import { useState } from 'react';

export const useMainPhoto = () => {
  const [mainPhotoType, setMainPhotoType] = useState('');

  const clearPhotoType = () => setMainPhotoType('');

  return {
    mainPhotoType,
    setMainPhotoType,
    clearPhotoType,
  };
};
