export const photoTypes = [
  { label: 'Orientation View', type: 'ORIENTATION' },
  { label: 'Mesh View', type: 'MESH_VIEW' },
  { label: 'Printed Model', type: 'PRINTED_MODEL' },
  { label: 'Top View', type: 'TOP_VIEW' },
  { label: 'Bottom View', type: 'BOTTOM_VIEW' },
  { label: 'Front View', type: 'FRONT_VIEW' },
  { label: 'Rear View', type: 'REAR_VIEW' },
  { label: 'Left Side View', type: 'LEFT_SIDE_VIEW' },
  { label: 'Right Side View', type: 'RIGHT_SIDE_VIEW' },
];

export const MAX_FILE_SIZE_NON_SUBSCRIBERS = 52428800; // 50mb
export const MAX_FILE_SIZE_SUBSCRIBERS = 524288000; // 500mb
