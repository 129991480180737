import { numArr } from 'utils/global';

export const MATERIAL_TYPES = { PLA: 'PLA', ABS: 'ABS' };

export const MATERIAL_TYPE_ITEMS = [
  { label: 'ABS', value: MATERIAL_TYPES.ABS },
  { label: 'PLA', value: MATERIAL_TYPES.PLA },
];

export const STATES_ITEMS = [
  { label: 'Alabama', value: 'AL' },
  { label: 'Alaska', value: 'AK' },
  { label: 'Arizona', value: 'AZ' },
  { label: 'Arkansas', value: 'AR' },
  { label: 'California', value: 'CA' },
  { label: 'Colorado', value: 'CO' },
  { label: 'Connecticut', value: 'CT' },
  { label: 'Delaware', value: 'DE' },
  { label: 'Florida', value: 'FL' },
  { label: 'Georgia', value: 'GA' },
  { label: 'Hawaii', value: 'HI' },
  { label: 'Idaho', value: 'ID' },
  { label: 'Illinois', value: 'IL' },
  { label: 'Indiana', value: 'IN' },
  { label: 'Iowa', value: 'IA' },
  { label: 'Kansas', value: 'KS' },
  { label: 'Kentucky', value: 'KY' },
  { label: 'Louisiana', value: 'LA' },
  { label: 'Maine', value: 'ME' },
  { label: 'Maryland', value: 'MD' },
  { label: 'Massachusetts', value: 'MA' },
  { label: 'Michigan', value: 'MI' },
  { label: 'Minnesota', value: 'MN' },
  { label: 'Mississippi', value: 'MS' },
  { label: 'Missouri', value: 'MO' },
  { label: 'Montana', value: 'MT' },
  { label: 'Nebraska', value: 'NE' },
  { label: 'Nevada', value: 'NV' },
  { label: 'New Hampshire', value: 'NH' },
  { label: 'New Jersey', value: 'NJ' },
  { label: 'New Mexico', value: 'NM' },
  { label: 'New York', value: 'NY' },
  { label: 'North Carolina', value: 'NC' },
  { label: 'North Dakota', value: 'ND' },
  { label: 'Ohio', value: 'OH' },
  { label: 'Oklahoma', value: 'OK' },
  { label: 'Oregon', value: 'OR' },
  { label: 'Pennsylvania', value: 'PA' },
  { label: 'Rhode Island', value: 'RI' },
  { label: 'South Carolina', value: 'SC' },
  { label: 'South Dakota', value: 'SD' },
  { label: 'Tennessee', value: 'TN' },
  { label: 'Texas', value: 'TX' },
  { label: 'Utah', value: 'UT' },
  { label: 'Vermont', value: 'VT' },
  { label: 'Virginia', value: 'VA' },
  { label: 'Washington', value: 'WA' },
  { label: 'West Virginia', value: 'WV' },
  { label: 'Wisconsin', value: 'WI' },
  { label: 'Wyoming', value: 'WY' },
];

export const DELIVERY_POLICY_SETTINGS = { PICKUP: 'PICKUP', PICKUP_AND_SHIP: 'PICKUP_AND_SHIP', SHIP: 'SHIP' };

export const SHIPPING_ADDRESS_SETTINGS = { BILLING_ADDRESS: 'BILLING_ADDRESS', ANOTHER_ADDRESS: 'ANOTHER_ADDRESS' };

export const DELIVERY_INFORMATION_SETTINGS = { PICKUP: 'PICKUP', SHIP: 'SHIP' };

export const SHIPPING_ADDRESS_ITEMS = [
  { label: 'Use Billing address', value: SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS },
  { label: 'Use Another Shipping address', value: SHIPPING_ADDRESS_SETTINGS.ANOTHER_ADDRESS },
];

export const DELIVERY_POLICY_ITEMS = [
  { label: 'Pickup only', value: DELIVERY_POLICY_SETTINGS.PICKUP },
  { label: 'Ship and Pickup', value: DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP },
  { label: 'Ship only', value: DELIVERY_POLICY_SETTINGS.SHIP },
];

export const MATERIAL_COLORS = [
  { label: 'Red', value: 'RED' },
  { label: 'Grey', value: 'GREY' },
  { label: 'Black', value: 'BLACK' },
  { label: 'Blue', value: 'BLUE' },
  { label: 'White', value: 'WHITE' },
  { label: 'Green', value: 'GREEN' },
  { label: 'Purple', value: 'PURPLE' },
  { label: 'Yellow', value: 'YELLOW' },
  { label: 'Orange', value: 'ORANGE' },
  { label: 'Pink', value: 'PINK' },
  { label: 'Brown', value: 'BROWN' },
];

export const EXTRUDER_SIZES = [
  { label: '0.1 mm', value: 0.1 },
  { label: '0.2 mm', value: 0.2 },
  { label: '0.3 mm', value: 0.3 },
  { label: '0.4 mm', value: 0.4 },
  { label: '0.5 mm', value: 0.5 },
  { label: '0.6 mm', value: 0.6 },
];

export const DEFAULT_PRINT_SETTINGS = {
  ABS: {
    travelAvoidOtherParts: true,
    adhesionType: 'SKIRT',
    retractionEnable: true,
    materialFlow: 100,
    infillSparseDensity: 20,
    speedLayerZero: 25,
    infillPattern: 'CUBIC',
    defaultMaterialBedTemperature: 85,
    materialBedTemperatureLayerZero: 90,
    speedPrint: 50,
    defaultMaterialPrintTemperature: 230,
    materialPrintTemperatureLayerZero: 230,
    retractionAmount: '1.0',
    speedTopbottom: 40,
    speedTravel: 60,
    supportInfillRate: 15,
    printQuality: 'HIGH',
  },
  PLA: {
    travelAvoidOtherParts: false,
    adhesionType: 'SKIRT',
    retractionEnable: true,
    materialFlow: 100,
    infillSparseDensity: 20,
    speedLayerZero: 25,
    infillPattern: 'CUBIC',
    defaultMaterialBedTemperature: 55,
    materialBedTemperatureLayerZero: 60,
    speedPrint: 50,
    defaultMaterialPrintTemperature: 195,
    materialPrintTemperatureLayerZero: 195,
    retractionAmount: '1.0',
    speedTopbottom: 40,
    speedTravel: 60,
    supportInfillRate: 15,
    printQuality: 'HIGH',
  },
};

export const INFILL_PERCENTS = numArr(100, 5).map((arrItem) => ({
  label: `${arrItem}%`,
  value: arrItem,
}));

export const SUPPORT_INFILL_PERCENTS = numArr(25, 10).map((arrItem) => ({
  label: `${arrItem}%`,
  value: arrItem,
}));

export const INFILL_PATTERNS = [
  { label: 'Grid', value: 'GRID' },
  { label: 'Lines', value: 'LINES' },
  { label: 'Tri-angles', value: 'TRIANGLES' },
  { label: 'Tri-hex', value: 'TRIHEXAGON' },
  { label: 'Cubic', value: 'CUBIC' },
  { label: 'Cubic-sub', value: 'CUBICSUBDIV' },
  { label: 'Quarter-cubic', value: 'QUARTER_CUBIC' },
  { label: 'Concentric', value: 'CONCENTRIC' },
  { label: 'Zig-zag', value: 'ZIGZAG' },
  { label: 'Cross', value: 'CROSS' },
  { label: '3D-cross', value: 'CROSS_3D' },
  { label: 'Gyroid', value: 'GYROID' },
  { label: 'Octet', value: 'TETRAHEDRAL' },
];

export const ADHESION_TYPES = [
  { label: 'Skirt x5', value: 'SKIRT' },
  { label: 'Raft', value: 'RAFT' },
  { label: 'Brim', value: 'BRIM' },
  { label: 'None', value: 'NONE' },
];

export const MATERIAL_PRINT_TEMPERATURE = {
  ABS: [
    { label: '220', value: 220 },
    { label: '225', value: 225 },
    { label: '230', value: 230 },
    { label: '235', value: 235 },
    { label: '240', value: 240 },
    { label: '245', value: 245 },
    { label: '250', value: 250 },
  ],
  PLA: [
    { label: '185', value: 185 },
    { label: '190', value: 190 },
    { label: '195', value: 195 },
    { label: '200', value: 200 },
    { label: '205', value: 205 },
    { label: '210', value: 210 },
  ],
};

export const PLATE_TEMPERATURE = {
  ABS: [
    { label: '70', value: 70 },
    { label: '75', value: 75 },
    { label: '80', value: 80 },
    { label: '85', value: 85 },
    { label: '90', value: 90 },
    { label: '95', value: 95 },
    { label: '100', value: 100 },
    { label: '105', value: 105 },
    { label: '110', value: 110 },
  ],
  PLA: [
    { label: '50', value: 50 },
    { label: '55', value: 55 },
    { label: '60', value: 60 },
    { label: '65', value: 65 },
    { label: '70', value: 70 },
  ],
};

export const FLOW_ITEMS = [
  { label: '100', value: 100 },
  { label: '105', value: 105 },
  { label: '110', value: 110 },
  { label: '115', value: 115 },
  { label: '120', value: 120 },
  { label: '125', value: 125 },
  { label: '130', value: 130 },
  { label: '135', value: 135 },
  { label: '140', value: 140 },
  { label: '145', value: 145 },
  { label: '150', value: 150 },
];

export const BOOLEAN_ITEMS = [
  { label: 'Yes', value: 'true' },
  { label: 'No', value: 'false' },
];

export const RETRACTION_DISTANCE_ITEMS = numArr(30).map((distanceItem) => ({
  label: `${(distanceItem * 0.1).toFixed(1)} mm`,
  value: (distanceItem * 0.1).toFixed(1),
}));

export const PRINT_SPEED_ITEMS = numArr(100, 20).map((speedItem) => ({ label: `${speedItem} mm`, value: speedItem }));

export const INITIAL_LAYER_SPEED_ITEMS = numArr(40, 20).map((speedItem) => ({
  label: `${speedItem} mm`,
  value: speedItem,
}));

export const PRINT_QUALITY_OPTIONS = [
  { label: 'Fine', value: 'FINE' },
  { label: 'Very High', value: 'VERY_HIGH' },
  { label: 'High', value: 'HIGH' },
  { label: 'Medium', value: 'MEDIUM' },
  { label: 'Low', value: 'LOW' },
];

export const OVERALL_SETTINGS = [
  { id: 1, label: 'Support Stair Step Height', value: '1.0' },
  { id: 2, label: 'Support Stair Step Maximum Width', value: '3.0' },
  { id: 3, label: 'Support Interface Density', value: '20.0' },
  { id: 4, label: 'Support Floor Thickness', value: '0.5' },
  { id: 5, label: 'Support Roof Thickness', value: '0.5' },
  { id: 6, label: 'Support Density', value: '15.0' },
  { id: 7, label: 'Print Speed', value: '80' },
  { id: 8, label: 'Top/Bottom Speed', value: '40' },
  { id: 9, label: 'Initial Layer Speed', value: '25' },
  { id: 10, label: 'Travel Speed', value: '80' },
  { id: 11, label: 'Avoid Printed Parts When Traveling', value: 'false' },
];
