import { gql } from '@apollo/client';

export const getCategoriesQuery = gql`
  query getCategories {
    threeDContCatagories {
      id
      name
      description
    }
  }
`;
