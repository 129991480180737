export const DEFAULT_CONTAINER_STYLE = {
  width: '100%',
  height: '80vh',
};

export const MAP_DEFAULT_CENTER = {
  lat: 38.056517,
  lng: -100.850486,
};

export const MAP_DEFAULT_ZOOM = 4.7;

export const GOOGLE_MAPS_LIBS = ['drawing', 'places', 'geometry'];
