import React from 'react';
import { Modal as ModalWrapper } from 'react-bootstrap';

const Modal = ({ children, visible, onClose, ...props }) => (
  <ModalWrapper show={visible} centered onHide={onClose} {...props}>
    {children}
  </ModalWrapper>
);

export default Modal;
