import React from 'react';
import { ApolloProvider } from '@apollo/client';
import ReactDOM from 'react-dom';

import 'react-toastify/dist/ReactToastify.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import './index.css';
import App from './App';

// GraphQL-specific
import client from './client';

import reportWebVitals from './reportWebVitals';

import * as serviceWorker from './serviceWorker';
import './assets/main.scss';
import 'react-phone-number-input/style.css';

// ApolloProvider wraps the React app and places the Apollo client
// on the React context so the client can be conveniently accessed
// from anywhere in the component tree.

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
  </ApolloProvider>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
