import React from 'react';
import { parse, stringify } from 'query-string';
import { toast } from 'react-toastify';
import { ERRORS } from 'constants/errors';

export const numArr = (length, startNumber) =>
  startNumber
    ? Array.from(Array(length + 1).keys()).filter((startItem) => startItem > startNumber - 1 && startItem)
    : Array.from(Array(length + 1).keys());

export const formatBooleanValue = (value) => {
  if (value === 'true') return true;
  if (value === 'false' || !value) return false;

  return value;
};

export const valueOrDefault = (value, formattedValue, defaultSign = '-') =>
  value ? formattedValue || value : defaultSign;

export const yesOrNo = (boolean) => (boolean ? 'Yes' : 'No');

export const getIncomeSum = (value, percentage) => {
  if (value) {
    const totalPrice = Number(value);
    const percentageOfSum = totalPrice * (percentage / 100);

    return (totalPrice - percentageOfSum).toFixed(2);
  }
};

export const getUserPosition = (onSuccess, onError) => {
  if (navigator.permissions) {
    navigator.permissions?.query({ name: 'geolocation' }).then((result) => {
      if (result.state == 'granted') {
        return navigator.geolocation.getCurrentPosition(({ coords }) => onSuccess(coords), onError);
      } else if (result.state == 'prompt') {
        navigator.geolocation.getCurrentPosition(({ coords }) => onSuccess(coords), onError);
      } else if (result.state == 'denied') {
        return onError;
      }
    });
  } else if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(({ coords }) => onSuccess(coords), onError);
  }
};

export const getUserToken = () => {
  const token = localStorage.getItem('auth-token');
  return token ? `Bearer ${token}` : null;
};

export const findAndReplace = (array = [], newItem) => {
  const id = newItem?.id;
  const filteredArray = array?.filter((part) => part?.id !== id);

  if (filteredArray) [...filteredArray, newItem];
};

const humanizeAddressError = (detail) => {
  try {
    const [, value] = detail.split(': ');

    const parsedValues = JSON.parse(value);
    let messages = '';
    for (const parsedValue in parsedValues) {
      switch (parsedValue) {
        case 'first_address_line':
          messages += 'Address Line 1: ' + parsedValues[parsedValue].join();
          break;
      }
    }

    return messages;
  } catch {
    return detail;
  }
};

export const handleErrors = (error, defaultMessage) => {
  const humanizeError = (detail, title) => {
    if (title === 'Could not update wingman' || title === 'Could not update user') {
      return humanizeAddressError(detail);
    }

    const found = ERRORS.find((singleError) => singleError.title === title && singleError.description === detail);
    if (found) return found.humanized;

    return detail;
  };

  const MessageError = ({ title, details }) => (
    <>
      <p className="font-weight-bold">{title}</p>
      {!!details?.length && (
        <>
          <p>Reasons:</p>
          <ul>
            {details.map((detail) => (
              <li key={detail}>{humanizeError(detail, title)}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );

  let title = defaultMessage || 'Something went wrong. Try again!';
  let details = [];

  if (error?.message) title = error?.message;

  if (error?.graphQLErrors) {
    error?.graphQLErrors.map((singleError) => {
      if (singleError?.details) {
        for (const [key, value] of Object.entries(singleError?.details)) {
          if (typeof value === 'object') {
            details.push(`${key}: ${JSON.stringify(value)}`);
          } else {
            details.push(`${key}: ${value}`);
          }
        }
      }
    });
  }

  toast.error(<MessageError title={title} details={details} />);
};

export const getFromLocalStorage = (key) => {
  const columns = localStorage.getItem(key);

  if (!columns) return null;
  return JSON.parse(columns);
};

export const setToLocalStorage = (key, value) => localStorage.setItem(key, JSON.stringify(value));

export const removeFromLocalStorage = (key) => localStorage.removeItem(key);

export const downloadFile = (file) => window.location.assign(file);

export const parseQueryString = (queryString) => parse(queryString);

export const getPaginationParams = (data) => ({
  pageNumber: data?.pageNumber,
  totalPages: data?.totalPages,
  pageSize: data?.pageSize,
  totalEntries: data?.totalEntries,
});

export const getQueryParams = (search, params) => {
  let parsed = parse(search);
  const searchParams = { ...parsed, ...params };
  parsed = Object.keys(searchParams).reduce((obj, key) => {
    if (params[key] === undefined) return obj;
    if (params[key] === null || !params[key]?.length) {
      delete obj[key];
      return obj;
    }
    return obj;
  }, searchParams);

  const query = stringify(parsed);
  return query ? `?${query}` : '';
};

export const getLabelFromValuesList = (value, valuesList) => {
  const foundValue = valuesList?.find((item) => item?.value === value);

  return foundValue?.label;
};

export const formatBytes = (bytes, decimals = 2) => {
  if (!+bytes) return '0 Bytes';

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
};

export const joinAddress = ({ firstAddressLine, city, country, stateProvince, postalCode }) => {
  let address = '';

  if (firstAddressLine) {
    address = firstAddressLine;
  }

  if (city) {
    if (address) {
      address += ', ' + city;
    } else {
      address = city;
    }
  }

  if (stateProvince) {
    if (address) {
      address += ', ' + stateProvince;
    } else {
      address = stateProvince;
    }
  }

  if (country) {
    if (address) {
      address += ', ' + country;
    } else {
      address = country;
    }
  }

  if (postalCode) {
    if (address) {
      address += ', ' + postalCode;
    } else {
      address = postalCode;
    }
  }

  return address;
};

export function humanizeSnakeCase(str) {
  if (typeof str !== 'string') {
    return 'Input is not a string';
  }

  const formattedStr = str.replace(/_/g, ' ').trim(); // Replace underscores with spaces and trim any leading or trailing spaces
  const words = formattedStr.split(' ').map((word) => {
    return word.charAt(0).toUpperCase() + word.slice(1);
  });

  return words.join(' ');
}
