import React, { useContext } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Button, Card } from 'react-bootstrap';
import { Link, useHistory, useParams } from 'react-router-dom';
import DeletePartModal from 'components/DeletePartModal';
import NoImage from 'components/NoImage';
import RequireSignInLink from 'components/RequireSignInLink';
import { PART_STATUSES } from 'constants/part';
import { useModal } from 'hooks/useModal';
import { downloadFile } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';
import styles from './index.module.scss';

const PartCard = ({ part }) => {
  const { user } = useContext(AuthContext);
  const { modalVisible, setModalVisible, toggle } = useModal();
  const { id } = useParams();
  const {
    location: { pathname },
  } = useHistory();
  const isMyParts = pathname?.includes('my-product');
  const isDesignerParts = pathname?.includes(`/designer/${id}`);

  const renderStatusAlert = () => {
    if (part?.status === PART_STATUSES.UNPROCESSED) {
      return (
        <div className="alert alert-warning mt-3 text-center" role="alert">
          We are processing this part. It could take up to 10 min. Check back later.
        </div>
      );
    } else if (part?.status === PART_STATUSES.FAILED) {
      return (
        <div className="alert alert-danger" role="alert">
          The part is invalid
        </div>
      );
    }
  };

  const renderImage = () => {
    const mainPhoto = part?.partPics?.find(({ isMain }) => isMain);

    if (mainPhoto?.url || part?.partPics[0]?.url)
      return <img src={mainPhoto?.url || part?.partPics[0]?.url} alt={part.name} className={cn('w-100', styles.img)} />;

    return <NoImage />;
  };

  return (
    <>
      <DeletePartModal isVisible={modalVisible} onClose={toggle} partId={part.id} setModalVisible={setModalVisible} />
      <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 p-2 d-flex align-items-lg-stretch">
        <Card className={cn('shadow h-100 w-100', styles.card)}>
          {part?.status !== PART_STATUSES.PROCESSED && !isDesignerParts && <div className={styles.disabledMask} />}
          <Card.Header>
            <h6 className={'card-title text-center m-0'}>
              {(part?.status === PART_STATUSES.PROCESSED || isDesignerParts) && (
                <RequireSignInLink path={`/part/${part.id}`}>{part.name}</RequireSignInLink>
              )}
            </h6>
          </Card.Header>
          <Card.Body className="p-2 d-flex flex-column justify-content-between">
            {(part?.status === PART_STATUSES.PROCESSED || isDesignerParts) && (
              <RequireSignInLink className={cn('text-decoration-none text-center m-auto')} path={`/part/${part.id}`}>
                {renderImage()}
              </RequireSignInLink>
            )}
            <div className="details w-100 d-flex flex-column justify-content-between align-items-center my-3">
              <div className="d-flex justify-content-center w-100">
                <div className={cn('maxJobs font-weight-bold', styles.jobs)}>{`Total Jobs (${part.jobs.length})`}</div>
              </div>
              <div className="description mt-2 px-1 w-100 text-center">{part.description}</div>
              {user && isMyParts && (
                <div
                  className={cn(
                    'd-flex align-items-center row justify-content-center mt-3 position-relative z-index-1 '
                  )}
                >
                  <Link to={`/part/edit/${part.id}`} className="text-decoration-none z-index-1">
                    <Button className="btn-warning mx-1">Edit</Button>
                  </Link>
                  <Button className="btn-danger mx-1" onClick={toggle}>
                    Delete
                  </Button>
                  {part?.userMedia?.url && (
                    <Button className="btn-success col-8 mt-2 mx-1" onClick={() => downloadFile(part?.userMedia?.url)}>
                      Download
                    </Button>
                  )}
                </div>
              )}
              {!isDesignerParts && renderStatusAlert()}
            </div>
          </Card.Body>
        </Card>
      </div>
    </>
  );
};

export default PartCard;

PartCard.propTypes = {
  part: PropTypes.object.isRequired,
};
