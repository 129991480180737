import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import InputError from 'components/InputError';

const FileInput = ({ disabled, label, labelInfo, value, errors, onChange }) => {
  const fileInputRef = useRef();
  const changeHandler = (event) => {
    const f = event.target.files[0];
    const reader = new FileReader();
    reader.onload = (() => {
      return function (e) {
        const binaryData = e.target.result;
        const base64String = window.btoa(binaryData);
        onChange({ file: f, data: base64String });
      };
    })(f);
    reader.readAsBinaryString(f);
  };

  const clear = () => {
    onChange(null);
    fileInputRef.current.value = null;
  };

  useEffect(() => {
    if (!value) fileInputRef.current.value = null;
  }, [value]);

  return (
    <>
      <div className="d-flex justify-content-between">
        <label className="h6" htmlFor="inputLabel">
          {label}
        </label>
        {labelInfo && <p className="m-0">{labelInfo}</p>}
      </div>
      <input
        id="fileInput"
        ref={fileInputRef}
        disabled={disabled}
        type="file"
        accept="model/*"
        className={cn('form-control', errors && 'is-invalid', value && !errors && 'is-valid')}
        onChange={changeHandler}
      />
      <InputError value={value} errors={errors} clear={clear} />
    </>
  );
};

export default FileInput;
