import React from 'react';
import cn from 'classnames';
import { FormGroup, FormLabel } from 'react-bootstrap';
import PhoneInput from 'react-phone-number-input';
import InputError from 'components/InputError';
import styles from './index.module.scss';

const PhoneNumberInput = ({ disabled, validation, errors, value, label, defaultValue, onChange }) => {
  return (
    <>
      <FormGroup className="m-0 w-100">
        <FormLabel className="h6">{label}</FormLabel>
        <PhoneInput
          international
          defaultCountry="US"
          value={value || ''}
          defaultValue={defaultValue}
          className={cn(
            styles.phoneInput,
            'form-control has-clear d-flex',
            validation && errors && 'is-invalid',
            validation && value && !errors && 'is-valid'
          )}
          countrySelectProps={{ disabled }}
          numberInputProps={{ className: 'border-0' }}
          onChange={onChange}
        />
      </FormGroup>
      {validation && <InputError value={value} errors={errors} />}
    </>
  );
};

export default PhoneNumberInput;
