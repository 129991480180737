import React, { useEffect } from 'react';
import { Accordion, Button, Card, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { FaAngleDown } from 'react-icons/fa';
import { DEFAULT_PRINT_SETTINGS, MATERIAL_TYPES, OVERALL_SETTINGS } from 'constants/wingman';
import { getSlicerSettingsByType } from 'utils/wingman';
import SettingsForm from '../SettingsForm';

const MaterialSettings = ({ selectedWingman, printQualityItems, toggle, setToggle, onSubmit }) => {
  const { control, handleSubmit, setValue } = useForm();
  const formFields = [
    'infillSparseDensity',
    'infillPattern',
    'defaultMaterialPrintTemperature',
    'materialPrintTemperatureLayerZero',
    'defaultMaterialBedTemperature',
    'materialBedTemperatureLayerZero',
    'retractionEnable',
    'retractionAmount',
    'adhesionType',
    'materialFlow',
    'printQuality',
  ];

  const setDefaultValues = (type) =>
    formFields.map((field) => setValue(`${field}${type}`, DEFAULT_PRINT_SETTINGS[type][field]));

  const plaSettings = getSlicerSettingsByType(MATERIAL_TYPES.PLA, selectedWingman?.printer);
  const absSettings = getSlicerSettingsByType(MATERIAL_TYPES.ABS, selectedWingman?.printer);

  useEffect(() => {
    if (toggle.PLA) setDefaultValues(MATERIAL_TYPES.PLA);
    if (toggle.ABS) setDefaultValues(MATERIAL_TYPES.ABS);
  }, [toggle]);

  useEffect(() => {
    formFields.map((field) => {
      setValue(
        `${field}${MATERIAL_TYPES.PLA}`,
        plaSettings?.[field] || DEFAULT_PRINT_SETTINGS[MATERIAL_TYPES.PLA][field]
      );
      setValue(
        `${field}${MATERIAL_TYPES.ABS}`,
        absSettings?.[field] || DEFAULT_PRINT_SETTINGS[MATERIAL_TYPES.ABS][field]
      );
    });

    setToggle({ ABS: false, PLA: false });
  }, [selectedWingman]);

  return (
    <>
      <Accordion defaultActiveKey="0" className="my-4">
        <Card>
          <Accordion.Toggle
            as={Card.Header}
            eventKey="0"
            role="button"
            className="d-flex justify-content-between align-items-center"
          >
            <span className="fs-20">Overall Settings</span>
            <FaAngleDown />
          </Accordion.Toggle>
          <Accordion.Collapse eventKey="0">
            <Card.Body className="m-2">
              <div className="font-weight-bold text-danger">
                NOTE: These settings are applied to every print (PLA and ABS). These settings are unchangeable by the
                operator.
              </div>
              {OVERALL_SETTINGS.map(({ id, label, value }) => (
                <>
                  <div className="mt-3 d-flex" key={id}>
                    <div className="mr-2 ">{`${label}:`}</div>
                    <div>{value}</div>
                  </div>
                  <div className="border-bottom my-2" />
                </>
              ))}
            </Card.Body>
          </Accordion.Collapse>
        </Card>
      </Accordion>
      <Row>
        <div className="col-6">
          <div className="mb-2 h6">Default PLA print settings</div>
          <div className="custom-control custom-switch py-2">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={toggle.PLA}
              onChange={(e) => setToggle({ ...toggle, PLA: e.target.checked })}
              id="switchPLA"
            />
            <label className="custom-control-label font-weight-bold" htmlFor="switchPLA">
              Use Default PLA settings
            </label>
          </div>
          <SettingsForm
            type={MATERIAL_TYPES.PLA}
            defaultValue={plaSettings}
            printQualityItems={printQualityItems}
            control={control}
            defaultDisabled={toggle.PLA}
          />
        </div>
        <div className="col-6">
          <div className="mb-2 h6">Default ABS print settings</div>
          <div className="custom-control custom-switch py-2">
            <input
              type="checkbox"
              className="custom-control-input"
              checked={toggle.ABS}
              onChange={(e) => setToggle({ ...toggle, ABS: e.target.checked })}
              id="switchABS"
            />
            <label className="custom-control-label font-weight-bold" htmlFor="switchABS">
              Use Default ABS settings
            </label>
          </div>
          <SettingsForm
            type={MATERIAL_TYPES.ABS}
            defaultValue={absSettings}
            printQualityItems={printQualityItems}
            control={control}
            defaultDisabled={toggle.ABS}
          />
        </div>
      </Row>
      <div className="text-center mt-2 mb-3">
        <Button type="submit" variant="primary" onClick={handleSubmit(onSubmit)}>
          Update
        </Button>
      </div>
    </>
  );
};

export default MaterialSettings;
