import { round } from 'lodash';

export const convertToFahrenheitTemp = (valueInCelsius) => {
  if (valueInCelsius) return round(valueInCelsius * 1.8 + 32, 2);
};

export const getPrintSettingLabel = (data, settingValue) => {
  if (data && settingValue) {
    const foundSetting = data.find(({ value }) => value === settingValue);
    return foundSetting.label;
  }
};

export const addTemporaryPartToPart = (jobItem) => {
	if(jobItem.part) {
		return jobItem;
	}
	jobItem.part = jobItem.temporaryPart;
	return jobItem;
};
