import React from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import Error from 'components/Error';
import Loading from 'components/Loading';
import Pagination from 'components/Pagination';
import useSearchParams from 'hooks/useSearchParams';
import { getListDesignersPartsQuery, getListDesignersQuery } from 'requests/designers';
import { getPaginationParams } from 'utils/global';
import PartsGrid from './PartsGrid';

const DesignerView = () => {
  const { id } = useParams();

  const { page = 1, pageSize = 9 } = useSearchParams();

  const { data: designersData } = useQuery(getListDesignersQuery);

  const { data, loading, error } = useQuery(getListDesignersPartsQuery, {
    variables: {
      id,
      page: +page,
      pageSize: +pageSize,
    },
  });

  const pagination = getPaginationParams(data?.listDesignerParts);

  const foundDesigner = designersData?.listDesigners?.entries?.find(({ userName }) =>
    data?.listDesignerParts?.entries?.some(({ userMedia }) => userName === userMedia?.threeDContUser?.userName)
  );

  return (
    <div className="container-fluid">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex align-items-center h5 my-4">
            <Link to="/designers" className="mr-2 text-primary">
              Designers
            </Link>
            <span>{`> ${foundDesigner?.userName || `Designer №${id}`}`}</span>
          </div>
          <div className="h3 my-4 font-weight-bold">Parts by {foundDesigner?.userName || `Designer №${id}`}</div>
          <Row className="m-3 flex-column align-items-center">
            <Col lg={12} sm={12}>
              {error ? <Error error={error} /> : <PartsGrid part={data?.listDesignerParts?.entries} />}
            </Col>
            <div className="my-3">{pagination.totalPages > 1 && <Pagination pagination={pagination} />}</div>
          </Row>
        </>
      )}
    </div>
  );
};

export default DesignerView;
