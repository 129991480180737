import { differenceInDays, format, formatDistance, parseISO, intervalToDuration } from 'date-fns';

const formatCurrency = (amount) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  return formatter.format(amount);
};

const formatYYYYMMDD = (date) => {
  if (!date) return null;
  return format(toISO(date), 'yyy-MM-dd');
};

const formatMonthDD = (date) => format(toISO(date), 'MMMM d');

const distanceInWordsFromNow = (date) =>
  formatDistance(toISO(date), Date.now(), {
    addSuffix: true,
    includeSeconds: true,
  });

const totalNights = (from, to) => differenceInDays(toISO(to), toISO(from));

const toISO = (date) => {
  if (typeof date === 'string') return parseISO(date);
  return date;
};

const formatDuration = (seconds = 0) => {
  const duration = intervalToDuration({ start: 0, end: seconds * 1000 });

  let date = '';

  if (duration.days) {
    date += `${duration.days}d `;
  }

  if (duration.hours) {
    date += `${duration.hours}h `;
  }

  if (duration.minutes) {
    date += `${duration.minutes}m `;
  }

  if (duration.seconds) {
    date += `${duration.seconds}s `;
  }

  return date;
};

const formatSecondsDuration = (timeInSeconds = 0) => {
  const { hours, minutes, seconds } = intervalToDuration({ start: 0, end: timeInSeconds * 1000 });

  return { hours, minutes, seconds };
};

export {
  formatCurrency,
  formatDuration,
  formatYYYYMMDD,
  formatMonthDD,
  distanceInWordsFromNow,
  totalNights,
  formatSecondsDuration,
};
