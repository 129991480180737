import React, { useContext } from 'react';
import { InfoWindow, Marker } from '@react-google-maps/api';
import { Link } from 'react-router-dom';
import { DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import { valueOrDefault, joinAddress } from 'utils/global';
import { getWingmanMarkerByDeliveryPolicy } from 'utils/map';
import { AuthContext } from 'сontexts/AuthContext';

const PositionMarker = ({ spiderfy, marker, activeMarker, setActiveMarker, popupValues }) => {
  const { user } = useContext(AuthContext);
  const foundPopupValues = popupValues?.find(({ id }) => id === activeMarker);
  const markerValues = popupValues?.find(({ id }) => id === marker.id);
  const userName = foundPopupValues?.threeDContUser?.userName;

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) return;
    setActiveMarker(marker);
  };

  const popupInfo = [
    {
      id: '1',
      label: 'Wingman Operator Name:',
      value: valueOrDefault(
        userName,
        <Link to={`/operator/${foundPopupValues?.threeDContUser?.id}`} className="text-primary">
          {userName}
        </Link>
      ),
    },
    {
      id: '2',
      label: 'Wingman Machine Name:',
      value: foundPopupValues?.machineName,
    },
    {
      id: '3',
      label: 'Model:',
      value: foundPopupValues?.printer?.model?.name,
    },
    {
      id: '4',
      label: 'Total Print Jobs:',
      value: foundPopupValues?.totalPrintJobs,
    },
    ...(foundPopupValues?.deliveryPolicy !== DELIVERY_POLICY_SETTINGS.SHIP
      ? [
          {
            id: '5',
            label: 'Address:',
            value:
              joinAddress({
                firstAddressLine: foundPopupValues?.shippingAddress?.firstAddressLine,
                secondAddressLine: foundPopupValues?.shippingAddress?.secondAddressLine,
                city: foundPopupValues?.shippingAddress?.city,
                country: foundPopupValues?.shippingAddress?.country === 'US' ? 'United States' : '',
                stateProvince: foundPopupValues?.shippingAddress?.stateProvince,
                postalCode: foundPopupValues?.shippingAddress?.postalCode,
              }) || foundPopupValues?.address,
          },
        ]
      : []),
    {
      id: '6',
      label: 'Shipping:',
      value:
        foundPopupValues?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP
          ? 'Ship and Pickup'
          : foundPopupValues?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP
          ? 'Pickup Only'
          : 'Ship Only',
    },
  ];

  return (
    <Marker
      position={marker}
      onClick={() => handleActiveMarker(marker?.id)}
      icon={{ url: getWingmanMarkerByDeliveryPolicy(markerValues?.deliveryPolicy) }}
      onLoad={(marker) => spiderfy.addMarker(marker)}
    >
      {activeMarker === marker?.id && popupValues.length ? (
        <InfoWindow onCloseClick={() => setActiveMarker(null)}>
          {user ? (
            <div>
              {popupInfo.map(({ id, label, value }) => (
                <div key={id} className="d-flex align-items-start mb-2">
                  <span className="font-weight-bold mr-1">{label}</span>
                  <span>{valueOrDefault(value)}</span>
                </div>
              ))}
              <Link to={`/wingman/${marker?.id}`} className="text-primary">
                See more
              </Link>
            </div>
          ) : (
            <Link to="/sign-in" className="text-primary">
              To see details, please, sign in
            </Link>
          )}
        </InfoWindow>
      ) : null}
    </Marker>
  );
};

export default PositionMarker;
