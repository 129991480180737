import React from 'react';
import { Accordion as AccordionComponent, Card } from 'react-bootstrap';
import { FaAngleDown } from 'react-icons/fa';

const { Toggle, Collapse } = AccordionComponent;
const { Header } = Card;

const Accordion = ({ title, children, defaultActiveKey = '0' }) => {
  return (
    <AccordionComponent defaultActiveKey={defaultActiveKey}>
      <Card>
        <Toggle as={Header} eventKey="0" role="button" className="d-flex justify-content-between align-items-center">
          <span className="fs-20">{title}</span>
          <FaAngleDown />
        </Toggle>
        <Collapse eventKey="0">
          <Card.Body>{children}</Card.Body>
        </Collapse>
      </Card>
    </AccordionComponent>
  );
};

export default Accordion;
