import { formatDuration, intervalToDuration, addMinutes, subMinutes } from 'date-fns';

export const milisecondsToTimeFormat = (miliseconds) => {
  return miliseconds && formatDuration(intervalToDuration({ start: 0, end: miliseconds }));
};

export const addTimezoneOffset = (date) => {
  const offset = date.getTimezoneOffset();
  return Math.sign(offset) === -1 ? addMinutes(date, Math.abs(offset)) : subMinutes(date, offset);
};
