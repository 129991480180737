import React, { useContext } from 'react';
import { Navbar, Nav, NavDropdown, Dropdown } from 'react-bootstrap';
import { FaUser } from 'react-icons/fa';
import { NavLink, useLocation } from 'react-router-dom';
import logo from 'assets/images/3dcont-white.png';
import Loading from 'components/Loading';
import Signout from 'components/Signout';
import { APP_LINKS, LANDING_LINK } from 'constants/global';
import { AuthContext } from 'сontexts/AuthContext';
import styles from './index.module.scss';

const Header = () => {
  const { user } = useContext(AuthContext);

  const location = useLocation();

  const isSignInPage = location.pathname.includes('/sign-in');
  const isSignUpPage = location.pathname.includes('/sign-up');

  const renderMenu = (links) => {
    return links.map(({ page, path, inner }) =>
      inner ? (
        renderInnerMenu(page, inner)
      ) : path.includes(LANDING_LINK) ? (
        <Nav.Item key={page} className="text-white text-right p-3 mx-1">
          <a href={path}>{page}</a>
        </Nav.Item>
      ) : (
        <Nav.Item key={page} className="text-white text-right p-3 mx-1">
          <NavLink to={path} activeClassName="link-active">
            {page}
          </NavLink>
        </Nav.Item>
      )
    );
  };

  const renderInnerMenu = (title, links) => (
    <NavDropdown
      align="start"
      title={title}
      id="inner-menu-dropdown"
      className="text-white text-right cursor-pointer p-3 mx-1"
      renderMenuOnMount
    >
      {links.map(({ path, page }) => (
        <NavDropdown.Item key={page} className={styles.innerMenuItem} href={path}>
          {page}
        </NavDropdown.Item>
      ))}
    </NavDropdown>
  );

  return (
    <header className="top-header">
      <Navbar bg="dark" expand="lg" variant="dark" className="py-3">
        <Navbar.Brand href="/">
          <img src={logo} width={87} alt="Wingman" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto w-100">
            <div className="w-100 mb-4 d-lg-flex d-xl-flex">
              <div className="d-lg-flex d-xl-flex flex-grow-1 justify-content-center align-items-center">
                {renderMenu(APP_LINKS)}
              </div>
              {user ? (
                <div className="col-md-auto position-unset d-flex justify-content-end align-items-center">
                  <Dropdown className="text-white text-right">
                    <Dropdown.Toggle variant="outline-primary" id="dropdown-header">
                      {user ? (
                        <>
                          <FaUser className="mr-2 color-dark w-100" />
                          <div className="text-white"> {user.userName}</div>
                        </>
                      ) : (
                        <Loading small />
                      )}
                    </Dropdown.Toggle>
                    <Dropdown.Menu id="profile-menu" className="p-2">
                      {user && (
                        <>
                          <Dropdown.Item bsPrefix="text-right" className="m-2" as="div">
                            <NavLink className="m-2 p-2" to={`/user/${user?.id}`} activeClassName="link-active">
                              My Account
                            </NavLink>
                          </Dropdown.Item>
                          <Dropdown.Item bsPrefix="text-right" className="m-2" as="div">
                            <NavLink className="m-2 p-2" to={`/my-cart`} activeClassName="link-active">
                              My Cart
                            </NavLink>
                          </Dropdown.Item>
                          <Dropdown.Item bsPrefix="text-right" className="m-2" as="div">
                            <NavLink className="m-2 p-2" to={`/my-product/${user?.id}`} activeClassName="link-active">
                              My Uploads
                            </NavLink>
                          </Dropdown.Item>
                          <Dropdown.Item bsPrefix="text-right" className="m-2" as="div">
                            <NavLink to={'/my-orders'} className="m-2 p-2" activeClassName="link-active">
                              My Orders
                            </NavLink>
                          </Dropdown.Item>
                          <Dropdown.Item bsPrefix="text-right" className="m-2" as="div">
                            <NavLink className="m-2 p-2" to={`/my-wingman`} activeClassName="link-active">
                              My Wingman
                            </NavLink>
                          </Dropdown.Item>
                        </>
                      )}

                      <Signout />
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              ) : (
                !isSignInPage &&
                !isSignUpPage && (
                  <>
                    <Nav.Item>
                      <NavLink to="/sign-in" className="text-white btn btn-primary mx-2">
                        Sign In
                      </NavLink>
                    </Nav.Item>
                    <Nav.Item>
                      <NavLink to="/sign-up" className="text-white btn btn-outline-primary">
                        Sign Up
                      </NavLink>
                    </Nav.Item>
                  </>
                )
              )}
            </div>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </header>
  );
};

export default Header;
