import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FaSearch } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import Input from 'components/Input';
import SelectQuery from 'components/SelectQuery';
import { getCategoriesQuery } from 'requests/category';
import { getQueryParams } from 'utils/global';

const SearchInput = ({ value, onSearchChange, catagory, hasClear, catagoryChange, title }) => {
  const location = useLocation();
  const history = useHistory();

  const [searchValue, setSearchValue] = useState('');

  useEffect(() => {
    value && setSearchValue(value);
  }, [value]);

  const handleSearch = (value) => {
    const { hash } = location;

    if (onSearchChange) onSearchChange(value);

    const query = getQueryParams(location.search, { searchValue: value, page: null, pageSize: null });
    history.push({ hash, search: query });
  };

  const handleChange = (value) => {
    setSearchValue(value);
    handleSearch(value);
  };

  return (
    <div className="search-criteria">
      <h4 className="pb-2">{title}</h4>
      <Input icon={<FaSearch />} value={searchValue} onChange={handleChange} hasClear={hasClear} />
      {catagory && (
        <>
          <h4>Category</h4>
          <SelectQuery
            value={catagory}
            onChange={catagoryChange}
            hasClear={hasClear}
            placeholder="Select category"
            labelField="name"
            isSearchable
            valueField="id"
            query={getCategoriesQuery}
            dataPath="threeDContCatagories"
          />
        </>
      )}
    </div>
  );
};

SearchInput.propTypes = {
  search: PropTypes.object || PropTypes.string,
  searchChange: PropTypes.func.isRequired,
};

export default SearchInput;
