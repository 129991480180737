import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import dateFnsFormat from 'date-fns/format';
import { Row, Col } from 'react-bootstrap';
import ImageViewer from 'react-simple-image-viewer';
import { valueOrDefault } from 'utils/global';
import styles from './index.module.scss';

const LiveImageBlock = ({ height, images }) => {
  const [currentImage, setCurrentImage] = useState(0);
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const openImageViewer = useCallback((index) => {
    setCurrentImage(index);
    setIsViewerOpen(true);
  }, []);

  const closeImageViewer = () => {
    setCurrentImage(0);
    setIsViewerOpen(false);
  };

  if (!images.length) {
    return <div className={cn(styles.noPhoto, 'my-5', 'pt-5')}>No Photo</div>;
  }

  return (
    <Row>
      <Col lg={4} className="my-3">
        <div className={cn(styles.image, styles.image, 'cursor-pointer')} style={{ height }}>
          <img onClick={() => openImageViewer(0)} src={images[0]?.url} alt="live-img-full" className="rounded" />
        </div>
      </Col>
      <Col lg={8} className={cn(styles.photoContainer, 'my-3 overflow-scroll')} style={{ height }}>
        {images
          .filter((img, i) => i !== 0 && img)
          .map((image, i) => (
            <div key={i} className={cn(i === 0 && styles.main, styles.image)}>
              <img
                onClick={() => openImageViewer(i + 1)}
                src={image?.url}
                alt={`live-img-${i}`}
                className="rounded cursor-pointer"
              />
              <div>
                {valueOrDefault(image?.timestamp, dateFnsFormat(new Date(image?.timestamp), 'MM-dd-yyyy hh:mm:ss a'))}
              </div>
            </div>
          ))}
      </Col>
      {isViewerOpen && (
        <ImageViewer
          src={images.map((image) => image?.url)}
          currentIndex={currentImage}
          onClose={closeImageViewer}
          disableScroll={false}
          backgroundStyle={{
            backgroundColor: 'rgba(0,0,0,0.9)',
          }}
          closeOnClickOutside={true}
        />
      )}
    </Row>
  );
};

export default LiveImageBlock;
