import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import InputError from 'components/InputError';

const TextArea = ({ disabled, label, value, onChange, errors }) => {
  const texAreaRef = useRef();
  const changeHandler = (e) => {
    onChange(e.target.value);
  };

  const clear = () => {
    onChange(null);
    texAreaRef.current.value = '';
  };

  useEffect(() => {
    texAreaRef.current.value = value || '';
  }, [value]);

  return (
    <>
      <label className="h6" htmlFor="textAreaLabel">
        {label}
      </label>
      <textarea
        id="textAreaLabel"
        ref={texAreaRef}
        disabled={disabled}
        value={value}
        className={cn('form-control', errors && 'is-invalid', value && !errors && 'is-valid')}
        onChange={changeHandler}
      />
      <InputError value={value} errors={errors} clear={clear} />
    </>
  );
};

export default TextArea;
