import React from 'react';
import cn from 'classnames';
import noImage from 'assets/images/noImage.jpeg';
import RequireSignInLink from 'components/RequireSignInLink';
import { valueOrDefault } from 'utils/global';
import styles from './index.module.scss';

const UserCard = ({ id, userName, profilePic, totalPrintJobs, entity }) => {
  const isDesignersPage = window.location.pathname.includes('/designers');

  const renderCardContent = () => (
    <div>
      <div className="h4 text-dark">{userName}</div>
      <div className={cn(styles.horizontalLine, 'mb-3')}></div>
      <div className="d-flex flex-column align-items-center">
        <img src={profilePic?.url || noImage} width="100%" />
        <div className={cn(styles.horizontalLine, 'mt-3')}></div>
      </div>
    </div>
  );

  return (
    <div className={cn(styles.card, 'card col-3 mx-3 my-3')}>
      <RequireSignInLink path={`/${entity}/${id}`}>{renderCardContent()}</RequireSignInLink>
      <div className="d-flex align-items-center justify-content-center my-3">
        <span className="font-weight-bold pr-2">
          {isDesignersPage ? 'Total parts' : 'Total Print Jobs'}: {valueOrDefault(totalPrintJobs, totalPrintJobs, 0)}
        </span>
      </div>
    </div>
  );
};

export default UserCard;
