import React, { useState } from 'react';
import { Status, Wrapper } from '@googlemaps/react-wrapper';
import { GOOGLE_MAPS_LIBS } from 'constants/map';

const GoogleMapsApiContext = React.createContext({
  isLoaded: false,
  error: undefined,
});

const GoogleMapsApiProvider = (props) => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [error, setError] = useState();

  const handleMapLoad = (status) => {
    if (status === Status.FAILURE) {
      setError(new Error('Google Maps API loading error'));
    }
    if (status === Status.SUCCESS) {
      setIsLoaded(true);
      setError(undefined);
    }
  };

  return (
    <Wrapper
      apiKey={process.env.REACT_APP_GOOGLE_API_KEY}
      libraries={GOOGLE_MAPS_LIBS}
      callback={handleMapLoad}
      language="en"
    >
      <GoogleMapsApiContext.Provider value={{ isLoaded, error }}>{props.children}</GoogleMapsApiContext.Provider>
    </Wrapper>
  );
};

export { GoogleMapsApiContext, GoogleMapsApiProvider };
