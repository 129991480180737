import React, { useRef } from 'react';
import cn from 'classnames';
import { FormGroup, InputGroup } from 'react-bootstrap';
import { FaTimesCircle } from 'react-icons/fa';
import InputError from 'components/InputError';
import styles from './index.module.scss';

function Input({
  label,
  labelInfo,
  value,
  defaultValue,
  errors,
  onChange,
  type = 'text',
  placeholder,
  step,
  min,
  icon,
  hasClear,
  autoFocus,
  disabled,
  validation,
  onKeyDown,
}) {
  const inputRef = useRef();

  const changeHandler = (e) => {
    onChange(e.target.value);
  };

  const clear = () => {
    onChange(null);
    inputRef.current.value = '';
  };

  return (
    <>
      <div className="d-flex justify-content-between">
        <label className="h6" htmlFor="inputLabel">
          {label}
        </label>
        {labelInfo && <p className="m-0">{labelInfo}</p>}
      </div>
      <InputGroup className={styles.inputGroup}>
        {icon && (
          <InputGroup.Prepend>
            <InputGroup.Text className="input-group-text">{icon}</InputGroup.Text>
          </InputGroup.Prepend>
        )}
        <FormGroup className="m-0 w-100">
          <input
            disabled={disabled}
            id="inputLabel"
            ref={inputRef}
            type={type}
            step={step}
            autoFocus={autoFocus}
            min={min}
            onKeyDown={onKeyDown}
            value={value || ''}
            defaultValue={defaultValue}
            placeholder={placeholder}
            className={cn(
              'form-control has-clear',
              styles.input,
              validation && errors && 'is-invalid',
              validation && value && !errors && 'is-valid'
            )}
            onChange={changeHandler}
          />
          {hasClear && value && <FaTimesCircle className={styles.clear} onClick={clear} />}
        </FormGroup>
      </InputGroup>
      {validation && <InputError value={value} errors={errors} />}
    </>
  );
}

export default Input;
