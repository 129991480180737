import React, { useState, useRef, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { toast } from 'react-toastify';
import PartForm from 'components/PartForm';
import { YES } from 'constants/global';
import { ADD_PART } from 'constants/part';
import { useMainPhoto } from 'hooks/useMainPhoto';
import { createPartMutation } from 'requests/partRequest';
import { handleErrors } from 'utils/global';
import { getGCodeVariables } from 'utils/part';
import { AuthContext } from 'сontexts/AuthContext';

const NewUploads = ({ setActiveTab }) => {
  const toastId = useRef(null);
  const client = useApolloClient();
  const { user } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const { mainPhotoType, setMainPhotoType, clearPhotoType } = useMainPhoto();

  const onSubmit = async ({
    PRINTED_MODEL,
    BOTTOM_VIEW,
    FRONT_VIEW,
    LEFT_SIDE_VIEW,
    MESH_VIEW,
    REAR_VIEW,
    RIGHT_SIDE_VIEW,
    TOP_VIEW,
    ORIENTATION,
    availability,
    file,
    dimensionsHeight,
    dimensionsLength,
    dimensionsWidth,
    pricePerPrint,
    transferPrice,
    supportEnable,
    gCodes,
    ...values
  }) => {
    const partPicsArray = [
      PRINTED_MODEL,
      BOTTOM_VIEW,
      FRONT_VIEW && FRONT_VIEW,
      LEFT_SIDE_VIEW,
      MESH_VIEW,
      REAR_VIEW,
      RIGHT_SIDE_VIEW,
      TOP_VIEW,
      ORIENTATION,
    ];
    try {
      toastId.current = toast.loading('Uploading...', { autoClose: false });
      setUploading(true);
      await client.mutate({
        mutation: createPartMutation,
        variables: {
          ...values,
          supportEnable: supportEnable === YES,
          partPics: partPicsArray.filter((pic) => pic),
          fileData: file?.data,
          availability,
          provider: user?.id,
          dimensionsHeight: parseFloat(dimensionsHeight)?.toFixed(2),
          dimensionsLength: parseFloat(dimensionsLength)?.toFixed(2),
          dimensionsWidth: parseFloat(dimensionsWidth)?.toFixed(2),
          pricePerPrint: parseFloat(pricePerPrint)?.toFixed(2),
          transferPrice: transferPrice ? parseFloat(transferPrice)?.toFixed(2) : null,
          ...(mainPhotoType && { mainPhotoType }),
          ...(gCodes.length && { gcodes: getGCodeVariables(gCodes) }),
        },
      });
      toast.dismiss(toastId.current);
      setUploading(false);
      setActiveTab('view');
      toast.success('Upload Complete');
      return true;
    } catch (error) {
      toast.dismiss(toastId.current);
      setUploading(false);
      handleErrors(error, 'An error has occurred while uploading');
    }
  };

  return (
    <div className="part-upload">
      <PartForm
        onSubmit={onSubmit}
        isRestricted={!user.stripeConnected}
        user={user}
        uploading={uploading}
        type={ADD_PART}
        mainPhotoType={mainPhotoType}
        setMainPhotoType={setMainPhotoType}
        clearPhotoType={clearPhotoType}
      />
    </div>
  );
};

export default NewUploads;
