import React from 'react';
import cn from 'classnames';
import { Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { ReactComponent as PrinterImage } from 'assets/images/icons/printer.svg';
import { valueOrDefault } from 'utils/global';
import styles from './index.module.scss';

const WingmanCard = ({ wingman }) => (
  <div className="col-md-4 col-lg-4 col-sm-6 col-xs-12 p-2 d-flex align-items-lg-stretch">
    <Card className="shadow h-100 w-100">
      <Link to={`/wingman/${wingman?.id}`} className="text-decoration-none text-dark">
        <Card.Header>
          <h6 className={'card-title text-center m-0'}>{wingman?.machineName || `Wingman №${wingman?.id}`}</h6>
        </Card.Header>
        <Card.Body className="p-2 d-flex flex-column justify-content-between">
          <PrinterImage className={cn('mt-1 mb-3', styles.wingmanImage)} fill="black" />
          <h6 className="d-flex justify-content-center w-100 font-weight-500">
            Total Print Jobs: {valueOrDefault(wingman?.totalPrintJobs, wingman?.totalPrintJobs, 0)}
          </h6>
          <div className="d-flex justify-content-center w-100">
            Extruder: {valueOrDefault(`${Number(wingman?.printer?.nozzleSize).toFixed(1)} mm`)}
          </div>
          <div className="d-flex justify-content-center w-100">
            Model: {valueOrDefault(wingman?.printer?.model?.name)}
          </div>
          <div className="d-flex justify-content-center w-100">
            Cost per hour: {valueOrDefault(wingman?.printer?.costPerHour)}
          </div>
        </Card.Body>
      </Link>
    </Card>
  </div>
);

export default WingmanCard;
