import React, { useState, useEffect, useRef, useCallback } from 'react';
import classNames from 'classnames';
import customIcon from 'assets/images/wingman-icon/wingman-marker-black.svg';
import Map from 'components/GoogleMap/Map';
import { MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM } from 'constants/map';
import useMap from 'hooks/useMap';
import { isValidPosition } from 'utils/validators';
import styles from './index.module.scss';

const defaultOptions = {
  strokeColor: '#ffa300',
  strokeOpacity: 1,
  strokeWeight: 2,
  fillColor: '#ffa300',
  fillOpacity: 0.5,
};

const editableOptions = {
  editable: true,
  draggable: true,
  clickable: true,
};

const WingmanMap = ({
  center,
  zoom,
  minZoom,
  className,
  onMarkerChange,
  markerLat,
  markerLng,
  withSearchBox = false,
  withSpiderfier = true,
  children,
  editable = true,
  handleSearch,
}) => {
  const { mapsRef, isMapMounted, handleMapsLoaded } = useMap();

  const drawingManagerRef = useRef(null);
  const [marker, setMarker] = useState(null);

  const handleMarkerChange = useCallback(
    (newMarker) => {
      const position = { lat: newMarker.getPosition()?.lat(), lng: newMarker.getPosition()?.lng() };
      if (position?.lat && position?.lng) onMarkerChange?.({ lat: position.lat, lng: position.lng });
    },
    [onMarkerChange]
  );

  const handleMarkerComplete = useCallback(
    (newMarker) => {
      setMarker((prevMarker) => {
        if (prevMarker) {
          prevMarker?.setMap(null);
        }
        return newMarker;
      });
      handleMarkerChange(newMarker);

      newMarker.addListener('dragend', () => handleMarkerChange(newMarker));
    },
    [handleMarkerChange]
  );

  useEffect(() => {
    if (isMapMounted && mapsRef.current?.map && markerLat && markerLng) {
      const position = { lat: markerLat, lng: markerLng };
      if (marker) {
        marker.setPosition(position);
      } else {
        const newMarker = new window.google.maps.Marker({
          position,
          icon: customIcon,
          ...defaultOptions,
          ...(editable && { ...editableOptions }),
        });

        newMarker.setMap(mapsRef.current?.map);
        handleMarkerComplete(newMarker);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editable, handleMarkerComplete, isMapMounted, mapsRef, markerLat, markerLng]);

  useEffect(() => {
    if (isMapMounted && window.google.maps?.drawing?.DrawingManager && editable) {
      const drawingManager = new window.google.maps.drawing.DrawingManager({
        drawingControl: true,
        drawingControlOptions: {
          position: window.google.maps.ControlPosition.TOP_RIGHT,
          drawingModes: [window.google.maps.drawing.OverlayType.MARKER],
        },
        markerOptions: {
          draggable: true,
          icon: customIcon,
        },
        polygonOptions: { ...defaultOptions, ...editableOptions },
      });

      drawingManager.setMap(mapsRef.current?.map);
      drawingManager.addListener('markercomplete', handleMarkerComplete);

      drawingManagerRef.current = drawingManager;
    }
  }, [isMapMounted, mapsRef, drawingManagerRef, editable, handleMarkerComplete]);

  return (
    <div className={classNames(styles.container, className)}>
      <Map
        center={center && isValidPosition(center) ? center : MAP_DEFAULT_CENTER}
        zoom={zoom || MAP_DEFAULT_ZOOM}
        fullscreenControl={false}
        onMapMount={handleMapsLoaded}
        minZoom={minZoom}
        withSpiderfier={withSpiderfier}
        withSearchBox={withSearchBox}
        handleSearch={handleSearch}
      >
        {children}
      </Map>
    </div>
  );
};

export default WingmanMap;
