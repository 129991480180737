export const JOB_SCOPES = { CUSTOMER: 'CUSTOMER', OPERATOR: 'OPERATOR' };

export const JOB_STATUSES = {
  READY_FOR_PRINT: 'READY_FOR_PRINT',
  STARTING: 'STARTING',
  DOWNLOAD_GCODE: 'DOWNLOAD_GCODE',
  IN_PROGRESS: 'IN_PROGRESS',
  PAUSED: 'PAUSED',
  PAUSING: 'PAUSING',
  RESUMING: 'RESUMING',
  CANCELING: 'CANCELING',
  DRAFT: 'DRAFT',
  COMPLETED: 'COMPLETED',
  DISPUTED: 'DISPUTED',
  PAID: 'PAID',
  REFUNDED: 'REFUNDED',
  CANCELED: 'CANCELED',
};

export const ORDER_STATUSES = {
  COMPLETED: 'COMPLETED',
  DRAFT: 'DRAFT',
  PAID: 'PAID',
  PROCESSING: 'PROCESSING',
  DISPUTED: 'DISPUTED',
  REFUNDED: 'REFUNDED',
  PRINTING: 'PRINTING',
  READY_FOR_PRINT: 'READY_FOR_PRINT',
};

export const JOB_IN_PROGRESS_STATUSES = [
  JOB_STATUSES.READY_FOR_PRINT,
  JOB_STATUSES.STARTING,
  JOB_STATUSES.DOWNLOAD_GCODE,
  JOB_STATUSES.IN_PROGRESS,
  JOB_STATUSES.PAUSED,
  JOB_STATUSES.PAUSING,
  JOB_STATUSES.RESUMING,
  JOB_STATUSES.CANCELING,
  JOB_STATUSES.CANCELED,
  JOB_STATUSES.DRAFT,
];

export const COMPLETED_JOB_STATUSES = [
  JOB_STATUSES.COMPLETED,
  JOB_STATUSES.PAID,
  JOB_STATUSES.DISPUTED,
  JOB_STATUSES.REFUNDED,
];

export const ORDER_IN_PROGRESS_STATUSES = [ORDER_STATUSES.READY_FOR_PRINT, ORDER_STATUSES.PRINTING];

export const ORDER_COMPLETED_STATUSES = [
  ORDER_STATUSES.COMPLETED,
  ORDER_STATUSES.DISPUTED,
  ORDER_STATUSES.REFUNDED,
  ORDER_STATUSES.PAID,
];

export const ORIENTATION = 'ORIENTATION';
