import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link, useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthPageWrapper from 'components/AuthPageWrapper';
import ControlledFormItem from 'components/ControlledFormItem/index';
import Input from 'components/Input/index';
import { singUpMutation } from 'requests/auth';
import { handleErrors } from 'utils/global';
import { minLength, maxLength, emailValidation } from 'utils/validators';

const minLengthValidation = minLength(2);
const maxLengthValidation = maxLength(50);

const Signup = () => {
  const client = useApolloClient();
  const history = useHistory();

  const {
    control,
    loading,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const singUpHandler = async (values) => {
    try {
      const data = await client.mutate({
        mutation: singUpMutation,
        variables: values,
      });

      if (data.data.signup === 'OK') {
        toast.success(
          `Your account has not been activated. Please confirm your email by clicking the link in the verification
					email sent to ${values.email} to sign in and start using the platform.`
        );
        history.push('/sign-in');
      }
    } catch (e) {
      handleErrors(e);
    }
  };

  return (
    <AuthPageWrapper title="Sign Up">
      <div className="text-center pt-2 pt-md-2 pb-4">
        <p className="mb-0 text-gray-800">
          Have an account?
          <Link to="/sign-in" className="text-primary font-weight-semibold ml-1">
            Sign In
          </Link>
        </p>
      </div>

      <form className="p-2" onSubmit={handleSubmit(singUpHandler)}>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="userName"
            label="Username"
            errors={errors.userName}
            component={Input}
            hasClear
            autoFocus
            placeholder="Username"
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="firstName"
            label="First name"
            errors={errors.firstName}
            component={Input}
            hasClear
            placeholder="First name"
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="lastName"
            label="Last name"
            errors={errors.lastName}
            component={Input}
            hasClear
            placeholder="Last name"
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="email"
            label="Email"
            errors={errors.email}
            component={Input}
            hasClear
            type="email"
            placeholder="Email"
            validation
            rules={{
              required: true,
              validate: { emailValidation },
            }}
          />
        </div>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="password"
            label="Password"
            errors={errors.password}
            component={Input}
            type="password"
            hasClear
            placeholder="Password"
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="d-grid">
          <Button type="submit" disabled={loading} size="lg">
            Sign Up
          </Button>
        </div>
      </form>
    </AuthPageWrapper>
  );
};

export default Signup;
