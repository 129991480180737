import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Link, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from 'hooks/useAuth';
import { jobUpdateOnUserSubscription } from 'requests/jobs';
import { handleErrors } from 'utils/global';

const JobUpdateContext = React.createContext();

const JobUpdateProvider = ({ children }) => {
  const { user } = useAuth();
  const [part, setPart] = useState();
  const { pathname } = useLocation();

  const client = useApolloClient();

  const isPartPage = pathname.includes(`/part/${part?.id}`);

  const renderInfoMessage = (partId, partName) => (
    <>
      We have processed your <span className="font-weight-bold">{partName}</span> order. Please, check it{' '}
      <Link to={`/part/${partId}`}>here</Link>
    </>
  );

  useEffect(() => {
    if (part && !isPartPage) {
      toast.info(renderInfoMessage(part?.id, part?.name), { autoClose: false });
    }
  }, [part, part?.id]);

  useEffect(() => {
    let subscription;
    const userId = user?.id;

    try {
      if (userId) {
        subscription = client
          .subscribe({
            query: jobUpdateOnUserSubscription,
            variables: { userId },
          })
          .subscribe({
            next({ data }) {
              const part = data?.jobUpdateOnUserSubscription?.part;
              setPart(part);
            },
            error(error) {
              handleErrors(error);
            },
          });
      } else {
        subscription?.unsubscribe();
      }
    } catch (error) {
      handleErrors(error);
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [client, user]);

  return <JobUpdateContext.Provider>{children}</JobUpdateContext.Provider>;
};

export { JobUpdateContext, JobUpdateProvider };
