import * as AbsintheSocket from '@absinthe/socket';
import { createAbsintheSocketLink } from '@absinthe/socket-apollo-link';
import { ApolloClient, HttpLink, InMemoryCache, split, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { hasSubscription } from '@jumpn/utils-graphql';
import { Socket as PhoenixSocket } from 'phoenix';
import { getUserToken } from 'utils/global';

const HTTP_ENDPOINT = `${process.env.REACT_APP_API_URL}/api`;
const WS_ENDPOINT = `${process.env.REACT_APP_WS_URL}`;

// Create an HTTP link to the Phoenix app's HTTP endpoint URL.
const httpLink = new HttpLink({
  uri: HTTP_ENDPOINT,
});

const fileLink = new HttpLink({
  uri: HTTP_ENDPOINT,
});

// If an authentication token exists in local storage, put
// the token in the "Authorization" request header.
// Returns an object to set the context of the GraphQL request.
const authLink = setContext((_, { headers }) => {
  const token = getUserToken();

  return {
    headers: {
      ...headers,
      authorization: token,
    },
  };
});

const isFile = (value) =>
  (typeof File !== 'undefined' && value instanceof File) || (typeof Blob !== 'undefined' && value instanceof Blob);

const isUpload = ({ variables }) => Object.values(variables).some(isFile);

// Create a link that "splits" requests based on GraphQL operation type.
// Queries and mutations go through the HTTP link.
// Subscriptions go through the WebSocket link.
const link = authLink.concat(httpLink);

const bossLink = split(isUpload, fileLink, link);

// Create the Apollo Client instance.
const client = new ApolloClient({ link: bossLink, cache: new InMemoryCache() });

// Create a WebSocket link to the Phoenix app's socket URL.
export const linkWithSockets = (token) => {
  const wsLink = createAbsintheSocketLink(
    AbsintheSocket.create(new PhoenixSocket(WS_ENDPOINT, { params: () => ({ Authorization: token }) }))
  );

  return from([split((operation) => hasSubscription(operation.query), wsLink, authLink.concat(httpLink))]);
};

export default client;
