import { useEffect, useState } from 'react';
import { parse } from 'query-string';
import { useLocation } from 'react-router-dom';

const useSearchParams = () => {
  const { search } = useLocation();
  const [searchParams, setSearchParams] = useState({});

  useEffect(() => {
    if (search) {
      const parsed = parse(search);

      setSearchParams(parsed);
    } else setSearchParams({});
  }, [search]);

  return searchParams;
};

export default useSearchParams;
