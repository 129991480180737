import wingmanMarkerBlack from 'assets/images/wingman-icon/wingman-marker-black.svg';
import wingmanMarkerBlue from 'assets/images/wingman-icon/wingman-marker-blue.svg';
import wingmanMarkerGreen from 'assets/images/wingman-icon/wingman-marker-green.svg';
import wingmanMarkerRed from 'assets/images/wingman-icon/wingman-marker-red.svg';
import { isoMap, minorIslandIsoCodeMap } from 'constants/countriesData';
import { DELIVERY_POLICY_SETTINGS } from 'constants/wingman';

export const getMarkerOptions = (data) =>
  data?.map(({ id, latitude, longitude }) => ({
    id: id,
    lat: +latitude,
    lng: +longitude,
  }));

export const getMarkerPositionOnClick = (event) => ({ lat: event.latLng.lat(), lng: event.latLng.lng() });

export const getPosition = ({ lat, lng }) => [{ lat, lng }];

export const formatAddress = (addressComponents) => {
  const components = {
    zip: 'postal_code',
    country: 'country',
    state: 'administrative_area_level_1',
    city: ['locality', 'postal_town'],
    address: ['street_number', 'route'],
  };

  return Object.entries(components).reduce((addressObj, [key, value]) => {
    if (!addressObj[key]) {
      addressObj[key] = addressComponents
        ?.filter((component) => {
          return Array.isArray(value)
            ? component.types.includes(value[0]) || component.types.includes(value[1])
            : component.types.includes(value);
        })
        ?.map(({ long_name, short_name }) => {
          if (key === components.country) {
            if (isoMap[short_name]) {
              return long_name;
            } else if (minorIslandIsoCodeMap[short_name]) {
              addressObj.state = `US-${short_name}`;
              return 'United States';
            }
            return '';
          }

          return long_name;
        })
        .join(' ');
    }

    return addressObj;
  }, {});
};

export const getWingmanMarkerByDeliveryPolicy = (deliveryPolicy) => {
  switch (deliveryPolicy) {
    case DELIVERY_POLICY_SETTINGS.PICKUP:
      return wingmanMarkerBlue;
    case DELIVERY_POLICY_SETTINGS.SHIP:
      return wingmanMarkerRed;
    case DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP:
      return wingmanMarkerGreen;
    default:
      return wingmanMarkerBlack;
  }
};
