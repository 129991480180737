import React, { useContext } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { AuthContext } from 'сontexts/AuthContext';

const RequireSignInLink = ({ path, className, children }) => {
  const { user } = useContext(AuthContext);

  return (
    <Link
      className={cn('text-decoration-none text-primary', className)}
      to={user ? path : { pathname: `/sign-in`, query: { path } }}
    >
      {children}
    </Link>
  );
};

export default RequireSignInLink;
