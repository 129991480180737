import React from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { getWingmanByIdQuery } from 'requests/wingmen';
import WingmanInfo from './WingmanInfo';

const WingmanView = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(getWingmanByIdQuery, {
    variables: { id },
    fetchPolicy: 'network-only',
  });

  return (
    <div className="container-fluid">
      {loading ? (
        <Loading />
      ) : (
        <>
          <h3 className="my-3 text-center">{data?.wingmanById?.machineName || `Wingman №${id}`}</h3>
          <Row className="m-3">
            <Col lg={12} sm={12}>
              {error ? <Error error={error} /> : <WingmanInfo wingman={data?.wingmanById} />}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default WingmanView;
