import React, { useState, useRef } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import noImage from 'assets/images/noImage.jpeg';
import Loading from 'components/Loading';
import { getBase64 } from 'components/Upload/utils';
import { getUserQuery, updateUserProfileMutation } from 'requests/user';
import { handleErrors } from 'utils/global';
import AddressForm from './AddressForm';
import ContactForm from './ContactForm';
import styles from './index.module.scss';
import PaymentOptions from './PaymentOptions';

const Profile = () => {
  const { data, loading, refetch } = useQuery(getUserQuery);

  const [dataUri, setDataUri] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);

  const client = useApolloClient();

  const ref = useRef(null);

  const {
    control,
    formState: { errors },
    handleSubmit,
    unregister,
    register,
  } = useForm();

  const onProfileUpdate = async ({
    city,
    firstAddressLine,
    postalCode,
    stateProvince,
    secondAddressLine,
    ...values
  }) => {
    try {
      setUpdateLoading(true);
      await client.mutate({
        mutation: updateUserProfileMutation,
        variables: {
          billingAddress: {
            city,
            countryCode: 'US',
            firstAddressLine,
            postalCode,
            stateProvince,
            ...(secondAddressLine && { secondAddressLine }),
          },
          profilePicData: dataUri.split(',').pop(),
          ...values,
        },
      });

      if (values?.email === data?.me?.email) {
        toast.success('Your profile was updated successfully.');
      } else {
        toast.success(
          'Your profile has been successfully updated. However, your new email is not yet verified. To use it, please follow the verification link sent to your email. This email is valid only for 2 hours.'
        );
      }

      unregister();
      refetch?.().then(() => {
        setUpdateLoading(false);
        register();
      });
    } catch (error) {
      handleErrors(error, 'An error has occurred while updating the profile');
    }
  };

  if (loading || updateLoading) return <Loading />;

  const handleUpload = () => ref.current?.click();

  const getUploadedPhoto = async (file) => {
    const base64Photo = await getBase64(file);
    await setDataUri(base64Photo);
  };

  return (
    <div className="container-fluid p-3">
      <div className="font-weight-bold h3 my-4 mx-1">Account</div>
      <div className="card p-3">
        <div className={cn(styles.container, 'card col-lg-3 col-md-4 col-sm-12')}>
          <h4>{data?.me?.userName}</h4>
          <div className={cn(styles.horizontalLine, 'mb-3')}></div>
          <div className="d-flex flex-column align-items-center">
            <img src={dataUri ? dataUri : data?.me?.profilePic?.url || noImage} width="100%" />
            <div className={cn(styles.horizontalLine, 'mt-3')}></div>
            <div className="text-center my-3">
              <Button variant="secondary" onClick={handleUpload}>
                <input
                  className="d-none"
                  ref={ref}
                  type="file"
                  accept="image/*"
                  onChange={(e) => getUploadedPhoto(e.target.files[0] || null)}
                />
                Edit Photo
              </Button>
            </div>
          </div>
        </div>
        <form onSubmit={handleSubmit(onProfileUpdate)}>
          <div className={cn(styles.container, 'mt-3 px-3')}>
            <div className={cn(styles.title, 'pt-2')}>Contact</div>
            <div className="row">
              <div className={cn(styles.wrapper, ' card col-lg-8 col-sm-12 mb-3 mx-3')}>
                <ContactForm currentUser={data?.me} control={control} errors={errors} />
                <div className="text-left px-2 py-3">
                  By providing your email and phone number, you agree to receive emails and text messages related to
                  your order and may receive promotional and offers from us. Standard text messaging rates may apply.
                  Reply STOP to opt-out to receiving future text messages related to your order or unsubscribe from any
                  email sent to you
                </div>
              </div>
            </div>
            <div className={cn(styles.title, 'pt-2')}>Billing Address</div>
            <div className="row">
              <div className={cn(styles.wrapper, 'card col-lg-8 col-sm-12 mb-3 mx-3')}>
                <AddressForm currentUser={data?.me} control={control} errors={errors} />
              </div>
            </div>
            <div className={cn(styles.title, 'pt-2')}>Receive payment for Wingman operators and designers</div>
            <div className="row">
              <div className={cn(styles.wrapper, 'card col-lg-8 col-sm-12 mb-3 mx-3')}>
                <PaymentOptions isStripeConnected={data?.me?.stripeConnected} />
              </div>
            </div>
            <div className="text-center mt-3 mb-3">
              <Button onSubmit={handleSubmit(onProfileUpdate)} variant="success" type="submit">
                Update
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Profile;
