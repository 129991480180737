import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import AsyncOperation from 'components/AsyncOperation';
import { ASYNC_OPERATION_TYPES } from 'constants/global';
import { emailConfirmMutation } from 'requests/email';
import { AuthContext } from 'сontexts/AuthContext';

const EmailConfirm = () => {
  const { user } = useContext(AuthContext);

  const successMessage = !user ? (
    <>
      Thank you. Your email has been confirmed. Now you can{' '}
      <Link to="/sign-in" className="text-primary font-weight-semibold ml-1">
        sign in
      </Link>{' '}
      using your username and password
    </>
  ) : (
    <>Thank you. Your email has been confirmed.</>
  );

  return (
    <AsyncOperation
      type={ASYNC_OPERATION_TYPES.EMAIL}
      successMessage={successMessage}
      mutation={emailConfirmMutation}
    />
  );
};

export default EmailConfirm;
