import { useContext } from 'react';
import { GoogleMapsApiContext } from 'сontexts/GoogleMapsApiContext';

const useGoogleMapsApi = () => {
  const { isLoaded, error } = useContext(GoogleMapsApiContext);
  return {
    isLoaded,
    error,
  };
};

export default useGoogleMapsApi;
