import React from 'react';
import { Button } from 'react-bootstrap';

const MaterialsList = ({ materials, onDelete }) => (
  <>
    <div className="font-weight-bold">Available material and color for this printer machine</div>
    {materials?.length ? (
      materials.map(({ type, color, id }) => (
        <tr key={id}>
          <td>
            <div>
              <Button variant="link" className="orange" onClick={() => onDelete(id)}>
                Remove
              </Button>
            </div>
          </td>
          <td>
            <div>{`${type} > ${color}`}</div>
          </td>
        </tr>
      ))
    ) : (
      <p className="font-weight-bold text-center pt-3">No available materials</p>
    )}
  </>
);

export default MaterialsList;
