import React, { useState, useRef, useContext, useEffect } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { useHistory, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loading from 'components/Loading';
import PartForm from 'components/PartForm';
import { YES } from 'constants/global';
import { AVAILABILITY_OPTIONS, EDIT_PART } from 'constants/part';
import { useMainPhoto } from 'hooks/useMainPhoto';
import { getPartByIdQuery, updatePartMutation } from 'requests/partRequest';
import { handleErrors } from 'utils/global';
import { getGCodeVariables } from 'utils/part';
import { AuthContext } from 'сontexts/AuthContext';

const EditPart = () => {
  const toastId = useRef(null);
  const client = useApolloClient();
  const { user } = useContext(AuthContext);
  const [uploading, setUploading] = useState(false);
  const { id } = useParams();
  const history = useHistory();

  const { mainPhotoType, setMainPhotoType, clearPhotoType } = useMainPhoto();

  const [clearedPhotoType, setClearedPhotoType] = useState('');

  const { data: partData, loading } = useQuery(getPartByIdQuery, {
    variables: { partId: id },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    const mainPhoto = partData?.partById?.partPics?.find(({ isMain }) => isMain);
    mainPhoto && setMainPhotoType(mainPhoto.type);
  }, [partData, setMainPhotoType]);

  const onSubmit = async ({
    PRINTED_MODEL,
    BOTTOM_VIEW,
    FRONT_VIEW,
    LEFT_SIDE_VIEW,
    MESH_VIEW,
    REAR_VIEW,
    RIGHT_SIDE_VIEW,
    TOP_VIEW,
    ORIENTATION,
    availability,
    file,
    dimensionsHeight,
    dimensionsLength,
    dimensionsWidth,
    pricePerPrint,
    transferPrice,
    supportEnable,
    gCodes,
    ...values
  }) => {
    const partPicsArray = [
      PRINTED_MODEL,
      BOTTOM_VIEW,
      FRONT_VIEW && FRONT_VIEW,
      LEFT_SIDE_VIEW,
      MESH_VIEW,
      REAR_VIEW,
      RIGHT_SIDE_VIEW,
      TOP_VIEW,
      ORIENTATION,
    ];
    const uploadedPics = partPicsArray.filter((pic) => pic);
    const deletedPics = clearedPhotoType.length && clearedPhotoType.map((type) => ({ fileData: null, type }));

    try {
      toastId.current = toast.loading('Updating...', { autoClose: false });
      setUploading(true);
      await client.mutate({
        mutation: updatePartMutation,
        variables: {
          ...values,
          supportEnable: supportEnable === YES,
          partPics: deletedPics.length ? [...uploadedPics, ...deletedPics] : uploadedPics,
          fileData: file?.data,
          provider: user?.id,
          dimensionsHeight: parseFloat(dimensionsHeight)?.toFixed(2),
          dimensionsLength: parseFloat(dimensionsLength)?.toFixed(2),
          dimensionsWidth: parseFloat(dimensionsWidth)?.toFixed(2),
          pricePerPrint: parseFloat(pricePerPrint)?.toFixed(2),
          transferPrice: transferPrice ? parseFloat(transferPrice)?.toFixed(2) : null,
          id: id,
          ...(mainPhotoType && { mainPhotoType }),
          ...(availability !== AVAILABILITY_OPTIONS.FORCED_PRIVATE && { availability }),
          ...(gCodes.length && { gcodes: getGCodeVariables(gCodes) }),
        },
      });
      toast.dismiss(toastId.current);
      setUploading(false);
      toast.success('Part updated successfully');
      history.goBack();
      return true;
    } catch (error) {
      toast.dismiss(toastId.current);
      setUploading(false);
      handleErrors(error, 'The update error');
    }
  };

  return (
    <div className="part-upload">
      {loading ? (
        <Loading />
      ) : (
        <PartForm
          onSubmit={onSubmit}
          isRestricted={!user.stripeConnected}
          data={partData?.partById}
          user={user}
          uploading={uploading}
          type={EDIT_PART}
          mainPhotoType={mainPhotoType}
          setMainPhotoType={setMainPhotoType}
          clearPhotoType={clearPhotoType}
          setClearedPhotoType={setClearedPhotoType}
        />
      )}
    </div>
  );
};

export default EditPart;
