import { useState, useEffect, useContext, useCallback } from 'react';
import { STATES_ITEMS } from 'constants/wingman';
import { formatAddress } from 'utils/map';
import { MapContext } from 'сontexts/MapContext';

const useMap = () => {
  const { mapsRef, setMapMounted, isMapMounted } = useContext(MapContext);
  const [isGeocodeLoading, setGeocodeLoading] = useState(false);

  const geocode = useCallback((request) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode(request, (result, status) => {
        if (status === 'OK') resolve(formatAddress(result?.[0].address_components));
        else reject(status);
      });
    });
  }, []);

  const getBoundsFromPoints = useCallback((points) => {
    const bounds = new window.google.maps.LatLngBounds();
    points.forEach((point) => bounds.extend(point));
    return bounds;
  }, []);

  const getAddressFromLatLng = async (lat, lng, setAddressItemValue) => {
    try {
      setGeocodeLoading(true);
      const data = await geocode({ location: { lat: +lat, lng: +lng } });
      if (data) {
        let addressString = Object.keys(data)
          .reverse()
          .reduce((acc, field) => {
            if (!acc) {
              return data[field];
            }
            if (!data[field]) {
              return acc;
            }
            return `${acc}, ${data[field]}`;
          }, '');

        setAddressItemValue('firstAddressLine', data.address);
        setAddressItemValue('city', data.city);
        setAddressItemValue('country', data.country);
        setAddressItemValue('stateProvince', STATES_ITEMS.find(({ label }) => label === data.state).value);
        setAddressItemValue('postalCode', data.zip);
        setAddressItemValue('address', addressString);
      }
      setGeocodeLoading(false);
    } catch (error) {
      setGeocodeLoading(false);
    }
  };

  const handleMapsLoaded = useCallback(
    (refMap) => {
      mapsRef.current = { map: refMap };
      setMapMounted(true);
    },
    [setMapMounted, mapsRef]
  );

  useEffect(() => {
    return () => {
      mapsRef.current = undefined;
      setMapMounted(false);
    };
  }, [setMapMounted, mapsRef]);

  return {
    isMapMounted,
    setMapMounted,
    mapsRef,
    getBoundsFromPoints,
    getAddressFromLatLng,
    handleMapsLoaded,
    isGeocodeLoading,
    geocode,
  };
};

export default useMap;
