import React from 'react';
import Tabs from 'components/Tabs';
import NewUploads from './NewUploads';
import UploadsList from './UploadsList';

const tabItems = [
  {
    title: 'View Uploads',
    component: <UploadsList />,
    key: 'view',
  },
  {
    title: 'New Upload',
    component: <NewUploads />,
    key: 'new',
  },
];

const MyUploads = () => {
  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between">
        <div className="h3 my-4 font-weight-bold pr-5 mr-5 w-50">My Uploads</div>
      </div>
      <Tabs defaultActiveKey="view" id="myProductTabs" items={tabItems} />
    </div>
  );
};

export default MyUploads;
