import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import Toggle from 'react-toggle';
import StripeLogo from 'assets/images/stripe.png';
import { stripeConnectQuery } from 'requests/stripe';
import { handleErrors } from 'utils/global';
import styles from './index.module.scss';

const PaymentOptions = ({ isStripeConnected }) => {
  const client = useApolloClient();

  const handleStripeSubscribe = async () => {
    try {
      const {
        data: { stripeConnectRequest: redirectUrl },
      } = await client.query({ query: stripeConnectQuery });
      redirectUrl && window.location.assign(redirectUrl);
    } catch (e) {
      handleErrors(e);
    }
  };

  return (
    <div className="d-flex w-50 justify-content-between align-items-center">
      <div className="d-flex justify-content-between align-items-center">
        <img className={styles.logo} src={StripeLogo} />
        <Toggle className="ml-3" checked={isStripeConnected} icons={false} disabled={true} />
      </div>
      {!isStripeConnected && (
        <Button className={styles.connect} variant="primary" size="sm" onClick={handleStripeSubscribe}>
          Connect
        </Button>
      )}
    </div>
  );
};

export default PaymentOptions;
