import React, { createRef, useMemo, useEffect } from 'react';
import cn from 'classnames';
import { Form } from 'react-bootstrap';
import InputError from 'components/InputError';
import styles from './index.module.scss';

const RadioCheckBox = ({ disabled, label, value, errors, onChange, items, disabledOption }) => {
  const refs = useMemo(() => items.map(() => createRef()), [items]);

  const handleChange = (e) => {
    onChange(e.target.id);
  };

  useEffect(() => {
    if (!value) refs.map((ref) => (ref.current.checked = false));
  }, [value, refs]);

  return (
    <>
      <label className="h6" htmlFor="selectItem">
        {label}
      </label>
      <div
        key="inline-radio"
        className={cn('mb-0 d-flex justify-content-start flex-column flex-md-row', styles.form, errors && styles.error)}
      >
        {items.map((item, i) => (
          <Form.Check
            key={item.label}
            ref={refs[i]}
            className={cn('w-50', styles.input)}
            inline
            disabled={item.value === disabledOption || disabled}
            checked={item.value === value}
            onChange={handleChange}
            name={label}
            label={
              <span className={item.value === disabledOption || disabled ? 'cursor-auto' : 'cursor-pointer'}>
                {item.label}
              </span>
            }
            type="radio"
            id={item.value}
          />
        ))}
      </div>
      <InputError value={value} errors={errors} />
    </>
  );
};

export default RadioCheckBox;
