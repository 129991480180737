import React from 'react';
import NoData from 'components/NoData';
import PartCard from 'components/PartCard';

const PartsGrid = ({ part }) => {
  if (part?.length === 0) return <NoData />;

  return (
    <div className="d-flex flex-wrap container pt-3">
      {part?.map((part) => (
        <PartCard key={part.id} part={part} />
      ))}
    </div>
  );
};

export default PartsGrid;
