export const USER_FEES_PERCENTAGE = 20;

export const COMPANY_FEES_PERCENTAGE = 8;

export const PER_HOUR_FEES_PARCENTAGE = 20;

export const ASYNC_OPERATION_TYPES = {
  EMAIL: 'email',
  STRIPE: 'stripe',
};

export const YES = 'Yes';
export const NO = 'No';

export const BOOLEAN_ITEMS = [
  { label: YES, value: YES },
  { label: NO, value: NO },
];

export const LANDING_LINK = 'https://3dteleprint.com';

export const APP_LINKS = [
  { page: 'Home', path: LANDING_LINK },
  { page: 'Products', path: '/product-list' },
  { page: 'Designers', path: '/designers' },
  { page: 'Operators', path: '/operators' },
  { page: 'Locations', path: '/wingman-locations' },
  {
    page: 'Help',
    path: '',
    inner: [
      { page: 'Contact', path: `${LANDING_LINK}/contact` },
      { page: 'Design & Support', path: `${LANDING_LINK}/design-support` },
      { page: 'Returns', path: `${LANDING_LINK}/returns` },
      { page: 'Tutorials', path: `${LANDING_LINK}/tutorials` },
    ],
  },
  { page: 'Order', path: `${LANDING_LINK}/shop` },
];

export const MOCK_PAGINATION = {
  page: 1,
  pageSize: 100,
};

export const NEED_TO_SIGN_IN_ALERT = 'To see this page, you need to sign in';

export const MAX_DIMENSIONS_VALUE = 1000;
