import React from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';

const PaymentSuccess = () => (
  <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <CheckCircleFill color="green" size={96} />
    <h5 className="text-dark text-center mt-3">
      Thank you for your purchase, we&apos;ve started working on it.
      <br />
      You will get updates on <a href="/my-orders">My Orders page</a> soon.
    </h5>
  </div>
);

export default PaymentSuccess;
