import React, { useMemo } from 'react';
import { ADHESION_TYPES, INFILL_PATTERNS, PRINT_QUALITY_OPTIONS } from 'constants/wingman';
import { formatDuration } from 'lib/helpers';
import { getLabelFromValuesList, valueOrDefault, yesOrNo } from 'utils/global';
import Accordion from '../Accordion';

const renderAvailableGCodeValues = ({ label, value }) => (
  <div className="d-flex align-items-center mb-2">
    <span className="font-weight-bold mr-2">{label}:</span>
    <span>{valueOrDefault(value)}</span>
  </div>
);

const AvailableGCodes = ({ gCode }) => {
  const {
    extruderSize,
    materialType,
    printQuality,
    infillSparseDensity,
    infillPattern,
    height,
    length,
    width,
    supportEnable,
    adhesionType,
    retractionEnable,
    retractionDistance,
    estimatedTime,
    model,
  } = gCode;

  const availableGCodeValues = useMemo(
    () => [
      {
        id: 1,
        label: 'Extruder size',
        value: `${extruderSize} mm`,
      },
      {
        id: 2,
        label: 'Material type',
        value: materialType,
      },
      {
        id: 3,
        label: 'Print quality',
        value: getLabelFromValuesList(printQuality, PRINT_QUALITY_OPTIONS),
      },
      {
        id: 4,
        label: 'Infill %',
        value: `${infillSparseDensity} %`,
      },
      {
        id: 5,
        label: 'Infill pattern',
        value: getLabelFromValuesList(infillPattern, INFILL_PATTERNS),
      },
      {
        id: 6,
        label: 'Size (X, Y, Z)',
        value: `${length}mm ${width}mm ${height}mm`,
      },
      {
        id: 7,
        label: 'Support Enabled',
        value: yesOrNo(supportEnable),
      },
      {
        id: 8,
        label: 'Build plate adhesion type',
        value: getLabelFromValuesList(adhesionType, ADHESION_TYPES),
      },
      {
        id: 9,
        label: 'Retraction Enabled',
        value: yesOrNo(retractionEnable),
      },
      {
        id: 10,
        label: 'Retraction Distance',
        value: `${retractionDistance} mm`,
      },
      {
        id: 11,
        label: 'Time',
        value: formatDuration(estimatedTime),
      },
    ],
    [
      adhesionType,
      estimatedTime,
      extruderSize,
      height,
      infillPattern,
      infillSparseDensity,
      length,
      materialType,
      printQuality,
      retractionDistance,
      retractionEnable,
      supportEnable,
      width,
    ]
  );

  return (
    <Accordion title={model?.name} defaultActiveKey={null}>
      <div className="mt-2">
        <div className="d-flex align-items-start">
          <div className="col-6">
            {availableGCodeValues.map(
              ({ id, ...values }, i) => i < 6 && <div key={id}>{renderAvailableGCodeValues(values)}</div>
            )}
          </div>
          <div className="col-9">
            {availableGCodeValues.map(
              ({ id, ...values }, i) => i >= 6 && <div key={id}>{renderAvailableGCodeValues(values)}</div>
            )}
          </div>
        </div>
      </div>
    </Accordion>
  );
};

export default AvailableGCodes;
