import { useJsApiLoader } from '@react-google-maps/api';
// import { useCallback, useEffect } from 'react';

const API_KEY = process.env.REACT_APP_GOOGLE_API_KEY;
// const GOOGLE_MAPS_PLACES_URL = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&language=en`;

const useOldMap = () => {
  const smallMapContainerStyle = {
    width: '100%',
    height: '50vh',
  };

  const { isLoaded } = useJsApiLoader({
    id: 'google-map-script',
    googleMapsApiKey: API_KEY,
  });

  // useEffect(() => {
  //   if (!window.google?.maps?.places) {
  //     const googleMapScript = document.createElement('script');
  //     googleMapScript.src = GOOGLE_MAPS_PLACES_URL;
  //     googleMapScript.async = true;
  //     window.document.body.appendChild(googleMapScript);
  //   }
  // }, []);

  // const geocode = useCallback((request) => {
  //   return new Promise((resolve, reject) => {
  //     const geocoder = new window.google.maps.Geocoder();
  //     geocoder.geocode(request, (result, status) => {
  //       if (status === 'OK') resolve(result?.[0].address_components);
  //       else reject(null);
  //     });
  //   });
  // }, []);

  return { isLoaded, smallMapContainerStyle };
};

export default useOldMap;
