import React, { useContext } from 'react';
import { CheckCircleFill } from 'react-bootstrap-icons';
import { AuthContext } from 'сontexts/AuthContext';

const TransferSuccess = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <CheckCircleFill color="green" size={96} />
      <h5 className="text-dark text-center mt-3">
        Thank you for your purchase, we&apos;ve started transferring part&apos;s ownership.
        <br />
        You will get updates on {user ? <a href={`/my-product/${user.id}`}>My Uploads page</a> : 'My Uploads page'}{' '}
        soon.
        <br />
        If you want to resell it - please, set the price on the Edit page
      </h5>
    </div>
  );
};

export default TransferSuccess;
