import React from 'react';
import { Container, Jumbotron } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import styles from './index.module.scss';

const NoData = () => (
  <Jumbotron className="mt-5 text-center">
    <Container>
      <h5>
        You have no models uploaded yet. To start browsing models for printing, created by others - click on {''}
        <Link to={'/product-list'} className={styles.link}>
          Products
        </Link>
      </h5>
    </Container>
  </Jumbotron>
);

export default NoData;
