import { gql } from '@apollo/client';

export const emailConfirmMutation = gql`
  mutation confirmEmail($email: String!, $token: String!) {
    confirmEmail(email: $email, token: $token)
  }
`;

export const emailUnsubscribeMutation = gql`
  mutation emailUnsubscribe($email: String!, $token: String!, $scope: UnsubscribeScope!) {
    emailUnsubscribe(email: $email, token: $token, scope: $scope)
  }
`;
