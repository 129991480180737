import React from 'react';
import { Controller } from 'react-hook-form';
import { getError } from 'utils/validators';

const ControlledFormItem = ({
  name,
  control,
  rules,
  component: Component,
  errors,
  defaultValue,
  onChange,
  formClassName,
  ...props
}) => (
  <Controller
    control={control}
    name={name}
    defaultValue={defaultValue}
    rules={rules}
    render={({ field: { onChange: controllerChange, value } }) => (
      <div className={formClassName}>
        <Component
          value={value}
          defaultValue={defaultValue}
          onChange={(e) => {
            onChange && onChange(e);
            controllerChange(e);
          }}
          {...props}
          errors={getError(errors)}
        />
      </div>
    )}
  />
);

export default ControlledFormItem;
