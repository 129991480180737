import React from 'react';
import cn from 'classnames';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import { BOOLEAN_ITEMS, MAX_DIMENSIONS_VALUE } from 'constants/global';
import { MIN_SCALE_SIZE, SCALE_SIZE_STEP } from 'constants/part';
import {
  ADHESION_TYPES,
  EXTRUDER_SIZES,
  INFILL_PATTERNS,
  INFILL_PERCENTS,
  MATERIAL_TYPE_ITEMS,
  RETRACTION_DISTANCE_ITEMS,
} from 'constants/wingman';

import usePrintQuality from 'hooks/usePrintQuality';
import { formatSecondsDuration } from 'lib/helpers';
import { getPrinterModelQuery } from 'requests/wingmen';
import { minValue } from 'utils/validators';
import styles from './index.module.scss';

const minValueValidation = minValue(0.01);

const TIME_MIN_VALUE = 0;

const GCodeForm = ({ control, errors, gCode, nameSpace }) => {
  const formatedTime = formatSecondsDuration(gCode?.estimatedTime);

  const extruderSize = gCode?.extruderSize;

  const printQualityItems = usePrintQuality(gCode?.extruderSize);

  return (
    <>
      <div className="row mt-3 mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.model`}
            errors={errors?.model}
            component={SelectQuery}
            query={getPrinterModelQuery}
            defaultValue={gCode?.model}
            labelField="name"
            defaultValueField="name"
            dataPath="printerModels"
            placeholder="Select Printer model"
            isSearchable
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.extruderSize`}
            errors={errors?.extruderSize}
            component={Select}
            placeholder="Select Extruder Size"
            items={EXTRUDER_SIZES}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.materialType`}
            errors={errors?.materialType}
            component={Select}
            placeholder="Select Material Type"
            items={MATERIAL_TYPE_ITEMS}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.printQuality`}
            errors={errors?.printQuality}
            component={Select}
            placeholder="Select Printer Quality"
            items={printQualityItems}
            disabled={!extruderSize}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.infillPercent`}
            errors={errors?.infillPercent}
            component={Select}
            items={INFILL_PERCENTS}
            placeholder="Select Infill percent %"
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.infillPattern`}
            errors={errors?.infillPattern}
            component={Select}
            placeholder="Select Infill pattern"
            items={INFILL_PATTERNS}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.adhesionType`}
            errors={errors?.adhesionType}
            component={Select}
            placeholder="Select Build plate adhesion type"
            items={ADHESION_TYPES}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className={cn('col-8 d-flex align-items-center', styles.printingTime)}>
          <div className="mx-3">Printing Time:</div>
          <div className="col-3 d-flex align-items-center">
            <span className="mx-2">Hrs:</span>
            <ControlledFormItem
              control={control}
              name={`${nameSpace}.printingTime.hours`}
              defaultValue={String(formatedTime?.hours)}
              errors={errors?.printingTime?.hours}
              component={Input}
              type="number"
              min={TIME_MIN_VALUE}
              hasClear
              validation
              rules={{
                required: true,
              }}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <span className="mx-2">Mins:</span>
            <ControlledFormItem
              control={control}
              name={`${nameSpace}.printingTime.minutes`}
              defaultValue={String(formatedTime?.minutes)}
              errors={errors?.printingTime?.minutes}
              component={Input}
              type="number"
              min={TIME_MIN_VALUE}
              hasClear
              validation
              rules={{
                required: true,
              }}
            />
          </div>
          <div className="col-3 d-flex align-items-center">
            <span className="mx-2">Secs:</span>
            <ControlledFormItem
              control={control}
              name={`${nameSpace}.printingTime.seconds`}
              defaultValue={String(formatedTime?.seconds)}
              errors={errors?.printingTime?.seconds}
              component={Input}
              type="number"
              min={TIME_MIN_VALUE}
              hasClear
              validation
              rules={{
                required: true,
              }}
            />
          </div>
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.supportEnable`}
            errors={errors?.supportEnable}
            label="Support enabled"
            component={Select}
            items={BOOLEAN_ITEMS}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.retractionEnable`}
            label="Retraction enabled"
            errors={errors?.retractionEnable}
            component={Select}
            items={BOOLEAN_ITEMS}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.retractionDistance`}
            label="Retraction Distance"
            errors={errors?.retractionDistance}
            component={Select}
            items={RETRACTION_DISTANCE_ITEMS}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row mx-1">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.length`}
            errors={errors?.length}
            label="Length - X(mm)"
            labelInfo={`Max Length ${MAX_DIMENSIONS_VALUE}mm`}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.width`}
            label="Width - Y(mm)"
            labelInfo={`Max Width ${MAX_DIMENSIONS_VALUE}mm`}
            errors={errors?.width}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name={`${nameSpace}.height`}
            errors={errors?.height}
            label="Height - Z(mm)"
            labelInfo={`Max Height ${MAX_DIMENSIONS_VALUE}mm`}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
      </div>
    </>
  );
};

export default GCodeForm;
