import React from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import { ReactComponent as PrinterImage } from 'assets/images/icons/printer.svg';
import { Arrow } from 'components/Arrow';
import styles from './index.module.scss';

const SliderSection = ({ wingmans, sliderRef, slideIndex, setSlideIndex }) => {
  const settings = {
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />,
    infinite: false,
    swipeToSlide: true,
    speed: 200,
    afterChange: () => {
      setSlideIndex(sliderRef.current?.innerSlider.state.targetSlide);
    },
  };

  return (
    <Slider
      className={cn('mb-4', styles.slick)}
      ref={(slider) => (sliderRef.current = slider)}
      {...settings}
      slidesToShow={Math.min(wingmans.length, 4)}
    >
      {wingmans?.map((item, i) => (
        <div
          key={item.id}
          className={cn('d-flex flex-column align-items-center', styles.image, i === slideIndex && styles.activeSlide)}
        >
          <div>{item?.machineName || `Wingman №${item?.id}`}</div>
          <PrinterImage onClick={() => setSlideIndex(i)} className="my-1" fill={item?.status?.value || 'black'} />
          <div>Extruder: {`${Number(item?.printer?.nozzleSize).toFixed(1)} mm` || 'not set'}</div>
          <div>Model: {item?.printer?.model?.name || 'not set'}</div>
          <div>Cost per hour: {item?.printer?.costPerHour || 'not set'}</div>
          <div>Material: {item?.printer?.currentMaterial?.type || 'not set'}</div>
        </div>
      ))}
    </Slider>
  );
};

export default SliderSection;
