import React from 'react';
import { Route } from 'react-router-dom';
import RequireLogout from 'components/RequireLogout';

const ProtectedAuthRoute = (props) => {
  return (
    <RequireLogout>
      <Route {...props} />
    </RequireLogout>
  );
};

export default ProtectedAuthRoute;
