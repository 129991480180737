import { gql } from '@apollo/client';
import { address } from './wingmen';

const threeDContUser = `
  email
  phone
  billingAddress {
    city
    countryCode
    firstAddressLine
    postalCode
    secondAddressLine
    stateProvince
  }
`;

export const getUserQuery = gql`
  query getCurrentUserQuery {
    me {
      id
      userName
      firstName
      lastName
      email
      unconfirmedEmail
      phone
      stripeConnected
      cart {
        cartItems {
          amount
          id
          job {
            actualJobTime
            aesKey
            bedTemperature
            canBeDeleted
            completedTime
            completion
            deliveryMethod
            designerPrice
            dimensionsHeight
            dimensionsLength
            dimensionsWidth
            estimatedJobTime
            estimatedPrintTime
            extruderTemperature
            fromGcode
            gcodeUrl
            id
            insertedAt
            materialLength
            operatorPrice
            price
            resizeMode
            scope
            shippingAddress
            startUpTime
            status
            success
            slicerSetting {
              id
              printQuality
              infillSparseDensity
              infillPattern
            }
            material {
              color
              id
              type
            }
            part {
              availability
              deleted
              description
              dimensionsHeight
              dimensionsLength
              dimensionsWidth
              id
              name
              patentNumber
              pricePerPrint
              status
              supportEnable
              totalPrintsCount
              transferPrice
              partPics {
                id
                isMain
                type
                url
              }
              userMedia {
                id
                url
                threeDContUser {
                  availablePrivateParts
                  balance
                  designerTotalPrintJobs
                  email
                  firstName
                  id
                  lastName
                  operatorTotalPrintJobs
                  phone
                  stripeConnected
                  unconfirmedEmail
                  userName
                }
              }
            }
            wingMan {
              address
              deliveryPolicy
              id
              latitude
              longitude
              machineName
              model
              serialNumber
              show
              totalPrintJobs
              shippingAddress {
                ${address}
              }
            }
          }
        }
      }
      billingAddress {
        city
        countryCode
        firstAddressLine
        postalCode
        secondAddressLine
        stateProvince
      }
      profilePic {
        url
      }
    }
  }
`;

export const updateUserProfileMutation = gql`
  mutation editProfile($billingAddress: AddressInput, $email: String, $phone: String,  $profilePicData: String){
    editProfile(billingAddress: $billingAddress, email: $email, phone:$phone, profilePicData: $profilePicData){
      ${threeDContUser}
      profilePic{
        url
      }
    }
  }
`;

export const resendEmailConfirmationMutation = gql`
  mutation resendEmailConfirmation {
    resendEmailConfirmation
  }
`;
