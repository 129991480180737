import { gql } from '@apollo/client';

export const material = `
  id
  color
  type
`;

export const slicerSetting = `
  adhesionType
  defaultMaterialBedTemperature
  defaultMaterialPrintTemperature
  infillPattern
  infillSparseDensity
  materialBedTemperatureLayerZero
  materialFlow
  materialPrintTemperatureLayerZero
  plasticType
  retractionAmount
  retractionEnable
  printQuality
  part {
    id
  }
`;

export const printer = `
  id
  availableMaterials {
    ${material}
  }
  currentMaterial {
    ${material}
  }
  nozzleSize
  model {
    id
    name
  }
  costPerHour
  slicerSettings {
    ${slicerSetting}
  }
`;

export const address = `
  city
  countryCode
  firstAddressLine
  postalCode
  secondAddressLine
  stateProvince
`;

export const wingman = `
  id
  serialNumber
  machineName
  model
  address
  latitude
  longitude
  printer {
    ${printer}
  }
  jobQueue {
    estimatedPrintTime
  }
  totalPrintJobs
  latitude
  longitude
  threeDContUser {
    id
    userName
  }
  deliveryPolicy
  shippingAddress {
    ${address}
  }

`;

export const onlyLocationWingman = `
  id
  latitude
  longitude
`;

export const getWingmansQuery = gql`
  query threeDContWingmen {
    threeDContWingmen {
      ${wingman}
    }
  }
`;

export const getLocationWingmansQuery = gql`
  query threeDContWingmen {
    threeDContWingmen {
      ${onlyLocationWingman}
    }
  }
`;

export const getWingmanByIdQuery = gql`
  query getWingmanById($id: ID!) {
    wingmanById(wingmanId: $id) {
      ${wingman}
    }
  }
`;

export const getMyWingmenQuery = gql`
  query getMyWingmen($ownerId: ID!) {
    getMyWingmen(ownerId: $ownerId) {
      ${wingman}
    }
  }
`;

export const updateWingmanMutation = gql`
  mutation updateWingman(
    $wingmanId: ID!
    $address: String
    $latitude: Float
    $longitude: Float
    $machineName: String
    $modelId: ID
    $nozzleSize: Decimal
    $costPerHour: Decimal
    $currentMaterialId: ID
    $deliveryPolicy: DeliveryPolicy
    $shippingAddress: AddressInput
  ) {
    updateWingman(
      wingmanId: $wingmanId
      address: $address
      latitude: $latitude
      longitude: $longitude
      machineName: $machineName
      modelId: $modelId
      nozzleSize: $nozzleSize
      costPerHour: $costPerHour
      currentMaterialId: $currentMaterialId
      deliveryPolicy: $deliveryPolicy
      shippingAddress: $shippingAddress
    ) {
      id
    }
  }
`;

export const updateWingmanSlicerSettingsMutation = gql`
  mutation updateWingmanSliserSettings(
    $firstSetting: SlicerSettingArgs
    $secondSetting: SlicerSettingArgs
    $wingmanId: ID!
  ) {
    updateWingmanSliserSettings(firstSetting: $firstSetting, secondSetting: $secondSetting, wingmanId: $wingmanId) {
      id
    }
  }
`;

export const addMaterialMutation = gql`
  mutation addMaterial($color: MaterialColor!, $type: MaterialType!, $printerId: ID!) {
    addMaterial(color: $color, type: $type, printerId: $printerId) {
      id
    }
  }
`;

export const removeMaterialMutation = gql`
  mutation removeMaterial($materialId: ID!) {
    removeMaterial(materialId: $materialId) {
      id
    }
  }
`;

export const createPurchaseOrderMutation = gql`
  mutation createPurchaseOrder($userId: ID!, $model: String) {
    createPurchaseOrder(threeDContUserId: $userId, model: $model) {
      id
    }
  }
`;

export const printQualityOptionsQuery = gql`
  query getPrintQualityOptions {
    printQualities {
      ... on RangeNozzleSize {
        availableNozzleSizes
        printQuality
      }
      ... on StrongNozzleSize {
        availableNozzleSizes
        printQuality
      }
    }
  }
`;

export const getPrinterModelQuery = gql`
  query printerModels($searchValue: String) {
    printerModels(searchValue: $searchValue) {
      id
      name
    }
  }
`;
