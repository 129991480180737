import { gql } from '@apollo/client';
import { printer } from './wingmen';

export const getCurrentUserQuery = gql`
  query getCurrentUser {
    me {
      id
      userName
      firstName
      lastName
      email
      stripeConnected
      availablePrivateParts
      billingAddress {
        city
        countryCode
        firstAddressLine
        postalCode
        secondAddressLine
        stateProvince
      }
      cart {
        cartItems {
          amount
          id
          job {
            id
            part {
              id
            }
          }
        }
      }
      wingmen {
        id
        model
        printer {
          ${printer}
        }
      }
    }
  }
`;

export const singUpMutation = gql`
  mutation signUp($userName: String!, $email: String!, $password: String!, $firstName: String!, $lastName: String!) {
    signup(userName: $userName, email: $email, password: $password, firstName: $firstName, lastName: $lastName)
  }
`;

export const singInMutation = gql`
  mutation signIn($userName: String!, $password: String!) {
    signin(userName: $userName, password: $password) {
      token
      threeDContUser {
        id
        userName
        firstName
        lastName
        email
      }
    }
  }
`;

export const signOutMutation = gql`
  mutation Signout($id: id!) {
    signout(id: $id)
  }
`;

export const resetPasswordMutation = gql`
  mutation resetPasswordRequest($email: String) {
    resetPasswordRequest(email: $email)
  }
`;

export const resetPasswordConfirmMutation = gql`
  mutation resetPasswordConfirm($email: String!, $password: String!, $token: String!) {
    resetPasswordConfirm(email: $email, password: $password, token: $token)
  }
`;
