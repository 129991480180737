import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { toast } from 'react-toastify';
import { NEED_TO_SIGN_IN_ALERT } from 'constants/global';
import { AuthContext } from 'сontexts/AuthContext';

const RequireSignIn = (props) => {
  const { user, userLogOuted } = useContext(AuthContext);

  if (!user && !userLogOuted) {
    toast.info(NEED_TO_SIGN_IN_ALERT, { autoClose: 2000 });
    return <Redirect to="/sign-in" />;
  }
  return props.children;
};

export default RequireSignIn;
