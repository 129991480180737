import { useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { printQualityOptionsQuery } from 'requests/wingmen';
import { getPrintQualityItems } from 'utils/wingman';

const usePrintQuality = (nozzleSize) => {
  const { data: printQualityOptions } = useQuery(printQualityOptionsQuery);
  const printQualities = printQualityOptions?.printQualities;

  const [printQualityItems, setPrintQualityItems] = useState([]);

  useEffect(() => {
    if (nozzleSize) {
      setPrintQualityItems(getPrintQualityItems(Number(nozzleSize)?.toFixed(1), printQualities));
    }
  }, [nozzleSize, printQualities]);

  return printQualityItems;
};

export default usePrintQuality;
