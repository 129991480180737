import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Modal, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import TextArea from 'components/TextArea';
import { sendComplaintMessageMutation } from 'requests/messageRequst';
import { handleErrors } from 'utils/global';

const MessageModal = ({ data: { orderId }, handleClose }) => {
  const client = useApolloClient();

  const {
    control,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const messageHandler = async ({ message }) => {
    try {
      const { data } = await client.mutate({
        mutation: sendComplaintMessageMutation,
        variables: {
          message,
          orderId,
        },
      });

      if (data.sendComplaintMessage === 'ok') {
        toast.success('Dispute Sent');
      }
      handleClose();
    } catch (e) {
      handleErrors(e, 'An error has occurred while sending message');
    }
  };

  return (
    <Modal show={orderId} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>Send Dispute</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <ControlledFormItem
          control={control}
          label="Dispute text"
          name="message"
          errors={errors.message}
          component={TextArea}
        />
        <Alert variant="primary" className="mt-2">
          Please, be complete. Do not forget to include additional details like the name of the part and the reason for
          the dispute.
        </Alert>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit(messageHandler)}>
          Send dispute
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MessageModal;
