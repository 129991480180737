import React from 'react';
import { useQuery } from '@apollo/client';
import SearchInput from 'components/custom-inputs/SearchInput';
import Loader from 'components/Loader';
import Pagination from 'components/Pagination';
import UserCard from 'components/UserCard';
import useDebounce from 'hooks/useDebounce';
import useSearchParams from 'hooks/useSearchParams';
import { getListOperatorsQuery } from 'requests/operators';
import { getPaginationParams } from 'utils/global';

const OperatorsList = () => {
  const { page = 1, pageSize = 9, searchValue = '' } = useSearchParams();

  const debounceValue = useDebounce(searchValue);

  const { data, loading } = useQuery(getListOperatorsQuery, {
    variables: {
      page: +page,
      pageSize: +pageSize,
      ...(debounceValue && { searchValue: debounceValue }),
    },
  });

  const pagination = getPaginationParams(data?.listOperators);

  return (
    <div className="container-fluid">
      <div className="font-weight-bold text-center h3 my-5 mx-1">Wingman Operators</div>
      <div className="col-4 m-auto py-2">
        <SearchInput value={searchValue} hasClear />
      </div>
      <div className="d-flex flex-column align-items-center px-4 mb-3">
        <div className="row justify-content-center px-4 mb-3">
          {loading && <Loader />}
          {data?.listOperators?.entries?.map(({ id, userName, profilePic, operatorTotalPrintJobs }) => (
            <UserCard
              key={id}
              id={id}
              userName={userName}
              profilePic={profilePic}
              totalPrintJobs={operatorTotalPrintJobs}
              entity="operator"
            />
          ))}
        </div>
        <div className="my-3">{pagination.totalPages > 1 && <Pagination pagination={pagination} />}</div>
      </div>
    </div>
  );
};

export default OperatorsList;
