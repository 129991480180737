import React from 'react';
import { Col, Row, Card } from 'react-bootstrap';

import { MATERIAL_TYPES, INFILL_PATTERNS, ADHESION_TYPES, PRINT_QUALITY_OPTIONS } from 'constants/wingman';
import { valueOrDefault, yesOrNo } from 'utils/global';
import { getPrintSettingLabel } from 'utils/job';
import { getSlicerSettingsByType } from 'utils/wingman';
import JobQueue from '../JobQueue';

const WingmanInfo = ({ wingman }) => {
  const printer = wingman?.printer;
  const jobQueue = wingman?.jobQueue;

  const plaSettings = getSlicerSettingsByType(MATERIAL_TYPES.PLA, printer);
  const absSettings = getSlicerSettingsByType(MATERIAL_TYPES.ABS, printer);

  const plaPrintQuality = getPrintSettingLabel(PRINT_QUALITY_OPTIONS, plaSettings?.printQuality);
  const absPrintQuality = getPrintSettingLabel(PRINT_QUALITY_OPTIONS, absSettings?.printQuality);

  const COMMON_INFO = [
    { name: 'Printer model', value: valueOrDefault(printer?.model?.name) },
    { name: 'Cost per hour', value: valueOrDefault(printer?.costPerHour) },
    { name: 'Wingman Address', value: valueOrDefault(wingman?.address) },
    { name: 'Nozzle size', value: valueOrDefault(printer?.nozzleSize) },
    {
      name: 'Current material',
      value: valueOrDefault(
        printer?.currentMaterial?.type,
        `${printer?.currentMaterial?.type} > ${printer?.currentMaterial?.color}`
      ),
    },
  ];

  const PLA_SLICER_SETTINGS_INFO = [
    { name: 'Print quality', value: valueOrDefault(plaPrintQuality) },
    { name: 'Infill percent', value: `${valueOrDefault(plaSettings?.infillSparseDensity)} %` },
    {
      name: 'Infill pattern',
      value: valueOrDefault(INFILL_PATTERNS.find((item) => item.value === plaSettings?.infillPattern)?.label),
    },
    { name: 'Printing temperature', value: `${valueOrDefault(plaSettings?.defaultMaterialPrintTemperature)} ℃` },
    {
      name: 'Printing temp initial layer',
      value: `${valueOrDefault(plaSettings?.materialPrintTemperatureLayerZero)} ℃`,
    },
    { name: 'Build plate temperature', value: `${valueOrDefault(plaSettings?.defaultMaterialBedTemperature)} ℃` },
    {
      name: 'Build plate temp initial layer',
      value: `${valueOrDefault(plaSettings?.materialBedTemperatureLayerZero)} ℃`,
    },
    { name: 'Enable retraction', value: yesOrNo(plaSettings?.retractionEnable) },
    { name: 'Retraction distance', value: `${valueOrDefault(plaSettings?.retractionAmount)} mm` },
    {
      name: 'Build plate adhesion type',
      value: valueOrDefault(ADHESION_TYPES.find((item) => item.value === plaSettings?.adhesionType)?.label),
    },
    { name: 'Flow', value: valueOrDefault(plaSettings?.materialFlow) },
  ];

  const ABS_SLICER_SETTINGS_INFO = [
    { name: 'Print quality', value: valueOrDefault(absPrintQuality) },
    { name: 'Infill percent', value: `${valueOrDefault(absSettings?.infillSparseDensity)} %` },
    {
      name: 'Infill pattern',
      value: valueOrDefault(INFILL_PATTERNS.find((item) => item.value === absSettings?.infillPattern)?.label),
    },
    { name: 'Printing temperature', value: `${valueOrDefault(absSettings?.defaultMaterialPrintTemperature)} ℃` },
    {
      name: 'Printing temp initial layer',
      value: `${valueOrDefault(absSettings?.materialPrintTemperatureLayerZero)} ℃`,
    },
    { name: 'Build plate temperature', value: `${valueOrDefault(absSettings?.defaultMaterialBedTemperature)} ℃` },
    {
      name: 'Build plate temp initial layer',
      value: `${valueOrDefault(absSettings?.materialBedTemperatureLayerZero)} ℃`,
    },
    { name: 'Enable retraction', value: yesOrNo(absSettings?.retractionEnable) },
    { name: 'Retraction distance', value: `${valueOrDefault(absSettings?.retractionAmount)} mm` },
    {
      name: 'Build plate adhesion type',
      value: valueOrDefault(ADHESION_TYPES.find((item) => item.value === absSettings?.adhesionType)?.label),
    },
    { name: 'Flow', value: valueOrDefault(absSettings?.materialFlow) },
  ];

  const renderInfoCard = (title, source) => (
    <Card className="p-3">
      <h5>{title}</h5>
      {source.map(({ name, value }) => (
        <p key={name}>
          <span className="font-weight-bold">{name}:</span> {value}
        </p>
      ))}
    </Card>
  );

  return (
    <div className="container">
      {!!jobQueue?.length && <JobQueue queue={jobQueue} />}
      <Row className="my-3">
        <Col lg={4} sm={4}>
          {renderInfoCard('Common info', COMMON_INFO)}
        </Col>

        <Col lg={4} sm={4}>
          {renderInfoCard('PLA print settings', PLA_SLICER_SETTINGS_INFO)}
        </Col>

        <Col lg={4} sm={4}>
          {renderInfoCard('ABS print settings', ABS_SLICER_SETTINGS_INFO)}
        </Col>
      </Row>
    </div>
  );
};

export default WingmanInfo;
