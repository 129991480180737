import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import homeImg from 'assets/images/home-2.jpg';
import { AuthContext } from 'сontexts/AuthContext';

const Home = () => {
  const { user } = useContext(AuthContext);

  return (
    <div className="main-section d-flex align-items-center w-100">
      <div className="row w-100">
        <div className="col-md-6">
          <div className="heroImg">
            <img src={homeImg} className="img-fluid" alt="" />
          </div>
        </div>
        <div className="col-md-6">
          <div className="wingman-caption pe-0 pe-lg-5">
            <h2 className="mb-0 font-weight-bold text-dark">Welcome designers and 3D printer operators.</h2>
            <p className="lead font-weight-regular lh-base text-dark mt-3">
              Designers can send print jobs to 3d printers. 3D printer operators can receive print job requests from
              anyone remotely in real time. See how the Wingman by 3DTeleprint changes remote manufacturing and design
              with the transport of print jobs instead of object files. The wingman is your 3DPrinters personal
              assistant.
            </p>
            <div className="pt-3">
              {!user && (
                <Link to="/sign-in" className="btn btn-xl btn-primary px-lg-5">
                  Get started!
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
