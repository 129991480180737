import React, { useContext, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { withRouter } from 'react-router';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthPageWrapper from 'components/AuthPageWrapper';
import ControlledFormItem from 'components/ControlledFormItem/index';
import Input from 'components/Input/index';
import { NEED_TO_SIGN_IN_ALERT } from 'constants/global';
import { getCurrentUserQuery, singInMutation } from 'requests/auth';
import { getUserToken, handleErrors } from 'utils/global';
import { minLength, maxLength } from 'utils/validators';
import { AuthContext } from 'сontexts/AuthContext';
import { linkWithSockets } from '../../client';

const minLengthValidation = minLength(2);
const maxLengthValidation = maxLength(50);

const Signin = () => {
  const client = useApolloClient();
  const history = useHistory();
  const location = useLocation();
  const { setUser } = useContext(AuthContext);

  useEffect(() => {
    if (location?.query?.path) toast.info(NEED_TO_SIGN_IN_ALERT, { autoClose: 2000 });
  }, [location?.query?.path]);

  const {
    control,
    loading,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const singInHandler = async (values) => {
    try {
      const { data } = await client.mutate({
        mutation: singInMutation,
        variables: values,
      });
      if (data?.signin?.token) {
        setUser(data.signin.threeDContUser);
        handleCompleted(data);
        handleUpdate(data);
      }
    } catch (e) {
      handleErrors(e, 'An error has occurred while singing in');
    }
  };

  const handleCompleted = (data) => {
    localStorage.setItem('auth-token', data.signin.token);
    client.setLink(linkWithSockets(getUserToken()));
    history.push(location?.query?.path || `/my-product/${data.signin.threeDContUser.id}`);
  };

  const handleUpdate = (data) => {
    client.cache.writeQuery({
      query: getCurrentUserQuery,
      data: { me: data.signin.threeDContUser },
    });
  };

  return (
    <AuthPageWrapper title="Sign In">
      <form className="p-2" onSubmit={handleSubmit(singInHandler)}>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="userName"
            label="Username"
            errors={errors.userName}
            component={Input}
            hasClear
            autoFocus
            placeholder="Username"
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="password"
            label="Password"
            errors={errors.password}
            component={Input}
            type="password"
            hasClear
            placeholder="Password"
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="d-grid">
          <Button disabled={loading} type="submit" size="lg">
            Sign In
          </Button>
        </div>
        <div className="mt-4 d-flex">
          <div className="form-check form-check-sm d-flex align-items-center mb-0">
            <input className="form-check-input" type="checkbox" id="gridCheck" />
            <label className="form-check-label text-gray-800 mt-2" htmlFor="gridCheck">
              Remember me
            </label>
          </div>
          <Link to="/forgot-password" className="caption text-gray-800 ms-auto ml-2 mt-2 text-primary">
            Forgot password?
          </Link>
        </div>
      </form>
      <div className="text-center pt-4 pt-md-5 pb-2">
        <p className="mb-0 text-gray-800">
          {`Don't have an account yet?`}
          <Link to="/sign-up" className="text-primary font-weight-semibold ml-1">
            Sign up
          </Link>
        </p>
      </div>
    </AuthPageWrapper>
  );
};

export default withRouter(withApollo(Signin));
