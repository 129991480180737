import { YES } from 'constants/global';
import { yesOrNo } from './global';

export const convertTimeToSeconds = ({ hours, minutes, seconds }) =>
  Number(hours) * 3600 + Number(minutes) * 60 + Number(seconds);

export const getGCodeVariables = (data) =>
  data.map(
    ({
      id,
      printingTime,
      gCodeFile,
      infillPercent,
      model,
      supportEnable,
      retractionEnable,
      height,
      length,
      width,
      ...gCode
    }) => ({
      id,
      width,
      length,
      height,
      estimatedTime: convertTimeToSeconds(printingTime),
      fileData: gCodeFile?.data,
      infillSparseDensity: Number(infillPercent),
      modelId: model?.id,
      supportEnable: supportEnable === YES,
      retractionEnable: retractionEnable === YES,
      adhesionType: gCode?.adhesionType,
      extruderSize: gCode?.extruderSize,
      infillPattern: gCode?.infillPattern,
      materialType: gCode?.materialType,
      printQuality: gCode?.printQuality,
      retractionDistance: gCode?.retractionDistance,
    })
  );

export const getGCodesDefaultValues = (data) =>
  data?.map(({ infillSparseDensity, supportEnable, retractionEnable, ...gCode }) => ({
    infillPercent: String(infillSparseDensity),
    supportEnable: yesOrNo(supportEnable),
    retractionEnable: yesOrNo(retractionEnable),
    ...gCode,
  }));
