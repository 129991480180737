import React from 'react';
import { XCircleFill } from 'react-bootstrap-icons';

const TransferReject = () => (
  <div className="d-flex flex-column justify-content-center align-items-center w-100">
    <XCircleFill color="red" size={96} />
    <h5 className="text-dark text-center mt-3">
      Something went wrong and your payment wasn&apos;t successful. <br />
      Please, try again, and if you will get this message again, please,{' '}
      <a href="mailto:support_link">contact support</a>.
    </h5>
  </div>
);

export default TransferReject;
