import React from 'react';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import styles from './index.module.scss';

const InputError = ({ errors, clear, value }) => {
  return (
    <div className={cn(styles.container, 'd-flex justify-content-between invalid-feedback')}>
      <div>{`${errors ? errors?.message : ''}`} </div>
      {clear && value && (
        <div onClick={clear} className="d-flex align-items-center cursor-pointer mt-1">
          <Button variant="danger" size="sm">
            Clear
          </Button>
        </div>
      )}
    </div>
  );
};

export default InputError;
