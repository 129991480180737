import { gql } from '@apollo/client';
import { wingman } from './wingmen';

const operator = `
  id
  userName
  profilePic{
    url
  }
  operatorTotalPrintJobs
`;

export const pagination = `
  pageNumber
  pageSize
  totalEntries
  totalPages
  entries {
    ${operator}
  }
`;

export const getListOperatorsQuery = gql`
  query listOperators(
    $searchValue: String
    $page: Int
    $pageSize: Int
    ) {
    listOperators(
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
      ) {
      ${pagination}
    }
  }
`;

export const getOperatorByIdQuery = gql`
  query getOperatorById($id: ID!) {
    operatorById(id: $id) {
      ${operator}
      wingmen {
        ${wingman}
      }
    }
  }
`;
