import { gql } from '@apollo/client';

export const temporaryPart = `
  id
  jobs {
    id
    startUpTime
  }
	supportEnable
	userMedia {
    url
    threeDContUser {
      id
      userName
      stripeConnected
    }
  }
`;

export const createTemporaryPartMutation = gql`
  mutation createTemporaryPart (
		$supportEnable: Boolean!
		$provider: ID!
		$fileData: String!
  ) {
			createTemporaryPart (
				supportEnable: $supportEnable
				provider: $provider
				fileData: $fileData
		) {
			${temporaryPart}
		}
	}
`;