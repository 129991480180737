import React from 'react';
import styles from './index.module.scss';

const Counter = ({ value, onMinusClick, onPlusClick }) => {
  return (
    <div className={styles.counter}>
      <button
        className={styles.button}
        onClick={() => {
          onMinusClick(value - 1);
        }}
      >
        {'-'}
      </button>
      <span className={styles.count}>{value}</span>
      <button
        className={styles.button}
        onClick={() => {
          onPlusClick(value + 1);
        }}
      >
        {'+'}
      </button>
    </div>
  );
};

export default Counter;
