import React, { useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, ModalBody, ModalFooter } from 'react-bootstrap';
import { toast } from 'react-toastify';
import Modal from 'components/ModalWrapper';
import { MOCK_PAGINATION } from 'constants/global';
import { deletePartMutation, getMyPartsQuery } from 'requests/partRequest';
import { handleErrors } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';

const DeletePartModal = ({ isVisible, onClose, partId, setModalVisible }) => {
  const client = useApolloClient();

  const { user } = useContext(AuthContext);

  const handleDeletePart = () => {
    try {
      client.mutate({
        mutation: deletePartMutation,
        variables: {
          id: partId,
        },
        refetchQueries: [
          { query: getMyPartsQuery, variables: { ...MOCK_PAGINATION, filter: { ownerIds: [user.id] } } },
        ],
      });
      toast.success('Part deleted successfully');
      setModalVisible(false);
    } catch (e) {
      handleErrors(e, 'The part delete error has occurred');
    }
  };

  return (
    <Modal visible={isVisible} onClose={onClose}>
      <ModalBody>
        Delete <span className="text-warning font-weight-bold">{partId}</span> part?{' '}
      </ModalBody>
      <ModalFooter>
        <Button className="btn-danger" onClick={handleDeletePart}>
          Delete
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default DeletePartModal;
