import React, { useEffect, useState } from 'react';
import { Tab, Tabs as TabsComponent } from 'react-bootstrap';
import styles from './index.module.scss';

const Tabs = ({ id, items, defaultActiveKey, setCurrentKey, onTabChange }) => {
  const [key, setKey] = useState(defaultActiveKey || items[0]?.key);

  useEffect(() => {
    setCurrentKey && setCurrentKey(key);
  }, [key, setCurrentKey]);

  return (
    <TabsComponent
      defaultActiveKey={defaultActiveKey}
      id={id}
      activeKey={key}
      onSelect={(k) => {
        setKey(k);
        onTabChange?.(k);
      }}
      className="d-flex justify-content-center"
    >
      {items.map((tabItem) => (
        <Tab key={tabItem.key} tabClassName={styles.tab} eventKey={tabItem.key} title={tabItem.title}>
          {React.cloneElement(tabItem.component, { setActiveTab: setKey })}
        </Tab>
      ))}
    </TabsComponent>
  );
};

export default Tabs;
