import React from 'react';
import { FaBan } from 'react-icons/fa';
import image from 'assets/images/noImage.jpeg';
import styles from './index.module.scss';

const NoImage = ({ error, size = 60 }) => (
  <div className={styles.noImage}>
    <img className={styles.img} src={image} alt="noImage" />
    {error && (
      <div className={styles.error}>
        <div>
          <FaBan size={`${size}px`} color="#be616a" />
        </div>
        <div color="#be616a">load Error</div>
      </div>
    )}
  </div>
);

export default NoImage;
