import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import PhoneNumberInput from 'components/PhoneNumberInput';
import { resendEmailConfirmationMutation } from 'requests/user';

const ProfileForm = ({ currentUser, control, errors }) => {
  const client = useApolloClient();

  return (
    <>
      {currentUser?.unconfirmedEmail && (
        <div className="d-flex align-items-end w-100 mt-2">
          <ControlledFormItem
            formClassName="w-100"
            control={control}
            name="unconfirmedEmail"
            label="Unconfirmed Email"
            disabled
            errors={errors.unconfirmedEmail}
            defaultValue={currentUser?.unconfirmedEmail}
            component={Input}
          />
          <Button
            className="ml-5 w-100 h-50"
            variant="secondary"
            onClick={async () => {
              await client.mutate({
                mutation: resendEmailConfirmationMutation,
              });
              toast.success(
                'The verification email was successfully sent. To use the updated email, please follow the verification link sent to your email.'
              );
            }}
          >
            RESEND VERIFICATION EMAIL
          </Button>
        </div>
      )}

      <ControlledFormItem
        control={control}
        formClassName="mt-4"
        name="email"
        label="Email"
        placeholder="Enter your email"
        errors={errors.email}
        defaultValue={currentUser?.email}
        component={Input}
        hasClear
        validation
        rules={{
          required: true,
        }}
      />
      <div className="text-left px-2 py-3 mb-4">
        {`After clicking the Update button, if you requested to change your email, you'll receive a new email with a
        verification link. This email is valid only for 2 hours. Clicking the link verifies your new email, allowing you
        to use the updated address.`}
      </div>

      <ControlledFormItem
        control={control}
        name="phone"
        label="Phone"
        errors={errors.phone}
        defaultValue={currentUser?.phone}
        disabled
        component={PhoneNumberInput}
        validation
        rules={{
          required: true,
        }}
      />
    </>
  );
};

export default ProfileForm;
