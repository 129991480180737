import React, { useState, useEffect } from 'react';
import { useQuery, useApolloClient } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PartList from 'components/PartList';
import { ALL, AVAILABILITY_OPTIONS } from 'constants/part';
import useDebounce from 'hooks/useDebounce';
import useSearchParams from 'hooks/useSearchParams';
import { getMyPartsQuery, updatePartSubscription } from 'requests/partRequest';
import { findAndReplace, handleErrors, getPaginationParams } from 'utils/global';

import Filter from './Filter';

const UploadsList = () => {
  const [categoryInput, setCategoryInput] = useState();
  const [visibility, setVisibility] = useState(ALL);

  const { id } = useParams();
  const client = useApolloClient();

  const { page = 1, pageSize = 9, searchValue = '' } = useSearchParams();

  const debounceValue = useDebounce(searchValue, 1500);

  const { loading, error, data, updateQuery } = useQuery(getMyPartsQuery, {
    variables: {
      page: +page,
      pageSize: +pageSize,
      filter: {
        ...(categoryInput && { categoryIds: [categoryInput] }),
        ...(visibility !== ALL && { availability: visibility === AVAILABILITY_OPTIONS.PUBLIC }),
      },
      ...(debounceValue && { searchValue: debounceValue }),
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    let subscription;
    const updateParts = () => {
      subscription = client
        .subscribe({
          query: updatePartSubscription,
          variables: {
            userId: id,
          },
        })
        .subscribe({
          next({ data: newEntry }) {
            updateQuery((previousState) => {
              const queryData = previousState?.getMyParts.entries;
              const newEntryData = newEntry?.partUpdateSubscription;

              /* Update existed part */
              if (queryData.find((part) => part.id === newEntryData?.id)) {
                return {
                  getMyParts: findAndReplace(queryData, newEntryData),
                };
              }

              /* Add new part */
              return {
                getMyParts: [...queryData, { ...newEntryData }],
              };
            });
          },
          error(error) {
            handleErrors(error);
          },
        });
    };

    updateParts();

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [client, id, updateQuery]);

  return (
    <Row className="mx-2 mx-lg-0 ">
      <Col lg={2} sm={12}>
        <div className="search pt-4 pb-3 d-flex flex-column justify-content-stretch">
          <Filter
            searchValue={searchValue}
            category={categoryInput}
            categoryChange={setCategoryInput}
            visibility={visibility}
            visibilityChange={setVisibility}
            isPart={true}
            hasClear
            id={id}
          />
        </div>
      </Col>
      <Col lg={10} sm={12}>
        {error ? (
          <Error error={error} />
        ) : loading ? (
          <Loading />
        ) : (
          <PartList parts={data?.getMyParts?.entries} pagination={getPaginationParams(data?.getMyParts)} />
        )}
      </Col>
    </Row>
  );
};

export default UploadsList;
