import React, { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import qs from 'qs';
import { CheckCircleFill, XCircleFill } from 'react-bootstrap-icons';
import { useHistory } from 'react-router-dom';

import Loader from 'components/Loader';
import { ASYNC_OPERATION_TYPES } from 'constants/global';
import { handleErrors } from 'utils/global';

const AsyncOperation = ({ successMessage, errorMessage = 'Something went wrong. Try again', mutation, type }) => {
  const client = useApolloClient();
  const history = useHistory();

  const [isLoading, setLoading] = useState(true);
  const [hasError, setError] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        let variables;
        setLoading(true);
        if (type === ASYNC_OPERATION_TYPES.EMAIL) {
          variables = qs.parse(history.location.search, { ignoreQueryPrefix: true });
        } else {
          const { code } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
          variables = { code };
        }

        await client.mutate({ mutation, variables });
      } catch (e) {
        setError(true);
        handleErrors(e, errorMessage);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return (
    <div className="d-flex justify-content-center align-items-center w-100">
      {isLoading ? (
        <Loader type="primary" />
      ) : (
        <>
          {hasError ? (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <XCircleFill color="red" size={96} />
              <h5 className="text-dark mt-3">{errorMessage}</h5>
            </div>
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center">
              <CheckCircleFill color="green" size={96} />
              <h5 className="text-dark mt-3">{successMessage}</h5>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default AsyncOperation;
