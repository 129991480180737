import React, { useState, useEffect } from 'react';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';
import { Card, Button } from 'react-bootstrap';
import Counter from 'components/Counter';
import NoImage from 'components/NoImage';
import { PRINT_QUALITY_OPTIONS, INFILL_PATTERNS } from 'constants/wingman';
import useDebounce from 'hooks/useDebounce';
import { formatDuration } from 'lib/helpers';
import { updateCartItemMutation, deleteCartItemMutation } from 'requests/jobs';
import { handleErrors } from 'utils/global';
import styles from './index.module.scss';

const BasketCard = ({ id, amount, job, refetch, setCardLoading }) => {
  const client = useApolloClient();

  const [count, setCount] = useState(amount);
  const [removed, setRemoved] = useState(false);

  const debounceCount = useDebounce(count, 500);

  const renderImage = () => {
    const mainPhoto = job?.part?.partPics?.find(({ isMain }) => isMain);

    if (mainPhoto?.url || job?.part?.partPics[0]?.url)
      return (
        <img
          src={mainPhoto?.url || job?.part?.partPics[0]?.url}
          alt={job?.part.name}
          className={cn(styles.photoSlider)}
        />
      );

    return <NoImage />;
  };

  useEffect(() => {
    if (debounceCount === 0) {
      try {
        client
          .mutate({
            mutation: deleteCartItemMutation,
            variables: { id },
          })
          .then(() => {
            refetch().then(() => setCardLoading(false));
          });
      } catch (error) {
        handleErrors(error);
        setCardLoading(false);
      }
    } else if (amount !== debounceCount && id) {
      try {
        client
          .mutate({
            mutation: updateCartItemMutation,
            variables: { id, amount: debounceCount },
          })
          .then(() => {
            refetch().then(() => setCardLoading(false));
          });
      } catch (error) {
        handleErrors(error);
        setCardLoading(false);
      }
    }
  }, [amount, client, debounceCount, id, refetch, setCardLoading]);

  if (removed) {
    return null;
  }

  return (
    <Card key={id} className={cn('shadow h-100 w-100', styles.card)}>
      <Card.Body className="p-2 d-flex justify-content-between">
        <div className={cn('d-flex justify-content-between', styles.partWrapper)}>
          <div className={cn(styles.photoSlider)}>{renderImage()}</div>
          <div className={'p-4 m-0'}>
            <div className={'d-flex justify-content-between'}>
              <h5 className={'card-title font-weight-bold'}>{job?.part?.name}</h5>
              {false && <span className={'ml-2 card-title font-weight-bold'}>{`(Status: ${job?.part?.status})`}</span>}
            </div>

            <div className={'mt-2'}>
              <span className={'font-weight-bold'}>{'Parameters: '}</span>
              <span>{`${job?.material?.type} ${job?.material?.color}, ${
                PRINT_QUALITY_OPTIONS.find((item) => item.value === job?.slicerSetting?.printQuality).label
              } print quality`}</span>
            </div>
            <div className={'mt-1'}>
              <span className={'font-weight-bold'}>{'Infill: '}</span>
              <span>{`${job?.slicerSetting?.infillSparseDensity}%, ${
                INFILL_PATTERNS.find((item) => item.value === job?.slicerSetting?.infillPattern)?.label
              } pattern`}</span>
            </div>
            <div className={'mt-1'}>
              <span className={'font-weight-bold'}>{'Size: '}</span>
              <span>{`L (X):${job?.dimensionsLength}mm; W (Y):${job?.dimensionsWidth}mm; H (Z):${job?.dimensionsHeight}mm;`}</span>
            </div>
            <div className={'mt-1'}>
              <span className={'font-weight-bold'}>{'Wingman: '}</span>
              <span>{`${job?.wingMan?.machineName}`}</span>
            </div>
            <div className={'mt-1'}>
              <span className={'font-weight-bold'}>{'Estimated print time: '}</span>
              <span>{`${formatDuration(job?.estimatedPrintTime)}`}</span>
            </div>
          </div>
        </div>

        {amount && (
          <div className={styles.amount}>
            <div className={'mt-1'}>
              <span className={'font-weight-bold'}>{`$${(job?.price * count).toFixed(2)}`}</span>
            </div>
            <Counter
              value={count}
              onMinusClick={(value) => {
                setCardLoading(true);
                setCount(value);
              }}
              onPlusClick={(value) => {
                setCardLoading(true);
                setCount(value);
              }}
            />
            <Button
              variant="warning"
              onClick={() => {
                setCardLoading(true);
                setRemoved(true);
                setCount(0);
              }}
            >
              Remove
            </Button>
          </div>
        )}
      </Card.Body>
    </Card>
  );
};

export default BasketCard;
