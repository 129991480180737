import React from 'react';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import ControlledFormItem from 'components/ControlledFormItem';
import Select from 'components/Select';
import { MATERIAL_TYPE_ITEMS, MATERIAL_COLORS } from 'constants/wingman';

const AddMaterialsForm = ({ onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    clearErrors,
  } = useForm();

  const [typeValue, colorValue] = watch(['type', 'color']);

  const onAdd = (props) => {
    onSubmit(props);
    reset();
    clearErrors();
  };

  return (
    <>
      <div className="font-weight-bold">Add available material and color to profile</div>
      <form className="p-2" onSubmit={handleSubmit(onAdd)}>
        <ControlledFormItem
          control={control}
          name="type"
          label="Material"
          placeholder="Select material to add"
          errors={errors.type}
          value={typeValue}
          component={Select}
          items={MATERIAL_TYPE_ITEMS}
          validation
          rules={{ required: true }}
        />
        <ControlledFormItem
          control={control}
          name="color"
          label="Color"
          placeholder="Select materal color to add"
          errors={errors.color}
          value={colorValue}
          component={Select}
          items={MATERIAL_COLORS}
          validation
          rules={{ required: true }}
        />
        <div className="text-center mt-2 mb-3">
          <Button variant="warning" type="submit">
            Add
          </Button>
        </div>
      </form>
    </>
  );
};

export default AddMaterialsForm;
