import React from 'react';
import AsyncOperation from 'components/AsyncOperation';
import { ASYNC_OPERATION_TYPES } from 'constants/global';
import { emailUnsubscribeMutation } from 'requests/email';

const EmailUnsubscribe = () => (
  <AsyncOperation
    type={ASYNC_OPERATION_TYPES.EMAIL}
    successMessage="You've been successfully unsubscribed from all emails"
    mutation={emailUnsubscribeMutation}
  />
);

export default EmailUnsubscribe;
