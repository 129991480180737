import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { camelCase } from 'lodash';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import InputError from 'components/InputError';

const SelectSearch = ({
  label,
  labelClassName,
  wrapperClassName,
  value,
  defaultValue,
  onChange,
  hasClear,
  errors,
  items,
  placeholder,
  validation,
  disabled,
  renderCustomOptions,
  renderInputStatus,
  defaultValueField = 'id',
}) => {
  const selectRef = useRef();

  const [selected, setSelected] = useState([]);

  useEffect(() => {
    if (items && defaultValue) {
      const defaultItem = items?.find(({ value }) => value === defaultValue);

      if (defaultItem) {
        setSelected([defaultItem]);
      }
    }
  }, [defaultValue, defaultValueField, items]);

  useEffect(() => {
    if (!value) {
      setSelected([]);
    } else {
      const newValue = items?.find((item) => item.value === value);
      if (newValue) {
        setSelected([newValue]);
      } else {
        setSelected([]);
      }
    }
  }, [items, value]);

  const changeSearchableHandler = (selected) => {
    selected.length && onChange?.(selected[0].value);
    setSelected(selected);
  };

  const clear = () => {
    onChange?.(null);
    if (selectRef.current) selectRef.current.selectedIndex = 0;
  };

  return (
    <div className={cn(wrapperClassName || 'py-0')}>
      {label && (
        <label className={cn(labelClassName || 'h6')} htmlFor={`selectItem-${camelCase(label)}`}>
          {label}
        </label>
      )}
      <Typeahead
        ref={selectRef}
        id="selectSearchableItem"
        onChange={changeSearchableHandler}
        options={items || []}
        placeholder={placeholder || 'Search and select option'}
        clearButton={hasClear}
        selected={selected}
        disabled={disabled}
        renderMenuItemChildren={renderCustomOptions}
      >
        {({ onClear, selected }) => (
          <div className="rbt-aux">
            {hasClear && !disabled && !!selected.length && (
              <>
                <ClearButton
                  onClick={() => {
                    onClear();
                    clear?.();
                  }}
                />
              </>
            )}
          </div>
        )}
      </Typeahead>

      {renderInputStatus && renderInputStatus()}
      {validation && <InputError value={value} errors={errors} />}
    </div>
  );
};

export default SelectSearch;
