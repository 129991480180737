import React, { useContext } from 'react';
import { useQuery } from '@apollo/client';
import Loader from 'components/Loader';
import Map from 'components/Map';
import useOldMap from 'hooks/useOldMap';
import { getWingmansQuery, getLocationWingmansQuery } from 'requests/wingmen';
import { getMarkerOptions } from 'utils/map';
import { AuthContext } from 'сontexts/AuthContext';

const NearestOperators = () => {
  const { user } = useContext(AuthContext);
  const { isLoaded } = useOldMap();

  const { data: wingmanData, loading } = useQuery(user ? getWingmansQuery : getLocationWingmansQuery, {
    fetchPolicy: 'network-only',
  });

  return (
    <div className="container">
      <div className="font-weight-bold text-center h3 my-5 mx-1">Wingman Locations</div>
      {isLoaded && !loading ? (
        <Map position={getMarkerOptions(wingmanData?.threeDContWingmen)} popupValues={wingmanData?.threeDContWingmen} />
      ) : (
        <div className="d-flex justify-content-center align-items-center w-100">
          <Loader />
        </div>
      )}
    </div>
  );
};

export default NearestOperators;
