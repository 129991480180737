import React, { useRef, useMemo } from 'react';
import cn from 'classnames';
import Slider from 'react-slick';
import { Arrow } from 'components/Arrow';
import NoImage from 'components/NoImage';
import styles from './index.module.scss';

const PhotoSlider = ({ photoUrls }) => {
  const slider1 = useRef();
  const slider2 = useRef();

  const settings = {
    nextArrow: <Arrow next />,
    prevArrow: <Arrow />,
  };

  const orderedPhotoUrls = useMemo(() => {
    let ordered = photoUrls;
    const mainPhoto = photoUrls?.find(({ isMain }) => isMain);

    if (mainPhoto) {
      const filteredArray = photoUrls?.filter((picture) => picture.id !== mainPhoto?.id);
      ordered = [mainPhoto, ...filteredArray];
    }

    return ordered;
  }, [photoUrls]);

  if (orderedPhotoUrls?.length === 1) {
    return (
      <div className="text-center my-4">
        <img src={orderedPhotoUrls[0].url} alt={`sliderImg-${1}`} className={cn('img-fluid w-100', styles.image)} />
      </div>
    );
  }
  return (
    <div className="slider-container">
      {orderedPhotoUrls?.length ? (
        <div className="my-4">
          <Slider {...settings} asNavFor={slider2.current} ref={(slider) => (slider1.current = slider)}>
            {orderedPhotoUrls?.map((urlItem, i) => (
              <img
                key={urlItem}
                src={urlItem.url}
                alt={`sliderImg-${i}`}
                className={cn('img-fluid w-100', styles.image)}
              />
            ))}
          </Slider>
          <Slider
            className="mt-4"
            asNavFor={slider1.current}
            ref={(slider) => (slider2.current = slider)}
            slidesToShow={Math.min(orderedPhotoUrls?.length ?? 0, 3)}
            swipeToSlide
            focusOnSelect
          >
            {orderedPhotoUrls?.map((urlItem, i) => (
              <div key={`${urlItem}control`} className="imageContainer">
                <img
                  className="img-thumbnail mx-auto"
                  src={urlItem.url}
                  alt={`sliderImg-${i}`}
                  style={{ height: '70px' }}
                  role="button"
                />
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <NoImage />
      )}
    </div>
  );
};

export default PhotoSlider;
