import React from 'react';
import Footer from 'components/Footer';
import Header from 'components/Header';

const AppLayout = ({ children }) => {
  return (
    <div id="app" className="d-flex flex-column min-vh-100 justify-content-start align-items-stretch">
      <Header />
      <div className="d-flex flex-grow-1 px-lg-0 position-relative">{children}</div>
      <Footer />
    </div>
  );
};

export default AppLayout;
