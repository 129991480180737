import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';
import dateFnsFormat from 'date-fns/format';
import { isString } from 'lodash';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from 'components/Loader';
import { LOCAL_PICKUP, PART_SIZE_OPTIONS } from 'constants/part';
import { DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import { formatCurrency, formatDuration } from 'lib/helpers';
import {
  asyncUpdateJobCostMutation,
  createJobMutation,
  createCartItemMutation,
  updateJobSubscription,
} from 'requests/jobs';
import { handleErrors, joinAddress } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';
import styles from './index.module.scss';
import TemporaryCostForm from './TemporaryCostForm';
import TemporaryPartForm from './TemporaryPartForm';

const DATE_FORMAT = 'MM-dd-yyyy hh:mm:ss a';
const ADD_TO_CART_BUTTON_TITLE = 'Add to cart';

const OneTimePrint = () => {
  const client = useApolloClient();
  const { handleSubmit } = useForm();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const [jobData, setJobData] = useState();
  const [wingman, setWingman] = useState();
  const [isCalculating, setCalculating] = useState(false);
  const [showCostMessage, setShowCostMessage] = useState(false);
  const [updatedJobData, setUpdatedJobData] = useState();
  const [costValues, setCostValues] = useState();
  const [temporaryPartId, setTemporaryPartId] = useState(null);

  useEffect(() => {
    isCalculating && setTimeout(() => setShowCostMessage(true), 10000);
  }, [costValues, isCalculating, updatedJobData]);

  useEffect(() => {
    if (updatedJobData) {
      setCalculating(false);
      setShowCostMessage(false);
    }
  }, [updatedJobData]);

  const submitAddToCart = async () => {
    try {
      const { data } = await client.mutate({
        mutation: createCartItemMutation,
        variables: { jobId: jobData?.id || updatedJobData?.id, amount: 1 },
      });

      if (data.createCartItem.cartItems.length) {
        toast.success(`Part was successfully added to the cart.`);
        history.push(`/my-cart`);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const updateJob = (jobId) => {
    let subscription;

    if (jobId) {
      subscription = client
        .subscribe({
          query: updateJobSubscription,
          variables: { jobId },
        })
        .subscribe({
          next({ data }) {
            const updatedData = data?.jobUpdateSubscription;

            setUpdatedJobData(updatedData);
          },
        });
    } else {
      subscription?.unsubscribe();
    }
  };

  const operatorCostData = useMemo(
    () => [
      {
        id: '1',
        name: 'Part print time',
        value: jobData?.estimatedPrintTime || updatedJobData?.estimatedPrintTime,
        description: '(part print time)',
      },
      { id: '2', name: 'Cost per hour', value: wingman?.printer?.costPerHour, description: '(per hour) ' },
      {
        id: '3',
        name: 'Cost per print',
        value: jobData?.operatorPrice || updatedJobData?.operatorPrice,
        description: '(part print time * cost per hour)',
      },
      {
        id: '4',
        name: 'Total operator cost',
        value: jobData?.operatorPrice || updatedJobData?.operatorPrice,
        total: true,
      },
    ],
    [
      jobData?.estimatedPrintTime,
      jobData?.operatorPrice,
      updatedJobData?.estimatedPrintTime,
      updatedJobData?.operatorPrice,
      wingman?.printer?.costPerHour,
    ]
  );

  const viewCostSubmit = async ({ color, percent, type, wingman, size, ...values }) => {
    setCalculating(true);

    const availableMaterials = wingman?.printer?.availableMaterials;

    const checkCalculatingValuesAreReady = (data) => {
      if (data) return !!data?.estimatedPrintTime && !!data?.designerPrice && !!data?.operatorPrice;
    };

    const selectedMaterial = availableMaterials?.find((singleMaterial) => {
      if (singleMaterial?.type === type && singleMaterial?.color === color) {
        return singleMaterial;
      }
    });

    const partDetailValues = {
      printerMaterialId: Number(selectedMaterial?.id),
      infillSparseDensity: Number(percent),
      infillPattern: values?.pattern,
      ...values,
    };

    const costFormValues = {
      temporaryPartId,
      wingman,
      percent: Number(percent),
      partCreatedDate: dateFnsFormat(new Date(), DATE_FORMAT),
      color,
      type,
      size: size || PART_SIZE_OPTIONS.ORIGINAL,
      ...values,
    };

    try {
      if (!jobData?.id) {
        const { data: jobQueryResult } = await client.mutate({
          mutation: createJobMutation,
          variables: {
            deliveryMethod: LOCAL_PICKUP,
            threeDContUserId: user?.id,
            wingManId: wingman?.id,
            temporaryPartId,
            printerId: wingman?.printer?.id,
            ...partDetailValues,
          },
        });

        const jobQueryData = jobQueryResult?.createJob;

        const jobId = jobQueryData?.id;

        setJobData(jobQueryData);
        setCostValues(costFormValues);
        if (checkCalculatingValuesAreReady(jobQueryData)) {
          setCalculating(false);
          setUpdatedJobData(jobQueryData);
        } else {
          updateJob(jobId);
        }
      } else {
        const jobId = jobData?.id;
        const { data: asyncUpdateJobResult } = await client.mutate({
          mutation: asyncUpdateJobCostMutation,
          variables: { jobId, ...partDetailValues },
        });

        const jobQueryData = asyncUpdateJobResult?.asyncUpdateJobCost;
        if (checkCalculatingValuesAreReady(jobQueryData)) {
          setCalculating(false);
          setUpdatedJobData(jobQueryData);
        } else {
          updateJob(jobId);
        }
      }
    } catch (error) {
      handleErrors(error);
      setCalculating(false);
    }
  };

  const renderCostBlock = (type, data) => {
    const totalFieldValue = data.find((item) => item.total)?.value;

    const zeroCostText = () => {
      if (type === 'Designer') return 'You are the owner of the part';
      if (type === 'Operator') return 'You are the owner of the selected wingman';
    };

    return (
      <>
        <label>{`${type} expenses`}</label>
        <table className="table">
          <tbody className="font-weight-normal">
            {isString(totalFieldValue) && Number(totalFieldValue) === 0 ? (
              <tr>
                <td>{zeroCostText()}</td>
              </tr>
            ) : (
              <>
                {data.map(({ id, name, value, description }) => (
                  <tr key={id}>
                    <td className={styles.costNameColumn}>
                      <div className="ml-4">{name}</div>
                    </td>
                    <td className={styles.costValueColumn}>
                      <div className="pl-4">
                        {name === 'Part print time' ? formatDuration(value) : formatCurrency(value)}
                      </div>
                    </td>
                    <td>
                      <div className="pl-4 font-size-14 font-weight-light">{description}</div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="container-fluid">
      <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center h3 mt-4 font-weight-bold offset-md-1 pr-5 w-50">
          New one-time print
        </div>
      </div>
      <div className="row"></div>
      <div className="row">
        <div className="col-md-5 mt-5 offset-md-1">
          <TemporaryPartForm
            provider={user?.id}
            onSubmitComplited={(temporaryPart) => {
              setTemporaryPartId(temporaryPart.id);
            }}
          />
        </div>
        <div className="col-md-5 mt-5">
          {wingman && wingman?.deliveryPolicy !== DELIVERY_POLICY_SETTINGS.SHIP && (
            <div className="h6 mt-5 bold py-4">
              Pick-up location: {wingman?.shippingAddress ? joinAddress(wingman?.shippingAddress) : '-'}
            </div>
          )}
          <TemporaryCostForm
            onSubmit={viewCostSubmit}
            setWingman={setWingman}
            setJobData={setJobData}
            isCalculating={isCalculating}
            temporaryPartId={temporaryPartId}
          />
          {(jobData || updatedJobData) && (
            <>
              {!isCalculating ? (
                <div className="h6">
                  {renderCostBlock('Operator', operatorCostData)}
                  <div>
                    Total Print cost
                    <span className="pl-4 font-weight-normal">
                      {formatCurrency(Number(updatedJobData?.operatorPrice) + Number(updatedJobData?.designerPrice))}
                    </span>
                  </div>
                </div>
              ) : (
                <div className="d-flex justify-content-center flex-column align-items-center w-100">
                  {showCostMessage && (
                    <div className="mb-4  alert alert-warning" role="alert">
                      We are calculating the price for this job. It could take up to 5 min. You can leave this page, and
                      we will notify you about the result.
                    </div>
                  )}
                  <Loader type="primary" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center p-4">
        {!updatedJobData?.id || isCalculating ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-disabled">Please click View print cost first</Tooltip>}
          >
            <Button size="lg" className={cn('w-25 disabled btn-secondary not-allowed', styles.disabledBtn)}>
              {ADD_TO_CART_BUTTON_TITLE}
            </Button>
          </OverlayTrigger>
        ) : (
          <Button size="lg" className="w-25" onClick={handleSubmit(submitAddToCart)}>
            {ADD_TO_CART_BUTTON_TITLE}
          </Button>
        )}
      </div>
    </div>
  );
};

export default OneTimePrint;
