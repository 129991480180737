import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import cn from 'classnames';
import { Container, Row, Col, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { DELIVERY_INFORMATION_ITEMS } from 'constants/part';
import { purchaseOrderMutation } from 'requests/jobs';
import { getUserQuery } from 'requests/user';
import BasketCard from './BasketCard';
import DeliveryForm from './DeliveryForm';
import styles from './index.module.scss';
import TotalCostForm from './TotalCostForm';

const MyCart = () => {
  const client = useApolloClient();

  const { data, error, loading, refetch } = useQuery(getUserQuery, { fetchPolicy: 'network-only' });

  const [purchaseLoading, setPurchaseLoading] = useState(false);
  const [cardLoading, setCardLoading] = useState(false);
  const [deliveyInfoCompleted, setDeliveyInfoCompleted] = useState(false);
  const [shipping, setShipping] = useState(false);
  const [estimateTotalCost, setEstimateTotalCost] = useState(null);
  const [shippingRates, setShippingRates] = useState(null);
  const [shippingRatesVariables, setShippingRatesVariables] = useState(null);

  useEffect(() => {
    if (cardLoading) {
      setDeliveyInfoCompleted(false);
      setShipping(false);
      setEstimateTotalCost(null);
    }
  }, [cardLoading]);

  const handleComplete = useCallback((completed, shipping) => {
    setEstimateTotalCost(null);
    setDeliveyInfoCompleted(completed);
    setShipping(shipping);
  }, []);

  const basketCards = useMemo(() => {
    if (data?.me?.cart?.cartItems) {
      return data.me.cart.cartItems
        .sort((a, b) => a.id - b.id)
        .map(({ id, amount, job }) => (
          <BasketCard key={id} id={id} amount={amount} job={job} refetch={refetch} setCardLoading={setCardLoading} />
        ));
    } else {
      return null;
    }
  }, [data?.me?.cart?.cartItems, refetch]);

  const cartPrices = useMemo(
    () =>
      (estimateTotalCost?.cartItems ?? []).map((cartItem) => {
        return (
          <div key={cartItem.id} className={'mt-1'}>
            <span
              className={'font-weight-bold'}
            >{`Part ${cartItem?.job?.part?.name} ($${cartItem.job.price}) x${cartItem?.amount}: `}</span>
            <span>{`$${((cartItem?.amount ?? 1) * cartItem.job.price).toFixed(2)}`}</span>
          </div>
        );
      }),
    [estimateTotalCost?.cartItems]
  );

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;

  if (!data?.me?.cart?.cartItems?.length) {
    return <h3 className="text-center">Cart is empty</h3>;
  }

  return (
    <Container fluid className="p-3">
      <div className="h3 my-4 font-weight-bold">My Cart</div>
      <Row>
        <Col>
          <h5 className="font-weight-bold pl-3 ">Cart parts:</h5>
          <Container fluid className={cn('p-3', styles.basketCards)}>
            {basketCards}
          </Container>
        </Col>
        <Col>
          {cardLoading ? (
            <Loading />
          ) : (
            <>
              <DeliveryForm
                currentUser={data?.me}
                onComplete={handleComplete}
                onGetShippingRates={(data, shippingRatesVariables) => {
                  setShippingRates(data);
                  setShippingRatesVariables(shippingRatesVariables);
                }}
              />

              {deliveyInfoCompleted && (
                <TotalCostForm
                  shippingRates={shippingRates}
                  shippingRatesVariables={shippingRatesVariables}
                  shipping={shipping}
                  onComplete={(estimateCartCostData) => {
                    setEstimateTotalCost(estimateCartCostData);
                  }}
                />
              )}
              {estimateTotalCost && (
                <>
                  <div className="mb-4 mr-4">
                    <div className="d-flex flex-column  justify-content-center mb-4 ml-4">
                      {cartPrices}
                      {estimateTotalCost.deliveryMethod && (
                        <div className={'mt-1'}>
                          <span className={'font-weight-bold'}>{'Delivery Method: '}</span>
                          <span>
                            {
                              DELIVERY_INFORMATION_ITEMS.find(({ value }) => value === estimateTotalCost.deliveryMethod)
                                ?.label
                            }
                          </span>
                        </div>
                      )}
                      {estimateTotalCost.tax && (
                        <div className={'mt-1'}>
                          <span className={'font-weight-bold'}>{'Taxes '}</span>
                          <OverlayTrigger
                            placement="top"
                            overlay={<Tooltip id="tooltip-disabled">Based on your billing address</Tooltip>}
                          >
                            <span className={'font-weight-bold'}>{'ⓘ'}</span>
                          </OverlayTrigger>
                          <span className={'font-weight-bold'}>{': '}</span>
                          <span>{`$${estimateTotalCost.tax}`}</span>
                        </div>
                      )}

                      {estimateTotalCost.shippingCost && (
                        <div className={'mt-1'}>
                          <span className={'font-weight-bold'}>{'Shipping: '}</span>
                          <span>{`$${estimateTotalCost.shippingCost}`}</span>
                        </div>
                      )}
                      {estimateTotalCost.processingFee && (
                        <div className={'mt-1'}>
                          <span className={'font-weight-bold'}>{'Processing fee: '}</span>
                          <span>{`$${estimateTotalCost.processingFee}`}</span>
                        </div>
                      )}
                      {estimateTotalCost.totalCost && (
                        <div className={'mt-4'}>
                          <span className={'font-weight-bold'}>{'Total: '}</span>
                          <span>{`$${estimateTotalCost.totalCost}`}</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <Button
                    onClick={() => {
                      setPurchaseLoading(true);

                      client
                        .mutate({
                          mutation: purchaseOrderMutation,
                        })
                        .then((res) => {
                          setPurchaseLoading(false);
                          res?.data?.purchaseOrder && window.location.assign(res.data.purchaseOrder);
                        });
                    }}
                    disabled={purchaseLoading}
                    variant="primary"
                    className="ml-3"
                  >
                    Purchase
                  </Button>
                </>
              )}
            </>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default MyCart;
