import React, { useRef, useMemo, useState, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import RadioCheckBox from 'components/RadioCheckBox';
import { LOCAL_PICKUP, SHIPPING } from 'constants/part';
import { estimateCartCostMutation } from 'requests/jobs';
import { handleErrors } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';
import styles from './index.module.scss';

const TotalCostForm = ({ shipping, onComplete, shippingRates, shippingRatesVariables }) => {
  const client = useApolloClient();
  const { user } = useContext(AuthContext);

  const [loading, setLoading] = useState(false);

  const toastId = useRef(null);
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
  } = useForm();

  const submitHandler = async ({ serviceCode }) => {
    try {
      setLoading(true);
      const data = await client.mutate({
        mutation: estimateCartCostMutation,
        variables: {
          deliveryMethod: shipping ? SHIPPING : LOCAL_PICKUP,
          ...(serviceCode && shipping && { serviceCode: serviceCode }),
          ...shippingRatesVariables,
        },
      });

      onComplete(data?.data?.estimateCartCost);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.dismiss(toastId.current);
      handleErrors(
        'error',
        <>
          <span>{'Please set your billing address '}</span>
          <Link to={`/user/${user?.id}`} className="text-primary">
            here
          </Link>
          <span>{' to estimate the cart cost.'}</span>
        </>
      );
    }
  };

  const items = useMemo(
    () =>
      (shippingRates ?? []).map((shippingRate, i) => {
        if (!i) {
          setValue('serviceCode', shippingRate.serviceCode);
        }
        return {
          label: (
            <>
              <span className="d-block">{`${shippingRate.serviceType}: ${shippingRate.carrierDeliveryDays}`}</span>
              <span className="d-block font-weight-bold">${shippingRate.shippingCost}</span>
            </>
          ),
          value: shippingRate.serviceCode,
        };
      }),
    [setValue, shippingRates]
  );

  return (
    <form onSubmit={handleSubmit(submitHandler)} className="w-100">
      {shipping && items && (
        <div className={styles.radio}>
          <ControlledFormItem
            control={control}
            name="serviceCode"
            items={items}
            errors={errors.deliveryInformation}
            defaultValue={items?.[0]?.value}
            component={RadioCheckBox}
            onChange={(value) => {
              setValue('serviceCode', value);
              onComplete?.(null);
            }}
            rules={{
              required: true,
            }}
          />
        </div>
      )}

      <div className="mb-4 ml-3">
        <Button disabled={loading} onClick={handleSubmit(submitHandler)} variant="primary">
          Estimate the cart total cost
        </Button>
      </div>
    </form>
  );
};

export default TotalCostForm;
