import { gql } from '@apollo/client';

const category = `
  description
  id
  name
`;

const reviews = `
  id
  comment
  rating
  insertedAt
  threeDContUser {
    userName
  }
`;

const partPics = `
  id
  isMain
  part {
    id
  }
  type
  url
`;

export const gCodes = `
  id
  extruderSize
  infillPattern
  infillSparseDensity
  printQuality
  materialType
  estimatedTime
  retractionDistance
  retractionEnable
  supportEnable
  adhesionType
  width
  length
  height
  model {
    id
    name
  }
`;

export const part = `
  catagory {
    ${category}
  }
  description
  id
  jobs {
    id
    startUpTime
  }
  name
  partPics {
    ${partPics}
  }
  patentNumber
  pricePerPrint
  availability
  userMedia {
    url
    threeDContUser {
      id
      userName
      stripeConnected
    }
  }
  dimensionsHeight
  dimensionsLength
  dimensionsWidth
  description
  reviews {
    ${reviews}
  }
  supportEnable
  status
  transferPrice
  gcodes {
    ${gCodes}
  }
`;

export const pagination = `
  pageNumber
  pageSize
  totalEntries
  totalPages
  entries {
    ${part}
  }
`;

export const getPartsQuery = gql`
  query threeDContParts(
    $filter: PartFilter!
    $searchValue: String
    $sortBy: SortByPart
    $pageSize: Int
    $page: Int
  ) {
    threeDContParts(
      filter: $filter
      searchValue: $searchValue
      sortBy: $sortBy
      pageSize: $pageSize
      page: $page
    ) {
      ${pagination}
    }
  }
`;

export const getMyPartsQuery = gql`
  query getMyParts(
    $filter: PartFilter!
    $searchValue: String
    $sortBy: SortByPart
    $page: Int
    $pageSize: Int
  ) {
    getMyParts(
      filter: $filter
      searchValue: $searchValue
      sortBy: $sortBy
      page: $page
      pageSize: $pageSize
    ) {
      ${pagination}
    }
  }
`;

export const createPartMutation = gql`
  mutation createPart (
    $availability: UserPartAvailability!
    $catagoryId: ID!
    $description: String!
    $dimensionsHeight: Decimal!
    $dimensionsLength: Decimal!
    $dimensionsWidth: Decimal!
    $fileData: String!
    $name: String!
    $partPics: [PartPics]!
    $patentNumber: String
    $pricePerPrint: Decimal!
    $provider: ID!
    $mainPhotoType: PartPicType
    $transferPrice: Decimal
    $supportEnable: Boolean!
    $gcodes: [GcodeInput]
  ) {
    createPart (
      availability: $availability
      catagoryId: $catagoryId
      description: $description
      dimensionsHeight: $dimensionsHeight
      dimensionsLength: $dimensionsLength
      dimensionsWidth: $dimensionsWidth
      fileData: $fileData
      name: $name
      partPics: $partPics
      patentNumber: $patentNumber
      pricePerPrint: $pricePerPrint
      provider: $provider
      mainPhotoType: $mainPhotoType
      transferPrice: $transferPrice
      supportEnable: $supportEnable
      gcodes: $gcodes
  ) {
    ${part}
  }
}
`;

export const getSinglePartByNameQuery = gql`
  query getSinglePartByName($name: String!) {
    getSinglePartByName(name: $name) {
      ${part}
    }
  }
`;

export const getSinglePartByIdQuery = gql`
  query getSinglePartById($partId: ID!) {
    partById(partId: $partId) {
      ${part}
    }
  }
`;

export const updatePartSubscription = gql`
  subscription updatePartSubscription($userId: ID!){
    partUpdateSubscription(userId: $userId){
      ${part}
    }
  }
`;

export const updatePartMutation = gql`
  mutation updatePart(
    $availability: UserPartAvailability
    $catagoryId: ID
    $description: String
    $dimensionsHeight: Decimal
    $dimensionsLength: Decimal
    $dimensionsWidth: Decimal
    $fileData: String
    $id: ID!
    $name: String
    $partPics: [PartPics]
    $patentNumber: String
    $pricePerPrint: Decimal
    $mainPhotoType: PartPicType
    $transferPrice: Decimal
    $supportEnable: Boolean!
    $gcodes: [GcodeInput]
  ){
    updatePart(
      availability: $availability
      catagoryId: $catagoryId
      description: $description
      dimensionsHeight: $dimensionsHeight
      dimensionsLength: $dimensionsLength
      dimensionsWidth: $dimensionsWidth
      fileData: $fileData
      id: $id
      name: $name
      partPics: $partPics
      patentNumber: $patentNumber
      pricePerPrint: $pricePerPrint
      mainPhotoType: $mainPhotoType
      transferPrice: $transferPrice
      supportEnable: $supportEnable
      gcodes: $gcodes
    ){
      ${part}
    }
}
`;

export const getPartByIdQuery = gql`
  query partById($partId: ID!) {
    partById(partId: $partId){
      ${part}
    }
  }
`;

export const deletePartMutation = gql`
  mutation deletePart($id: ID!) {
    deletePart(id: $id)
  }
`;

export const partTransferMutation = gql`
  mutation partTransfer($partId: ID!) {
    partTransfer(partId: $partId)
  }
`;

export const sendRequestGCodeMutation = gql`
  mutation sendGCodeRequest(
    $adhesionType: AdhesionTypes!
    $extruderSize: Decimal!
    $height: Decimal!
    $infillPattern: InfillPatterns!
    $infillSparseDensity: Int!
    $length: Decimal!
    $materialType: MaterialType!
    $modelId: ID!
    $partId: ID!
    $printQuality: PrintQuality!
    $retractionDistance: Decimal!
    $retractionEnabled: Boolean!
    $supportEnabled: Boolean!
    $width: Decimal!
  ) {
    sendGCodeRequest(
      adhesionType: $adhesionType
      extruderSize: $extruderSize
      height: $height
      infillPattern: $infillPattern
      infillSparseDensity: $infillSparseDensity
      length: $length
      materialType: $materialType
      modelId: $modelId
      partId: $partId
      printQuality: $printQuality
      retractionDistance: $retractionDistance
      retractionEnabled: $retractionEnabled
      supportEnabled: $supportEnabled
      width: $width
    )
  }
`;

export const getPartGcodesQuery = gql`
  query getPartGcodes($partId: ID!){
    getPartGcodes(partId: $partId){
      ${gCodes}
    }
  }
`;
