import { gql } from '@apollo/client';

export const sendMessageMutation = gql`
  mutation sendMessage($message: String!, $partId: ID!) {
    sendMessage(message: $message, partId: $partId)
  }
`;

export const sendComplaintMessageMutation = gql`
  mutation sendComplaintMessage($message: String!, $orderId: ID!) {
    sendComplaintMessage(message: $message, orderId: $orderId)
  }
`;
