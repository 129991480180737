import { gql } from '@apollo/client';
import { pagination as partPagination } from './partRequest';

const designer = `
  id
  userName
  profilePic{
    url
  }
  designerTotalPrintJobs
`;
export const pagination = `
  pageNumber
  pageSize
  totalEntries
  totalPages
  entries {
    ${designer}
  }
`;

export const getListDesignersQuery = gql`
  query listDesigners(
    $searchValue: String
    $page: Int
    $pageSize: Int   
    ) {
    listDesigners(
      searchValue: $searchValue
      page: $page
      pageSize: $pageSize
    ) {
      ${pagination}
    }
  }
`;

export const getListDesignersPartsQuery = gql`
  query listDesignerParts(
    $id: ID!
    $page: Int
    $pageSize: Int
    ) {
    listDesignerParts(
      id: $id
      page: $page
      pageSize: $pageSize
      ) {
        ${partPagination}
      }
    }
`;
