import React from 'react';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import SelectSearch from 'components/SelectSearch';
import { STATES_ITEMS } from 'constants/wingman';
import { isUS, isZipValid } from 'utils/validators';

const AddressForm = ({ currentUser, control, errors }) => {
  return (
    <div className="py-2">
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="firstName"
            label="First Name"
            placeholder="Enter your first name"
            errors={errors.firstName}
            defaultValue={currentUser?.firstName ?? ''}
            className="w-100"
            component={Input}
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="lastName"
            label="Last Name"
            placeholder="Enter your Last Name"
            errors={errors.lastName}
            defaultValue={currentUser?.lastName ?? ''}
            component={Input}
            className="w-100"
            hasClear
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <ControlledFormItem
        control={control}
        name="country"
        label="Country"
        placeholder="Country"
        errors={errors.addressCountry}
        defaultValue={'United States'}
        component={Input}
        disabled
        validation
        rules={{ required: true, validate: isUS }}
      />
      <ControlledFormItem
        control={control}
        name="firstAddressLine"
        label="Address Line 1"
        placeholder="Enter Address Line 1"
        errors={errors?.firstAddressLine}
        defaultValue={currentUser?.billingAddress?.firstAddressLine}
        component={Input}
        hasClear
        validation
        rules={{ required: true }}
      />
      <ControlledFormItem
        control={control}
        name="secondAddressLine"
        label="Address Line 2"
        placeholder="Enter Address Line 2"
        errors={errors?.secondAddressLine}
        defaultValue={currentUser?.billingAddress?.secondAddressLine}
        component={Input}
        hasClear
        validation
      />
      <ControlledFormItem
        control={control}
        name="city"
        label="City"
        placeholder="Enter City"
        errors={errors?.city}
        defaultValue={currentUser?.billingAddress?.city}
        component={Input}
        hasClear
        validation
        rules={{ required: true }}
      />
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="postalCode"
            label="ZIP"
            placeholder="Enter ZIP"
            errors={errors?.postalCode}
            defaultValue={currentUser?.billingAddress?.postalCode}
            component={Input}
            className="w-100"
            hasClear
            validation
            rules={{ required: true, validate: { isZipValid } }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="stateProvince"
            label="State/Province"
            placeholder="Enter State/Province"
            errors={errors?.stateProvince}
            defaultValue={currentUser?.billingAddress?.stateProvince}
            component={SelectSearch}
            className="w-100"
            hasClear
            validation
            rules={{ required: true }}
            items={STATES_ITEMS}
          />
        </div>
      </div>
    </div>
  );
};

export default AddressForm;
