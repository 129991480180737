import React from 'react';
import SearchInput from 'components/custom-inputs/SearchInput';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import { AVAILABILITY_ITEMS_FILTER } from 'constants/part';
import { getCategoriesQuery } from 'requests/category';

const Filter = ({ searchValue, category, categoryChange, hasClear, visibility, visibilityChange }) => (
  <div className="search-criteria">
    <SearchInput title="Search" value={searchValue} hasClear={hasClear} />
    <h4>Category</h4>
    <SelectQuery
      value={category}
      onChange={categoryChange}
      hasClear={hasClear}
      placeholder="Select category"
      labelField="name"
      isSearchable
      valueField="id"
      query={getCategoriesQuery}
      dataPath="threeDContCatagories"
    />
    <h4>Availability</h4>
    <Select value={visibility} onChange={visibilityChange} items={AVAILABILITY_ITEMS_FILTER} />
  </div>
);

export default Filter;
