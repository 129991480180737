import { isArray, isString, inRange } from 'lodash';
import { PRINT_QUALITY_OPTIONS } from 'constants/wingman';

export const getSlicerSettingsByType = (type, printer) => {
  let settings;
  const slicerSettings = printer?.slicerSettings;
  if (slicerSettings) settings = slicerSettings.find((setting) => setting.plasticType === type);

  return settings;
};

export const getPrintQualityItems = (selectedNozzleSize, printQualities) => {
  const availableQualityItems = [];

  PRINT_QUALITY_OPTIONS.map((qualityItem) => {
    const { value: qualityItemValue } = qualityItem;
    const foundItem = printQualities?.find((item) => item.printQuality === qualityItemValue);
    const availableNozzleSizes = foundItem?.availableNozzleSizes;

    if (isArray(availableNozzleSizes)) {
      /* +0.01 for checking end of available range cause `inRange` doesn't include the last element */
      inRange(selectedNozzleSize, availableNozzleSizes[0], Number(availableNozzleSizes[1]) + 0.01) &&
        availableQualityItems.push(qualityItem);
    } else if (isString(availableNozzleSizes)) {
      selectedNozzleSize == availableNozzleSizes && availableQualityItems.push(qualityItem);
    }
  });

  return availableQualityItems;
};
