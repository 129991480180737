import React, { useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import mapImg from 'assets/images/world-map.png';
import { createPurchaseOrderMutation } from 'requests/wingmen';
import { handleErrors } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';

const WingmanOrder = () => {
  const client = useApolloClient();
  const { user } = useContext(AuthContext);
  const [isRequestWasSent, setRequestSent] = useState(false);

  const onSubmit = async () => {
    try {
      await client.mutate({
        mutation: createPurchaseOrderMutation,
        variables: { userId: user?.id, model: 'v1' },
      });
      toast.success('The order is successfully accepted');
      setRequestSent(true);
    } catch (e) {
      handleErrors(e);
    }
  };

  return (
    <div className="main-section w-100 text-center my-4">
      <div className="col-md-12 my-2">
        <h2 className="font-weight-bold text-dark">Designer Benefits</h2>
        <h6 className="mb-4">Send and receive print jobs from anywhere to anywhere</h6>
        <img src={mapImg} className="img-fluid" alt="Responsive image"></img>
        <h6 className="mt-4">
          The best platform for designers allowing you to charge by print job without distributing object files. Keep
          your IP Secure
        </h6>
      </div>

      <div className="col-md-12 mt-5">
        <h2 className="font-weight-bold text-dark">Order a Wingman</h2>
        <div className="text-center my-3">
          {isRequestWasSent ? (
            <h6 className="mb-4 text-success">
              Thank you! Your order was succesfully accepted. We`ll contact with you by email.
            </h6>
          ) : (
            <Button type="submit" variant="primary" className="btn-lg" onClick={onSubmit}>
              Place order
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default WingmanOrder;
