import React, { useEffect, useCallback } from 'react';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import { INFILL_PERCENTS, INFILL_PATTERNS, DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import usePrintQuality from 'hooks/usePrintQuality';
import { getWingmansQuery } from 'requests/wingmen';
import styles from './index.module.scss';

const PICKUP_STATUS = 'Pickup Only';
const PICKUP_AND_SHIP_STATUS = 'Ship and Pickup';
const SHIP_STATUS = 'Ship Only';

const TemporaryCostForm = ({ onSubmit, setWingman, setJobData, isCalculating, temporaryPartId }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [wingman, type] = watch(['wingman', 'type']);

  const printQualityItems = usePrintQuality(wingman?.printer?.nozzleSize);

  useEffect(() => {
    if (!type) {
      setValue('color', null);
      setValue('printQuality', null);
      setValue('percent', null);
      setValue('pattern', null);
    }

    setJobData(null);
    setWingman(wingman);

    if (wingman) {
      setWingman(wingman);
    } else {
      setWingman(null);
      setValue('type', null);
    }
  }, [wingman, type]);

  useEffect(() => {
    !type && setValue('printQuality', null);
  }, [setValue, type]);

  const getMaterialTypesItems = () => {
    let materialTypes = [];
    const uniqueItems = [...new Set(wingman?.printer?.availableMaterials.map((item) => item.type))];
    if (uniqueItems.length) materialTypes = uniqueItems.map((item) => ({ label: item, value: item }));

    return materialTypes;
  };

  const getColorItems = () => {
    let colorItems = [];

    const filtered = wingman?.printer?.availableMaterials?.filter((singleMaterial) => singleMaterial.type === type);
    if (filtered?.length) colorItems = filtered.map(({ color }) => ({ label: color, value: color }));

    return colorItems;
  };

  const onFormError = (formErrors) => {
    if (!isEmpty(formErrors)) toast.error("Some fields aren't filled");
  };

  const renderDeliveryPolicyStatus = useCallback((availableField) => {
    if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP) {
      return <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.pickupColor)}>{PICKUP_STATUS}</div>;
    } else if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP) {
      return (
        <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.pickupAndShipColor)}>
          {PICKUP_AND_SHIP_STATUS}
        </div>
      );
    } else if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.SHIP) {
      return <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.shipColor)}>{SHIP_STATUS}</div>;
    }
    return null;
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit, onFormError)}>
      <ControlledFormItem
        control={control}
        name="wingman"
        label="Pick wingman"
        placeholder="Select wingman"
        errors={errors.wingman}
        component={SelectQuery}
        renderInputStatus={() => (
          <div className="d-flex justify-content-end">{renderDeliveryPolicyStatus(wingman)}</div>
        )}
        isSearchable
        labelField="machineName"
        defaultValueField="machineName"
        query={getWingmansQuery}
        dataPath="threeDContWingmen"
        disabled={!temporaryPartId}
        hasClear
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="type"
        label="Material type"
        errors={errors.type}
        placeholder="Select type"
        component={Select}
        items={getMaterialTypesItems()}
        hasClear
        disabled={!wingman}
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="color"
        label="Material color"
        placeholder="Select color"
        errors={errors.color}
        component={Select}
        items={getColorItems()}
        hasClear
        disabled={!wingman || !type || !getColorItems().length}
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="printQuality"
        label="Print quality"
        placeholder="Print quality"
        component={Select}
        items={printQualityItems}
        disabled={!wingman || !type}
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="percent"
        label="InFill %"
        placeholder="Select %"
        errors={errors.percent}
        component={Select}
        items={INFILL_PERCENTS}
        hasClear
        disabled={!wingman || !type}
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="pattern"
        label="InFill pattern"
        placeholder="Select pattern"
        errors={errors.pattern}
        component={Select}
        items={INFILL_PATTERNS}
        hasClear
        disabled={!wingman || !type}
        validation
        rules={{
          required: true,
        }}
      />
      <div className=" border-bottom">
        <div className="row justify-content-center">
          <Button
            onClick={handleSubmit(onSubmit, onFormError)}
            variant="primary"
            className="justify-content-center mb-4"
            disabled={isCalculating}
          >
            View print cost
          </Button>
        </div>
      </div>
    </form>
  );
};

export default TemporaryCostForm;
