import React, { useRef } from 'react';
import { useApolloClient } from '@apollo/client';
import { Alert, Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import FileInput from 'components/FileInput';
import RadioCheckBox from 'components/RadioCheckBox';
import { BOOLEAN_ITEMS, NO, YES } from 'constants/global';
import { MAX_FILE_SIZE_NON_SUBSCRIBERS } from 'constants/uploads';
import { createTemporaryPartMutation } from 'requests/temporaryPart';
import { handleErrors } from 'utils/global';
import { fileSize, fileType } from 'utils/validators';

const modelFileTypeValidation = fileType(['stl']);
const modelFileSizeValidation = fileSize(MAX_FILE_SIZE_NON_SUBSCRIBERS);

const TemporaryPartForm = ({ onSubmitComplited, provider }) => {
  const toastId = useRef(null);
  const client = useApolloClient();
  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    clearErrors,
  } = useForm();

  const submitHandler = async ({ file, supportEnable }) => {
    try {
      toastId.current = toast.loading('Uploading...', { autoClose: false });
      const data = await client.mutate({
        mutation: createTemporaryPartMutation,
        variables: {
          supportEnable: supportEnable === YES,
          fileData: file?.data,
          provider,
        },
      });
      toast.dismiss(toastId.current);
      toast.success('Upload Complete');
      clearErrors();
      reset();
      onSubmitComplited(data.data.createTemporaryPart);
    } catch (error) {
      toast.dismiss(toastId.current);
      handleErrors(error, 'An error has occurred while uploading');
    }
  };

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      <div className="row align-items-center">
        <div className="col-md-12 mb-3">
          <ControlledFormItem
            control={control}
            name="file"
            label="Pick Part File"
            labelInfo="Limit 50 MB per file. Coming soon 500 MB for subscribers"
            errors={errors.file}
            component={FileInput}
            validation
            rules={{
              required: true,
              validate: { modelFileTypeValidation, modelFileSizeValidation },
            }}
          />
        </div>
        <div className="col-md-12 mb-3 font-weight-bold">
          <Alert variant={'primary'}>
            Ensure your design file is saved in STL format and follow our model landing and rotation guidelines before
            uploading it to 3DTelePrint. For a step-by-step guide,{' '}
            <Alert.Link
              href="https://www.youtube.com/watch?v=fZP-ZPU-GtY"
              variant={'primary'}
              className="h4 font-weight-bold"
            >
              watch our video
            </Alert.Link>
          </Alert>
        </div>
      </div>
      <div className="row">
        <div className="col-5 mb-3">
          <ControlledFormItem
            control={control}
            name="supportEnable"
            label="Does this model need support"
            items={BOOLEAN_ITEMS}
            errors={errors.supportEnable}
            defaultValue={NO}
            component={RadioCheckBox}
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row justify-content-center mb-5">
        <div className="col col-3">
          <Button type="submit">Upload</Button>
        </div>
      </div>
    </form>
  );
};

export default TemporaryPartForm;
