import React, { useEffect, useMemo, useState } from 'react';
import { Alert, Button, Card } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { FaDollarSign } from 'react-icons/fa';
import Add from 'assets/images/icons/add.svg';
import ControlledFormItem from 'components/ControlledFormItem';
import DropzoneComponent from 'components/Dropzone';
import FileInput from 'components/FileInput';
import Input from 'components/Input';
import RadioCheckBox from 'components/RadioCheckBox';
import SelectQuery from 'components/SelectQuery';
import TextArea from 'components/TextArea';
import {
  BOOLEAN_ITEMS,
  COMPANY_FEES_PERCENTAGE,
  MAX_DIMENSIONS_VALUE,
  NO,
  USER_FEES_PERCENTAGE,
  YES,
} from 'constants/global';
import { ORIENTATION } from 'constants/job';
import {
  ADD_PART,
  AVAILABILITY_ITEMS,
  AVAILABILITY_OPTIONS,
  EDIT_PART,
  MIN_SCALE_SIZE,
  SCALE_SIZE_STEP,
} from 'constants/part';
import { MAX_FILE_SIZE_NON_SUBSCRIBERS, photoTypes } from 'constants/uploads';
import { getCategoriesQuery } from 'requests/category';
import { getIncomeSum } from 'utils/global';
import { getGCodesDefaultValues } from 'utils/part';
import {
  fileSize,
  fileType,
  maxLength,
  maxValue,
  minLength,
  minValue,
  validateEditPartPhoto,
  validateUploadPartPhoto,
} from 'utils/validators';
import GCodeForm from './GCodeForm';

const minLengthValidation = minLength(3);
const maxLengthValidation = maxLength(50);

const minDimensionsValueValidation = minValue(0.01);
const maxDimensionsValueValidation = maxValue(MAX_DIMENSIONS_VALUE);

const modelFileTypeValidation = fileType(['stl', 'obj']);
const modelFileSizeValidation = fileSize(MAX_FILE_SIZE_NON_SUBSCRIBERS);

const PartForm = ({
  onSubmit,
  isRestricted,
  data,
  user,
  uploading,
  type,
  mainPhotoType,
  setMainPhotoType,
  clearPhotoType,
  setClearedPhotoType,
}) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    watch,
    reset,
    clearErrors,
  } = useForm({
    defaultValues: {
      gCodes: getGCodesDefaultValues(data?.gcodes) ?? [],
    },
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'gCodes',
  });

  const submitHandler = async (values) => {
    const result = await onSubmit(values);
    if (result) {
      reset();
      clearErrors();
    }
  };

  const [file, gCodes] = watch(['file', 'gCodes']);

  const [pricePerPrint, setPricePerPrint] = useState();

  const [transferPrice, setTransferPrice] = useState();

  const [requiredPhoto, setRequiredPhoto] = useState();

  const defaultPricePerPrint = Number(data?.pricePerPrint);
  const defaultTransferPrice = Number(data?.transferPrice);
  const defaultAvailability = () => {
    let availabilityValue;
    if (data?.availability === AVAILABILITY_OPTIONS.PUBLIC) availabilityValue = AVAILABILITY_OPTIONS.PUBLIC;
    if (data?.availability === AVAILABILITY_OPTIONS.PRIVATE) availabilityValue = AVAILABILITY_OPTIONS.PRIVATE;

    return availabilityValue;
  };

  useEffect(() => {
    if (defaultPricePerPrint) setPricePerPrint(defaultPricePerPrint);
    if (defaultTransferPrice) setTransferPrice(defaultTransferPrice);
  }, [defaultPricePerPrint, defaultTransferPrice]);

  const formFields = [
    'name',
    'catagoryId',
    'dimensionsHeight',
    'dimensionsLength',
    'dimensionsWidth',
    'description',
    'patentNumber',
    'pricePerPrint',
    'availability',
    'transferPrice',
    'supportEnable',
  ];

  const getDefaultValues = (field) => {
    if (field === 'catagoryId') return data?.catagory?.id;
    if (field === 'supportEnable') return data?.supportEnable ? YES : NO;
    else return data[field];
  };

  const setDefaultValues = () => formFields.map((field) => setValue(`${field}`, getDefaultValues(field)));

  const orientationPhoto = data?.partPics?.find(({ type }) => type === ORIENTATION);

  useEffect(() => {
    if (orientationPhoto?.url && type === EDIT_PART) setRequiredPhoto(orientationPhoto.url);
  }, [orientationPhoto?.url, type]);

  useEffect(() => {
    if (data && type === EDIT_PART) setDefaultValues();
  }, [data, type]);

  const validateUploadPhoto = (index) => {
    if (type === EDIT_PART) return validateEditPartPhoto(index, requiredPhoto);
    else return validateUploadPartPhoto(index);
  };

  const controlledGCodeFields = fields?.map((field, index) => ({
    ...field,
    ...gCodes[index],
  }));

  const gCodeFormInitialValues = useMemo(
    () => ({
      gCodeFile: null,
      model: null,
      extruderSize: '',
      materialType: '',
      printQuality: '',
      infillPercent: '',
      infillPattern: '',
      adhesionType: '',
      supportEnable: null,
      retractionEnable: null,
      retractionDistance: '',
      width: '',
      height: '',
      length: '',
      printingTime: {
        hours: '',
        minutes: '',
        seconds: '',
      },
    }),
    []
  );

  const getUploadFileMessage = () => (
    <>
      {type === EDIT_PART && (
        <div className="col-md-6 text-danger font-weight-bold">Note: Previously uploaded file would be used</div>
      )}
    </>
  );

  return (
    <form onSubmit={handleSubmit(submitHandler)}>
      {type === EDIT_PART && <div className="h3 my-4 font-weight-bold">Edit part</div>}
      {isRestricted && (
        <div className="m-4 text-center text-danger font-weight-bold">
          You can not upload/update models, because of Stripe account is not connected.
          <br />
          You can do it in the <a href={`/user/${user?.id}`}>My Profile</a> section.
        </div>
      )}
      <div className="row">
        <div className="col-md-6 mb-3">
          <ControlledFormItem
            control={control}
            name="name"
            label="Name this part"
            errors={errors.name}
            disabled={isRestricted}
            component={Input}
            hasClear
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
      </div>
      <div className="row align-items-center d-flex">
        <div className="col-md-6 mb-3">
          <ControlledFormItem
            control={control}
            name="file"
            label="Pick Part File"
            labelInfo="Limit 50 MB per file. Coming soon 500 MB for subscribers"
            disabled={isRestricted}
            errors={errors.file}
            component={FileInput}
            validation
            rules={{
              required: type === ADD_PART ? true : false,
              validate: { modelFileTypeValidation, modelFileSizeValidation },
            }}
          />
        </div>
        <div className="col-md-6 mb-3 font-weight-bold">
          <Alert variant={'primary'}>
            Ensure your design file is saved in STL format and follow our model landing and rotation guidelines before
            uploading it to 3DTelePrint. For a step-by-step guide,{' '}
            <Alert.Link
              href="https://www.youtube.com/watch?v=fZP-ZPU-GtY"
              variant={'primary'}
              className="h4 font-weight-bold"
            >
              watch our video
            </Alert.Link>
          </Alert>
        </div>
        {!file && getUploadFileMessage()}
      </div>
      <div className="row pt-3 pb-3">
        {photoTypes.map((item, index) => (
          <div key={item.label} className="col-xl-4 col-sm-6 mb-3">
            <ControlledFormItem
              control={control}
              name={item.type}
              itemName={item.label}
              type={item.type}
              disabled={isRestricted}
              component={DropzoneComponent}
              errors={errors[item.type]}
              rules={validateUploadPhoto(index)}
              mainPhotoType={mainPhotoType}
              setMainPhotoType={setMainPhotoType}
              clearPhotoType={clearPhotoType}
              images={data?.partPics}
              setClearedPhotoType={setClearedPhotoType}
              setRequiredPhoto={setRequiredPhoto}
            />
          </div>
        ))}
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <ControlledFormItem
            control={control}
            name="catagoryId"
            label="Categories"
            disabled={isRestricted}
            placeholder="Select Category"
            errors={errors.catagoryId}
            component={SelectQuery}
            labelField="name"
            valueField="id"
            query={getCategoriesQuery}
            dataPath="threeDContCatagories"
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 mb-3 d-flex">
          <div className="w-100 mr-5">
            <ControlledFormItem
              control={control}
              name="dimensionsLength"
              label="Length - X(mm)"
              labelInfo={`Max Length ${MAX_DIMENSIONS_VALUE}mm`}
              disabled={isRestricted}
              errors={errors.dimensionsLength}
              type="number"
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              component={Input}
              hasClear
              validation
              rules={{
                required: true,
                validate: { minDimensionsValueValidation, maxDimensionsValueValidation },
              }}
            />
          </div>
          <div className="w-100 mr-5">
            <ControlledFormItem
              control={control}
              name="dimensionsWidth"
              label="Width - Y(mm)"
              labelInfo={`Max Width ${MAX_DIMENSIONS_VALUE}mm`}
              disabled={isRestricted}
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              errors={errors.dimensionsWidth}
              type="number"
              component={Input}
              hasClear
              validation
              rules={{
                required: true,
                validate: { minDimensionsValueValidation, maxDimensionsValueValidation },
              }}
            />
          </div>
          <div className="w-100">
            <ControlledFormItem
              control={control}
              name="dimensionsHeight"
              label="Height - Z(mm)"
              labelInfo={`Max Height ${MAX_DIMENSIONS_VALUE}mm`}
              disabled={isRestricted}
              errors={errors.dimensionsHeight}
              type="number"
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              component={Input}
              hasClear
              validation
              rules={{
                required: true,
                validate: { minDimensionsValueValidation, maxDimensionsValueValidation },
              }}
            />
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <ControlledFormItem
            control={control}
            label="Description"
            name="description"
            disabled={isRestricted}
            errors={errors.description}
            component={TextArea}
            validation
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-md-6 mb-3">
          <ControlledFormItem
            control={control}
            name="patentNumber"
            label="Patent number"
            disabled={isRestricted}
            hasClear
            errors={errors.patent}
            component={Input}
          />
        </div>
      </div>
      <div className="row align-items-center mb-3">
        <div className="col-md-6">
          <ControlledFormItem
            control={control}
            type="number"
            hasClear
            name="pricePerPrint"
            icon={<FaDollarSign />}
            label="Price"
            disabled={isRestricted}
            step="0.01"
            min="0.01"
            validation
            rules={{
              required: true,
              validate: maxValue(1000),
            }}
            errors={errors.pricePerPrint}
            component={Input}
            onChange={(value) => setPricePerPrint(value)}
          />
        </div>
        {pricePerPrint > 0 && (
          <div className="col-md-6">Your income: {getIncomeSum(pricePerPrint, USER_FEES_PERCENTAGE)} $</div>
        )}
      </div>
      <div className="row align-items-center mb-3">
        <div className="col-md-6">
          <ControlledFormItem
            control={control}
            type="number"
            hasClear
            name="transferPrice"
            icon={<FaDollarSign />}
            label="Transfer ownership (leave blank if not for sale)"
            disabled={isRestricted}
            step="0.01"
            min="0.01"
            validation
            rules={{ validate: maxValue(1000000) }}
            errors={errors.transferPrice}
            component={Input}
            onChange={(value) => setTransferPrice(value)}
          />
        </div>
        {transferPrice > 0 && (
          <div className="col-md-6">Your income: {getIncomeSum(transferPrice, COMPANY_FEES_PERCENTAGE)} $</div>
        )}
      </div>
      <div className="row">
        <div className="col-12 mb-3">
          <ControlledFormItem
            control={control}
            name="availability"
            label="Availability"
            disabled={isRestricted || data?.availability === AVAILABILITY_OPTIONS.FORCED_PRIVATE}
            disabledOption={user?.availablePrivateParts < 1 && AVAILABILITY_OPTIONS.PRIVATE}
            items={AVAILABILITY_ITEMS}
            errors={errors.availability}
            defaultValue={defaultAvailability()}
            component={RadioCheckBox}
            rules={{
              required: true,
            }}
          />
          {data?.availability === AVAILABILITY_OPTIONS.FORCED_PRIVATE && (
            <p className="text-danger">The part is forced as private by Administration</p>
          )}
          {user?.availablePrivateParts < 1 && (
            <p className="text-danger">
              You&#39;ve reached the private parts limit. Please, create a public part or change existing private parts
              to be public.
            </p>
          )}
        </div>
        <div className="col-12 mb-3">
          <ControlledFormItem
            control={control}
            name="supportEnable"
            label="Does this model need support"
            disabled={isRestricted}
            items={BOOLEAN_ITEMS}
            errors={errors.supportEnable}
            defaultValue={data?.supportEnable ? YES : NO}
            component={RadioCheckBox}
            rules={{
              required: true,
            }}
          />
        </div>
      </div>
      {controlledGCodeFields.map(({ id, gCodeFile, ...gCode }, index) => (
        <div key={id} className="mb-5">
          <div className="d-flex align-items-end justify-content-between">
            <div className="h6">G-Code file</div>
            <Button className="btn-danger" onClick={() => remove(index)}>
              Delete g-code
            </Button>
          </div>
          <Card className="my-2">
            <div className="d-flex align-items-center">
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name={`gCodes.${index}.gCodeFile`}
                  errors={errors?.gCodes?.[index]?.gCodeFile}
                  component={FileInput}
                  validation
                  rules={{ validate: fileType(['gcode']) }}
                />
              </div>
              {data?.gcodes?.[index]?.id && getUploadFileMessage()}
            </div>
            {(gCodeFile?.file || data?.gcodes?.[index]?.id) && (
              <GCodeForm
                control={control}
                errors={errors?.gCodes?.[index]}
                gCode={gCode}
                nameSpace={`gCodes.${index}`}
              />
            )}
          </Card>
        </div>
      ))}
      <div className="d-flex align-items-center cursor-pointer mb-3" onClick={() => append([gCodeFormInitialValues])}>
        <img src={Add} height={40} alt="add" />
        <div className="font-weight-bold">Add G-code</div>
      </div>

      <div className="row justify-content-center mb-5">
        <div className="col col-3">
          <Button disabled={uploading || isRestricted} type="submit">
            {type === EDIT_PART ? 'Update' : 'Send'}
          </Button>
        </div>
      </div>
    </form>
  );
};

export default PartForm;
