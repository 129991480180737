import React from 'react';

const AuthPageWrapper = ({ title, children }) => (
  <div className="internal-pages wingman-login w-100">
    <div className="container">
      <div className="row align-items-center">
        <div className="col-md-5 col-lg-5 m-auto py-4 py-md-5">
          <div className="bg-white rounded-12 shadow-60 py-4 py-md-5 px-xl-5 px-3">
            <h1 className="mb-md-3 h3 font-weight-bold mb-0 text-center">{title}</h1>
            {children}
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default AuthPageWrapper;
