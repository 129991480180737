import React from 'react';
import { useQuery } from '@apollo/client';
import { Col, Row } from 'react-bootstrap';
import { useParams, Link } from 'react-router-dom';
import Error from 'components/Error';
import Loading from 'components/Loading';
import { getOperatorByIdQuery } from 'requests/operators';
import WingmansGrid from './WingmanGrid';

const OperatorView = () => {
  const { id } = useParams();

  const { data, loading, error } = useQuery(getOperatorByIdQuery, {
    variables: { id },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="container-fluid">
      {loading ? (
        <Loading />
      ) : (
        <>
          <div className="d-flex align-items-center h5 my-4">
            <Link to="/operators" className="mr-2 text-primary">
              Wingman Operators
            </Link>
            <span>{`> ${data?.operatorById?.userName || `Operator №${id}`}`}</span>
          </div>

          <div className="h3 my-4 font-weight-bold">
            3D printers by {data?.operatorById?.userName || `Operator №${id}`}
          </div>

          <Row className="m-3">
            <Col lg={12} sm={12}>
              {error ? <Error error={error} /> : <WingmansGrid wingmans={data.operatorById.wingmen} />}
            </Col>
          </Row>
        </>
      )}
    </div>
  );
};

export default OperatorView;
