import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => (
  <footer className="container-fluid">
    <div className="row">
      <div className="col-md-6 text-left">
        Copyright &copy; {new Date().getFullYear()} WingMan Inc. All Rights Reserved.
      </div>
      <div className="col-md-6 text-right">
        <ul className="footer-links">
          <li>
            <Link to="/about">About</Link>
          </li>
          <li>
            <Link to="/contact-us">Contact Us</Link>
          </li>
          <li>
            <Link to="/terms">Terms of Use</Link>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
