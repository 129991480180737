import React from 'react';
import { Spinner } from 'react-bootstrap';

const Loading = ({ small }) => (
  <div className="my-auto w-100 h-100 text-center d-flex align-items-center justify-content-center">
    <Spinner className={small && `spiner-small`} animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
  </div>
);

export default Loading;
