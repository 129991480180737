import React, { useMemo, useState, useEffect, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';
import { differenceInMinutes } from 'date-fns';
import dateFnsFormat from 'date-fns/format';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Alert, Button, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Loader from 'components/Loader';
import CostForm from 'components/PartDetails/CostForm';
import PhotoSlider from 'components/PhotoSlider';
import TextArea from 'components/TextArea';
import { LOCAL_PICKUP, PART_SIZE_OPTIONS } from 'constants/part';
import { DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import { formatCurrency, formatDuration } from 'lib/helpers';
import {
  asyncUpdateJobCostMutation,
  createJobMutation,
  createCartItemMutation,
  updateJobSubscription,
} from 'requests/jobs';
import { sendMessageMutation } from 'requests/messageRequst';
import { partTransferMutation } from 'requests/partRequest';
import {
  getFromLocalStorage,
  handleErrors,
  removeFromLocalStorage,
  setToLocalStorage,
  valueOrDefault,
  joinAddress,
} from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';
import Accordion from './Accordion';
import AvailableGCodes from './AvailableGCodes';
import styles from './index.module.scss';
import RequestGCode from './RequestGCode';

const MINUTES_DIFFERENCE = 30;

const DATE_FORMAT = 'MM-dd-yyyy hh:mm:ss a';
const ADD_TO_CART_BUTTON_TITLE = 'Add to cart';

const getNewDate = (date) => new Date(date);

const CustomToastWithLink = () => (
  <div className=" my-4">
    <span>Part was successfully added to the cart.</span>
    <Link to="/my-cart" className=" mr-2 text-primary">
      View the cart
    </Link>
  </div>
);

const PartDetails = ({ part }) => {
  const client = useApolloClient();
  const { handleSubmit } = useForm();
  const history = useHistory();
  const { user } = useContext(AuthContext);

  const {
    control: messageControl,
    formState: { errors: messageErrors },
    handleSubmit: submitMessage,
    watch: messageWatch,
    setValue: setMessageValue,
  } = useForm();

  const messageValue = messageWatch('message');

  const [jobData, setJobData] = useState();
  const [wingman, setWingman] = useState();
  const [isCalculating, setCalculating] = useState(false);
  const [showCostMessage, setShowCostMessage] = useState(false);
  const [updatedJobData, setUpdatedJobData] = useState();
  const [costValues, setCostValues] = useState();
  const [storageData, setStorageData] = useState();
  const [dateNow, setDateNow] = useState();

  useEffect(() => {
    const partLocalStorageKey = `part-${part?.id}`;
    const costLocalStorageKey = `cost-part${part?.id}`;

    const partData = getFromLocalStorage(partLocalStorageKey);
    const costData = getFromLocalStorage(costLocalStorageKey);

    /* clear invalid out-of-date local storage data */
    if (partData && typeof partData?.wingman === 'string') {
      removeFromLocalStorage(partLocalStorageKey);
      removeFromLocalStorage(costLocalStorageKey);
      return;
    }

    partData && setStorageData(partData);
    costData && setUpdatedJobData(costData);
  }, [part?.id]);

  useEffect(() => {
    if (storageData && !updatedJobData) {
      const currentDate = dateFnsFormat(new Date(), DATE_FORMAT);

      setDateNow(currentDate);
    }
  }, [storageData, updatedJobData]);

  useEffect(() => {
    costValues && setToLocalStorage(`part-${part?.id}`, { ...costValues, isCalculating });
    isCalculating && setTimeout(() => setShowCostMessage(true), 10000);
  }, [costValues, isCalculating, part?.id, updatedJobData]);

  useEffect(() => {
    storageData && setCalculating(storageData.isCalculating);
  }, [storageData, storageData?.isCalculating]);

  useEffect(() => {
    if (dateNow) {
      const timeDifference = differenceInMinutes(getNewDate(dateNow), getNewDate(storageData?.partCreatedDate));

      if (timeDifference === MINUTES_DIFFERENCE || !storageData?.partCreatedDate) {
        setStorageData(null);
        setCalculating(false);
        removeFromLocalStorage(`part-${part.id}`);
      }
    }
  }, [dateNow, part.id, storageData?.partCreatedDate]);

  useEffect(() => {
    if (updatedJobData) {
      setCalculating(false);
      setShowCostMessage(false);
    }
  }, [updatedJobData]);

  const isTransferOwnershipAvailable =
    !!part?.transferPrice &&
    part?.userMedia?.threeDContUser?.id !== user?.id &&
    part?.userMedia?.threeDContUser?.stripeConnected;

  const messageHandler = async ({ message }) => {
    try {
      const data = await client.mutate({
        mutation: sendMessageMutation,
        variables: {
          message,
          partId: part.id,
        },
      });
      if (data.data.sendMessage === 'ok') {
        toast.success('Message Sent');
      }
      setMessageValue('message', '');
    } catch (e) {
      handleErrors(e, "The message wasn't sent");
    }
  };

  const submitAddToCart = async () => {
    try {
      const { data } = await client.mutate({
        mutation: createCartItemMutation,
        variables: { jobId: jobData?.id || updatedJobData?.id, amount: 1 },
      });

      if (data.createCartItem.cartItems.length) {
        removeFromLocalStorage(`part-${part?.id}`);
        removeFromLocalStorage(`cost-part${part?.id}`);

        toast.success(CustomToastWithLink);
        history.push(`/product-list`);
      }
    } catch (error) {
      handleErrors(error);
    }
  };

  const submitTransfer = async () => {
    try {
      const {
        data: { partTransfer: redirectUrl },
      } = await client.mutate({
        mutation: partTransferMutation,
        variables: {
          partId: part.id,
        },
      });

      redirectUrl && window.location.assign(redirectUrl);
    } catch (error) {
      handleErrors(error);
    }
  };

  const updateJob = (jobId) => {
    let subscription;

    if (jobId) {
      subscription = client
        .subscribe({
          query: updateJobSubscription,
          variables: { jobId },
        })
        .subscribe({
          next({ data }) {
            const updatedData = data?.jobUpdateSubscription;

            setUpdatedJobData(updatedData);
            setToLocalStorage(`cost-part${part?.id}`, updatedData);
          },
        });
    } else {
      subscription?.unsubscribe();
    }
  };

  const designerCostData = useMemo(
    () => [
      {
        id: '1',
        name: 'Designer cost',
        value: (jobData?.designerPrice || updatedJobData?.designerPrice) ?? 0,
        description: '(per print)',
        total: true,
      },
    ],
    [jobData?.designerPrice, updatedJobData?.designerPrice]
  );

  const operatorCostData = useMemo(
    () => [
      {
        id: '1',
        name: 'Part print time',
        value: (jobData?.estimatedPrintTime || updatedJobData?.estimatedPrintTime) ?? 0,
        description: '(part print time)',
      },
      { id: '2', name: 'Cost per hour', value: wingman?.printer?.costPerHour, description: '(per hour) ' },
      {
        id: '3',
        name: 'Cost per print',
        value: (jobData?.operatorPrice || updatedJobData?.operatorPrice) ?? 0,
        description: '(part print time * cost per hour)',
      },
      {
        id: '4',
        name: 'Total operator cost',
        value: (jobData?.operatorPrice || updatedJobData?.operatorPrice) ?? 0,
        total: true,
      },
    ],
    [
      jobData?.estimatedPrintTime,
      jobData?.operatorPrice,
      updatedJobData?.estimatedPrintTime,
      updatedJobData?.operatorPrice,
      wingman?.printer?.costPerHour,
    ]
  );

  const viewCostSubmit = async ({ color, percent, type, wingman, size, ...values }) => {
    setCalculating(true);

    const availableMaterials = wingman?.printer?.availableMaterials;

    const checkCalculatingValuesAreReady = (data) => {
      if (data) return !!data?.estimatedPrintTime && !!data?.designerPrice && !!data?.operatorPrice;
    };

    const selectedMaterial = availableMaterials?.find((singleMaterial) => {
      if (singleMaterial?.type === type && singleMaterial?.color === color) {
        return singleMaterial;
      }
    });

    const partDetailValues = {
      printerMaterialId: Number(selectedMaterial?.id),
      infillSparseDensity: Number(percent),
      infillPattern: values?.pattern,
      ...values,
    };

    const costFormValues = {
      partId: part?.id,
      wingman,
      percent: Number(percent),
      partCreatedDate: dateFnsFormat(new Date(), DATE_FORMAT),
      color,
      type,
      size: size || PART_SIZE_OPTIONS.ORIGINAL,
      ...values,
    };

    try {
      if (!jobData?.id) {
        const { data: jobQueryResult } = await client.mutate({
          mutation: createJobMutation,
          variables: {
            deliveryMethod: LOCAL_PICKUP,
            threeDContUserId: user?.id,
            wingManId: wingman?.id,
            partId: part?.id,
            printerId: wingman?.printer?.id,
            ...partDetailValues,
          },
        });

        const jobQueryData = jobQueryResult?.createJob;

        const jobId = jobQueryData?.id;

        setJobData(jobQueryData);
        setCostValues(costFormValues);
        removeFromLocalStorage(`cost-part${part?.id}`);
        if (checkCalculatingValuesAreReady(jobQueryData)) {
          setCalculating(false);
          setUpdatedJobData(jobQueryData);
        } else {
          updateJob(jobId);
        }
      } else {
        const jobId = jobData?.id;
        const { data: asyncUpdateJobResult } = await client.mutate({
          mutation: asyncUpdateJobCostMutation,
          variables: { jobId, ...partDetailValues },
        });

        const jobQueryData = asyncUpdateJobResult?.asyncUpdateJobCost;
        if (checkCalculatingValuesAreReady(jobQueryData)) {
          setCalculating(false);
          setUpdatedJobData(jobQueryData);
        } else {
          updateJob(jobId);
        }
      }
    } catch (error) {
      handleErrors(error);
      setCalculating(false);
    }
  };

  const renderCostBlock = (type, data) => {
    const totalFieldValue = data.find((item) => item.total)?.value;

    const zeroCostText = () => {
      if (type === 'Designer') return 'You are the owner of the part';
      if (type === 'Operator') return 'You are the owner of the selected wingman';
    };

    return (
      <>
        <label>{`${type} expenses`}</label>
        <table className="table">
          <tbody className="font-weight-normal">
            {isString(totalFieldValue) && Number(totalFieldValue) === 0 ? (
              <tr>
                <td>{zeroCostText()}</td>
              </tr>
            ) : (
              <>
                {data.map(({ id, name, value, description }) => (
                  <tr key={id}>
                    <td className={styles.costNameColumn}>
                      <div className="ml-4">{name}</div>
                    </td>
                    <td className={styles.costValueColumn}>
                      <div className="pl-4">
                        {name === 'Part print time' ? formatDuration(value) : formatCurrency(value)}
                      </div>
                    </td>
                    <td>
                      <div className="pl-4 font-size-14 font-weight-light">{description}</div>
                    </td>
                  </tr>
                ))}
              </>
            )}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <>
      <div className="row">
        <div className="col-md-5 offset-md-1">
          <PhotoSlider photoUrls={part?.partPics} />
          <Accordion title="Description">
            <div>{part?.description}</div>
            <div className="mt-2">
              <span className="font-weight-bold mr-1">Requires support:</span>
              {part?.supportEnable ? 'Yes' : 'No'}
            </div>
            {isTransferOwnershipAvailable && (
              <div className="mt-2">
                <span className="font-weight-bold mr-1">Transfer ownership price:</span>
                {`${part?.transferPrice} $`}
              </div>
            )}
          </Accordion>

          {isTransferOwnershipAvailable && (
            <div className="my-3 d-flex justify-content-center">
              <Button size="lg" onClick={submitTransfer} className="w-50">
                Transfer ownership
              </Button>
            </div>
          )}

          <div className="mt-4 pt-4 d-flex flex-column justify-content-center">
            <ControlledFormItem
              control={messageControl}
              label="Message"
              name="message"
              value={messageValue}
              errors={messageErrors.message}
              component={TextArea}
              rules={{
                required: true,
              }}
            />
            <Button onClick={submitMessage(messageHandler)} className="m-auto">
              Send Message
            </Button>
          </div>

          {part?.gcodes?.length > 0 && (
            <>
              <div className="h6 mt-5 mb-2">Available G-codes</div>
              {part.gcodes.map(({ id, ...gCode }) => (
                <AvailableGCodes key={id} gCode={gCode} />
              ))}
              <RequestGCode partId={part?.id} />
            </>
          )}
        </div>
        <div className="col-md-5">
          <div className="h4 my-4">{part?.name}</div>
          <div className="h6 ">
            <span className="font-weight-bold">Owner: </span>
            {valueOrDefault(part?.userMedia?.threeDContUser?.userName)}
          </div>
          <div className="h6 ">
            <span className="font-weight-bold">Category: </span>
            {part.catagory.name}
          </div>
          <div className="h6">
            <span className="font-weight-bold bor">Part Id: </span>
            {part.id}
          </div>
          <div className="h6 border-top border-bottom py-4">
            {`${formatCurrency(part.pricePerPrint)} per print (designer expenses only)`}
          </div>
          {wingman && wingman?.deliveryPolicy !== DELIVERY_POLICY_SETTINGS.SHIP && (
            <div className="h6 border-bottom py-4">
              Pick-up location: {wingman?.shippingAddress ? joinAddress(wingman?.shippingAddress) : '-'}
            </div>
          )}
          <CostForm
            onSubmit={viewCostSubmit}
            part={part}
            setWingman={setWingman}
            setJobData={setJobData}
            isCalculating={isCalculating}
            storageData={storageData}
          />
          {(jobData || updatedJobData || storageData) && (
            <>
              {!isCalculating ? (
                <div>
                  {updatedJobData?.id && (
                    <div className="h6">
                      <div className="h6 d-flex justify-content-center mt-3">
                        <Alert variant={'primary'}>
                          <span>
                            <span className="font-weight-bold">Note: </span>The estimated cost does not include any
                            applicable taxes, processing and delivery fees.
                          </span>
                        </Alert>
                      </div>
                      {(updatedJobData?.fromGcode || storageData?.fromGcode) && (
                        <h5 className="mb-4 text-danger">Note: you&apos;re using the GCode file to print this part</h5>
                      )}
                      {renderCostBlock('Designer', designerCostData)}
                      {renderCostBlock('Operator', operatorCostData)}
                      <div className="mt-3">
                        Total Print cost
                        <span className="pl-4 font-weight-normal">
                          {formatCurrency(
                            Number(updatedJobData?.operatorPrice ?? 0) + Number(updatedJobData?.designerPrice ?? 0)
                          )}
                        </span>
                      </div>
                      <div className="h6 d-flex justify-content-center mt-3">
                        <Alert variant={'primary'}>
                          <span>
                            <span className="font-weight-bold">Note: </span>If you do not pick up the part within 7 days
                            from the printing date, the order will be automatically marked as paid. Consequently, the
                            order will not be eligible for a refund.
                          </span>
                        </Alert>
                      </div>
                    </div>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-center flex-column align-items-center w-100">
                  {showCostMessage && (
                    <div className="mb-4  alert alert-warning" role="alert">
                      We are calculating the price for this job. It could take up to 5 min. You can leave this page, and
                      we will notify you about the result.
                    </div>
                  )}
                  <Loader type="primary" />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <div className="d-flex justify-content-center p-4">
        {!updatedJobData?.id || isCalculating ? (
          <OverlayTrigger
            placement="top"
            overlay={<Tooltip id="tooltip-disabled">Please click View print cost first</Tooltip>}
          >
            <Button size="lg" className={cn('w-25 disabled btn-secondary not-allowed', styles.disabledBtn)}>
              {ADD_TO_CART_BUTTON_TITLE}
            </Button>
          </OverlayTrigger>
        ) : (
          <Button size="lg" className="w-25" onClick={handleSubmit(submitAddToCart)}>
            {ADD_TO_CART_BUTTON_TITLE}
          </Button>
        )}
      </div>
    </>
  );
};

PartDetails.propTypes = {
  part: PropTypes.object.isRequired,
};

export default PartDetails;
