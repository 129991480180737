import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import { Badge, Col, Row, Alert } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import SearchInput from 'components/custom-inputs/SearchInput';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PartList from 'components/PartList';
import useDebounce from 'hooks/useDebounce';
import useSearchParams from 'hooks/useSearchParams';
import { getPartsQuery } from 'requests/partRequest';
import { getPaginationParams } from 'utils/global';

const ProductList = () => {
  const [categoryInput, setCategoryInput] = useState();

  const { id } = useParams();

  const { page = 1, pageSize = 9, searchValue = '' } = useSearchParams();

  const debounceValue = useDebounce(searchValue);

  const { loading, error, data } = useQuery(getPartsQuery, {
    variables: {
      page: +page,
      pageSize: +pageSize,
      filter: { ...(categoryInput && { categoryIds: categoryInput }) },
      ...(debounceValue && { searchValue: debounceValue }),
    },
    fetchPolicy: 'cache-and-network',
  });

  return (
    <div className="container-fluid">
      <Row>
        <Col lg={2} sm={12}>
          <div className='d-flex align-items-center h3 my-4 font-weight-bold'>
            <span className="d-block">Products</span>
              {data?.threeDContParts?.totalEntries > 0 && (
                <Badge className="ml-4 border" variant="light">
                  {data?.threeDContParts?.totalEntries}
                </Badge>
              )}
          </div>
        </Col>
        <Col lg={10} sm={12}>
          <div className="h5 font-weight-bold d-flex justify-content-center pt-4">
            <Alert variant={'primary'}>
              Didn{'\''}t find what you were looking for? You can request {' '}
              <Alert.Link href="/one-time-print"  variant={'primary'} className="h4 font-weight-bold">
                One-Time Print
              </Alert.Link> 
              {' '} if you have an STL model.
            </Alert>
          </div>
        </Col>
      </Row>
      <Row className="p-3">
        <Col lg={2} sm={12}>
          <div className="search py-3 d-flex flex-column justify-content-stretch">
            <SearchInput
              title="Search"
              searchValue={searchValue}
              catagory={categoryInput}
              catagoryChange={setCategoryInput}
              isPart={true}
              hasClear
              id={id}
            />
          </div>
        </Col>
        <Col lg={10} sm={12}>
          {error ? (
            <Error error={error} />
          ) : loading ? (
            <Loading />
          ) : (
            <PartList parts={data?.threeDContParts?.entries} pagination={getPaginationParams(data?.threeDContParts)} />
          )}
        </Col>
      </Row>
    </div>
  );
};

export default ProductList;
