import React, { useState, useContext } from 'react';
import { useApolloClient } from '@apollo/client';
import cn from 'classnames';
import { format } from 'date-fns';
import { Alert, Accordion, Card, Button, Row } from 'react-bootstrap';
import { FaCheck } from 'react-icons/fa';
import { toast } from 'react-toastify';
import LiveImageBlock from 'components/LiveImageBlock';
import { JOB_SCOPES, ORIENTATION, COMPLETED_JOB_STATUSES } from 'constants/job';

import { INFILL_PATTERNS, PRINT_QUALITY_OPTIONS } from 'constants/wingman';
import { formatDuration } from 'lib/helpers';
import {
  cancelJobMutation,
  pauseJobMutation,
  resumeJobMutation,
  startJobMutation,
  deleteJobMutation,
  completeJobMutation,
} from 'requests/jobs';
import { addTimezoneOffset } from 'utils/dateFormat';
import { valueOrDefault, yesOrNo, handleErrors } from 'utils/global';
import { convertToFahrenheitTemp, getPrintSettingLabel } from 'utils/job';
import { AuthContext } from 'сontexts/AuthContext';
import styles from './index.module.scss';

const NameValueItem = ({ name, value }) => (
  <>
    {value && (
      <div>
        <span className="col-3 font-weight-bold">{name} :</span>
        <span className="col-9">{value}</span>
      </div>
    )}
  </>
);

const JobItem = ({ jobItem, inProgress, refetch }) => {
  const [answer, setAnswer] = useState(false);
  const { user } = useContext(AuthContext);

  const client = useApolloClient();

  const extruderTemperature = convertToFahrenheitTemp(jobItem?.extruderTemperature);
  const bedTemperature = convertToFahrenheitTemp(jobItem?.bedTemperature);
  const defaultMaterialBedTemperature = convertToFahrenheitTemp(jobItem?.slicerSetting?.defaultMaterialBedTemperature);
  const defaultMaterialPrintTemperature = convertToFahrenheitTemp(
    jobItem?.slicerSetting?.defaultMaterialPrintTemperature
  );
  const materialBedTemperatureLayerZero = convertToFahrenheitTemp(
    jobItem?.slicerSetting?.materialBedTemperatureLayerZero
  );
  const materialPrintTemperatureLayerZero = convertToFahrenheitTemp(
    jobItem?.slicerSetting?.materialPrintTemperatureLayerZero
  );

  const infillPattern = getPrintSettingLabel(INFILL_PATTERNS, jobItem?.slicerSetting?.infillPattern);

  const printerQuality = getPrintSettingLabel(PRINT_QUALITY_OPTIONS, jobItem?.slicerSetting?.printQuality);

  const realtimeData = [
    {
      id: '1',
      name: 'Percent complete',
      value: `${jobItem?.completion} %`,
    },
    {
      id: '2',
      name: 'Estimated print time',
      value: valueOrDefault(jobItem?.estimatedPrintTime, formatDuration(jobItem?.estimatedPrintTime)),
    },
    {
      id: '3',
      name: 'Extruder Temperature',
      value: `${valueOrDefault(jobItem?.extruderTemperature)}℃ (${valueOrDefault(extruderTemperature)}℉)`,
    },
    {
      id: '4',
      name: 'Build plate Temperature',
      value: `${valueOrDefault(jobItem?.bedTemperature)}℃ (${valueOrDefault(bedTemperature)}℉)`,
    },
    {
      id: '5',
      name: 'Start datetime',
      value: valueOrDefault(
        jobItem.startUpTime,
        jobItem.startUpTime ? format(addTimezoneOffset(new Date(jobItem?.startUpTime)), 'MM-dd-yyyy hh:mm:ss a') : ''
      ),
    },
  ];

  const slicerSettings = [
    {
      id: '1',
      name: 'Print quality',
      value: valueOrDefault(printerQuality),
    },
    {
      id: '2',
      name: 'Plastic type',
      value: valueOrDefault(jobItem?.slicerSetting?.plasticType),
    },
    {
      id: '3',
      name: 'Build plate temp initial layer',
      value: `${valueOrDefault(jobItem?.slicerSetting?.defaultMaterialBedTemperature)}℃ (${valueOrDefault(
        defaultMaterialBedTemperature
      )}℉)`,
    },
    {
      id: '4',
      name: 'Default Material Print Temperature',
      value: `${valueOrDefault(jobItem?.slicerSetting?.defaultMaterialPrintTemperature)}℃ (${valueOrDefault(
        defaultMaterialPrintTemperature
      )}℉)`,
    },
    {
      id: '5',
      name: 'Infill Pattern',
      value: valueOrDefault(infillPattern),
    },
    {
      id: '6',
      name: 'Infill Sparse Density',
      value: `${valueOrDefault(jobItem?.slicerSetting?.infillSparseDensity)} %`,
    },
    {
      id: '7',
      name: 'Build plate temp initial layer',
      value: `${valueOrDefault(jobItem?.slicerSetting?.materialBedTemperatureLayerZero)}℃ (${valueOrDefault(
        materialBedTemperatureLayerZero
      )}℉)`,
    },

    {
      id: '8',
      name: 'Material Print Temperature Layer Zero',
      value: `${valueOrDefault(jobItem?.slicerSetting?.materialPrintTemperatureLayerZero)}℃ (${valueOrDefault(
        materialPrintTemperatureLayerZero
      )}℉)`,
    },
    {
      id: '9',
      name: 'Print speed',
      value: valueOrDefault(jobItem?.slicerSetting?.speedPrint),
    },
    {
      id: '10',
      name: 'Top bottom speed',
      value: valueOrDefault(jobItem?.slicerSetting?.speedTopbottom),
    },
    {
      id: '11',
      name: 'Initial layer speed',
      value: valueOrDefault(jobItem?.slicerSetting?.speedLayerZero),
    },
    {
      id: '12',
      name: 'Travel speed',
      value: valueOrDefault(jobItem?.slicerSetting?.speedTravel),
    },
    {
      id: '13',
      name: 'Enable retraction',
      value: yesOrNo(jobItem?.slicerSetting?.retractionEnable),
    },
    {
      id: '14',
      name: 'Retraction distance',
      value: valueOrDefault(jobItem?.slicerSetting?.retractionAmount),
    },
    {
      id: '15',
      name: 'Avoid printed parts when travelling',
      value: yesOrNo(jobItem?.slicerSetting?.travelAvoidOtherParts),
    },
    {
      id: '16',
      name: 'Support infill percent',
      value: valueOrDefault(jobItem?.slicerSetting?.supportInfillRate),
    },
    {
      id: '17',
      name: 'Build plate adhesion type',
      value: valueOrDefault(jobItem?.slicerSetting?.adhesionType),
    },
    {
      id: '18',
      name: 'Flow',
      value: valueOrDefault(jobItem?.slicerSetting?.materialFlow),
    },
  ];

  const jobInfo = [
    { id: '1', name: 'Part ID', value: jobItem?.part?.id },
    {
      id: '2',
      name: 'Size',
      value: `L (X):${jobItem.dimensionsLength}mm; W (Y):${jobItem.dimensionsWidth}mm; H (Z):${jobItem.dimensionsHeight}mm;`,
    },
    { id: '3', name: 'Material length', value: valueOrDefault(jobItem?.materialLength) },
    { id: '4', name: 'Material type', value: valueOrDefault(jobItem?.material?.type) },
    { id: '5', name: 'Color', value: valueOrDefault(jobItem?.material?.color) },
    { id: '6', name: 'Print request by', value: jobItem.threeDContUser.userName },
    { id: '7', name: 'Printer make model', value: `${jobItem.printer.model.name}` },
    { id: '8', name: 'Wingman machine name', value: `${jobItem?.wingMan?.machineName}` },
  ].filter((item) => !(jobItem.temporaryPart && (item.name === 'Part ID' || item.name === 'Size')));

  const onDeleteClick = async () => {
    try {
      await client.mutate({
        mutation: deleteJobMutation,
        variables: { jobId: jobItem.id },
      });
      toast.success('The job is successfully deleted');
      refetch();
    } catch (e) {
      handleErrors(e);
    }
  };

  const startJob = () => {
    try {
      client.mutate({
        mutation: startJobMutation,
        variables: {
          jobId: jobItem.id,
        },
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const cancelJob = () => {
    try {
      client.mutate({
        mutation: cancelJobMutation,
        variables: {
          jobId: jobItem.id,
        },
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const pauseJob = () => {
    try {
      client.mutate({
        mutation: pauseJobMutation,
        variables: {
          jobId: jobItem.id,
        },
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const resumeJob = () => {
    try {
      client.mutate({
        mutation: resumeJobMutation,
        variables: {
          jobId: jobItem.id,
        },
      });
    } catch (error) {
      handleErrors(error);
    }
  };

  const completeJob = async () => {
    try {
      await client.mutate({
        mutation: completeJobMutation,
        variables: { jobId: jobItem?.id },
      });
      setAnswer(true);
    } catch (error) {
      handleErrors(error);
    }
  };

  const isOperator = jobItem?.scope === JOB_SCOPES.OPERATOR;

  const isJobCompleted = COMPLETED_JOB_STATUSES.includes(jobItem.status);

  const defaultPrintJobImg = jobItem.part?.partPics?.find(({ type }) => type === ORIENTATION);

  const isPrintSuccessful =
    jobItem.status === 'IN_PROGRESS' ||
    jobItem.status === 'PAUSING' ||
    jobItem.status === 'PAUSED' ||
    jobItem.status === 'RESUMING' ||
    jobItem.status === 'CANCELING' ||
    jobItem.status === 'CANCELED' ||
    jobItem.status === 'STARTING';

  return (
    <Accordion>
      <Card key={jobItem.id} className={cn(jobItem.status === 'COMPLETED' ? styles.completedCard : styles.border)}>
        <Card.Body as={Row}>
          <div className={cn('col-3')}>
            {jobItem.part?.partPics ? (
              <Accordion.Toggle
                className={cn(styles.jobPic, 'cursor-pointer d-flex justify-content-center align-items-center')}
                as="div"
                eventKey={jobItem.id + 'jobItem'}
              >
                <img
                  src={defaultPrintJobImg?.url || jobItem.part?.partPics?.[0]?.url}
                  alt={`jobPick-${jobItem.id}`}
                  className="rounded"
                />
              </Accordion.Toggle>
            ) : (
              <div className="d-flex align-items-center justify-content-center">
                <h3>one-time print</h3>
              </div>
            )}
          </div>
          <div className="col-7">
            <div className="d-flex align-items-center">
              <h4 className="my-3">{`${jobItem.part?.name ?? 'one-time print'} ${jobItem.id}`}</h4>
              {jobItem.status && (
                <div className="px-2 font-weight-bold">
                  (Status: {<span className="text-uppercase">{jobItem.status}</span>})
                </div>
              )}
              {jobItem.fromGcode && <div className="px-2 font-weight-bold text-danger">Printing from GCode</div>}
            </div>
            <NameValueItem
              name="Started at"
              value={valueOrDefault(
                jobItem.startUpTime,
                format(addTimezoneOffset(new Date(jobItem.startUpTime)), 'MM-dd-yyyy hh:mm:ss a')
              )}
            />
            <NameValueItem
              name="Uploaded by"
              value={jobItem.part ? jobItem.part.userMedia.threeDContUser.userName : ''}
            />
            <NameValueItem name="Estimated print time" value={formatDuration(jobItem.estimatedPrintTime)} />
          </div>
          <div className="col-2 d-flex flex-column justify-content-around">
            <Accordion.Toggle variant="info" size="sm" as={Button} eventKey={jobItem.id + 'jobItem'}>
              View
            </Accordion.Toggle>

            {isOperator && !isJobCompleted && (
              <Button variant="success" size="sm" onClick={startJob}>
                Print
              </Button>
            )}

            {jobItem?.canBeDeleted && (
              <Button variant="danger" size="sm" onClick={onDeleteClick}>
                Delete Print Job
              </Button>
            )}
          </div>
        </Card.Body>
        <Accordion.Collapse eventKey={jobItem.id + 'jobItem'}>
          <Card.Body>
            <div className="rounded-lg bg-light">
              {!!jobItem?.progressPics?.length && <LiveImageBlock height={400} images={jobItem.progressPics} />}
              <Row>
                <div className="col-4">
                  {user.id === jobItem.threeDContUser.id && (
                    <div className="h6 d-flex justify-content-center p-3">
                      <Alert variant={'primary'}>
                        <span>
                          <span className="font-weight-bold">Note: </span>If you do not pick up the part within 7 days
                          from the printing date, the order will be automatically marked as paid. Consequently, the
                          order will not be eligible for a refund.
                        </span>
                      </Alert>
                    </div>
                  )}

                  <div className="h6 font-weight-bold text-center">Realtime Data</div>

                  <div className="py-3">
                    {realtimeData.map(({ name, value, id }) => (
                      <div key={id} className="p-1">
                        <span className="col-3 font-weight-bold">{name} :</span>
                        <span className="col-9">{value}</span>
                      </div>
                    ))}
                  </div>
                  {isOperator && (
                    <>
                      {inProgress ? (
                        <div className="pb-3 d-flex justify-content-around">
                          <Button variant="warning" onClick={cancelJob}>
                            Cancel
                          </Button>
                          <Button variant="success" onClick={pauseJob}>
                            Pause
                          </Button>
                          <Button variant="primary" onClick={resumeJob}>
                            Resume
                          </Button>
                        </div>
                      ) : (
                        <div className="pb-3 d-flex justify-content-around">
                          <Button variant="primary">Repeat</Button>
                        </div>
                      )}
                      {isPrintSuccessful && (
                        <Alert show variant="info" className="m-3">
                          <Alert.Heading>Was the print successful?</Alert.Heading>
                          <div className="d-flex justify-content-end">
                            <Button variant="success" onClick={completeJob}>
                              Yes
                            </Button>
                            <Button className="ml-5" variant="danger" onClick={cancelJob}>
                              No
                            </Button>
                          </div>
                        </Alert>
                      )}
                      {answer && (
                        <Alert className="text-center" variant="success">
                          Response received <FaCheck />
                        </Alert>
                      )}
                    </>
                  )}
                </div>
                <div className="col-4">
                  <div className="h6 font-weight-bold mt-3 text-center">Slicer Settings</div>
                  <table className="table mt-4">
                    <tbody className="font-weight-normal">
                      {slicerSettings.map(({ name, value, id }) => (
                        <tr key={id}>
                          <td className="font-weight-bold">
                            <div className="ml-4">{name}</div>
                          </td>
                          <td>
                            <div className="pl-4">{value}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="col-4">
                  <div className="h6 font-weight-bold mt-3 text-center">Job Info</div>
                  <div className="py-3">
                    {jobInfo.map(({ name, value, id }) => (
                      <div key={id} className="p-1">
                        <span className="col-3 font-weight-bold">{name} :</span>
                        <span className="col-9">{value}</span>
                      </div>
                    ))}
                  </div>
                </div>
              </Row>
            </div>
          </Card.Body>
        </Accordion.Collapse>
      </Card>
    </Accordion>
  );
};

export default JobItem;
