import React, { useCallback, useEffect, useState } from 'react';
import cn from 'classnames';
import { Button } from 'react-bootstrap';
import { useDropzone } from 'react-dropzone';
import Add from 'assets/images/icons/add.svg';
import styles from './index.module.scss';

const DropzoneComponent = ({
  disabled,
  itemName,
  onChange,
  errors,
  type,
  mainPhotoType,
  setMainPhotoType,
  clearPhotoType,
  images,
  setClearedPhotoType,
  setRequiredPhoto,
  value,
}) => {
  const [file, setFile] = useState();
  const [defaultImage, setDefaultImage] = useState();

  useEffect(() => {
    const foundImage = images?.find((image) => image.type === type);
    foundImage && setDefaultImage(foundImage);
  }, [images, type]);

  useEffect(() => {
    if (file) setRequiredPhoto(file.src);
  }, [file]);

  useEffect(() => {
    if (!value && file) clear(type);
  }, [value]);

  const clear = (type) => {
    setFile(null);
    onChange(null);
    clearPhotoType();
    setDefaultImage();
    setRequiredPhoto();
    setClearedPhotoType && setClearedPhotoType((prev) => [...prev, type]);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      const f = acceptedFiles[0];
      const url = window.URL.createObjectURL(f);
      const reader = new FileReader();
      reader.onload = (() => {
        return (e) => {
          const binaryData = e.target.result;
          const base64String = window.btoa(binaryData);
          onChange({ fileData: base64String, type: type });
          setClearedPhotoType && setClearedPhotoType((prev) => prev && prev.filter((picType) => picType !== type));
          setFile({
            src: url,
          });
        };
      })(f);
      reader.readAsBinaryString(f);
    },
    [onChange, setClearedPhotoType, type]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    multiple: false,
    accept: 'image/jpeg, image/png,image/jpg',
  });

  const isDefault = mainPhotoType === type;

  return (
    <div className={cn('p-1', errors && styles.errors)}>
      <div
        {...getRootProps({
          onClick: (event) => disabled && event.stopPropagation(),
        })}
      >
        <div className={cn(styles.uploadBox, disabled && styles.disabled)}>
          {defaultImage || file ? (
            <>
              <img className={styles.img} src={defaultImage?.url || file.src} alt={`${itemName}-alt`} />
            </>
          ) : (
            <>
              <input {...getInputProps()} />
              <img height={80} src={Add} alt="add" />
              <h5>Add photo</h5>
            </>
          )}
        </div>
      </div>
      <div className={styles.remove}>
        <div className="d-flex justify-content-between mt-2">
          <div className="font-weight-bold">
            <div>{itemName}</div>
            {isDefault && <div className={cn(styles.default, 'text-primary')}>(Default)</div>}
          </div>
          {(defaultImage || file) && (
            <div className="d-flex align-items-center">
              {!isDefault && (
                <div className={cn(styles.actions, 'mr-2')}>
                  <Button variant="primary" size="sm" onClick={() => setMainPhotoType(type)}>
                    Set default
                  </Button>
                </div>
              )}
              <div className={styles.actions}>
                <Button variant="danger" size="sm" onClick={() => clear(type)}>
                  Clear
                </Button>
              </div>
            </div>
          )}
        </div>
        <div className={cn(styles.errorsText, !errors && 'opacity-0')}>{`${errors?.message}`}</div>
      </div>
    </div>
  );
};

export default DropzoneComponent;
