import React from 'react';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';

export const Arrow = ({ next, ...props }) => {
  return (
    <div className={`d-block slickArrow ${next ? 'nextArrow' : 'prevArrow'}`} onClick={props.onClick}>
      {next ? (
        <FaAngleRight color={!props.onClick ? 'gray' : undefined} />
      ) : (
        <FaAngleLeft color={!props.currentSlide ? 'gray' : undefined} />
      )}
    </div>
  );
};
