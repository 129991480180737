import React, { useRef, useState } from 'react';

const MapContext = React.createContext({
  isMapMounted: false,
  setMapMounted: () => null,
  mapsRef: { current: undefined },
});

const MapProvider = (props) => {
  const [isMapMounted, setMapMounted] = useState(false);
  const mapsRef = useRef();

  return <MapContext.Provider value={{ isMapMounted, setMapMounted, mapsRef }}>{props.children}</MapContext.Provider>;
};

export { MapContext, MapProvider };
