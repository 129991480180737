import React from 'react';
import ControlledFormItem from 'components/ControlledFormItem/index';
import Select from 'components/Select/index';
import {
  DEFAULT_PRINT_SETTINGS,
  INFILL_PERCENTS,
  INFILL_PATTERNS,
  ADHESION_TYPES,
  MATERIAL_PRINT_TEMPERATURE,
  PLATE_TEMPERATURE,
  FLOW_ITEMS,
  BOOLEAN_ITEMS,
  RETRACTION_DISTANCE_ITEMS,
} from 'constants/wingman';

const SettingsForm = ({ type, control, defaultValue, printQualityItems, defaultDisabled }) => (
  <form>
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      name={`printQuality${type}`}
      label="Print quality"
      placeholder="Print quality"
      component={Select}
      defaultValue={defaultValue?.printQuality || DEFAULT_PRINT_SETTINGS[type].printQuality}
      items={printQualityItems}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      name={`infillSparseDensity${type}`}
      label="Infill percent %"
      placeholder="Infill percent %"
      defaultValue={defaultValue?.infillSparseDensity || DEFAULT_PRINT_SETTINGS[type].infillSparseDensity}
      component={Select}
      items={INFILL_PERCENTS}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      label="Infill pattern"
      name={`infillPattern${type}`}
      placeholder="Infill pattern"
      component={Select}
      defaultValue={defaultValue?.infillPattern || DEFAULT_PRINT_SETTINGS[type].infillPattern}
      items={INFILL_PATTERNS}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      labelClassName="font-weight-500"
      formClassName="pb-2"
      label={`Printing temperature (${type})`}
      control={control}
      name={`defaultMaterialPrintTemperature${type}`}
      placeholder={`Printing temperature (${type})`}
      defaultValue={
        defaultValue?.defaultMaterialPrintTemperature || DEFAULT_PRINT_SETTINGS[type].defaultMaterialPrintTemperature
      }
      component={Select}
      items={MATERIAL_PRINT_TEMPERATURE[type]}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      labelClassName="font-weight-500"
      formClassName="pb-2"
      label={`Printing temp initial layer (${type})`}
      control={control}
      name={`materialPrintTemperatureLayerZero${type}`}
      placeholder={`Printing temperature (${type})`}
      defaultValue={
        defaultValue?.materialPrintTemperatureLayerZero ||
        DEFAULT_PRINT_SETTINGS[type].materialPrintTemperatureLayerZero
      }
      component={Select}
      items={MATERIAL_PRINT_TEMPERATURE[type]}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      labelClassName="font-weight-500"
      formClassName="pb-2"
      label={`Build plate temperature (${type})`}
      control={control}
      name={`defaultMaterialBedTemperature${type}`}
      placeholder={`Build plate temperature (${type})`}
      defaultValue={
        defaultValue?.defaultMaterialBedTemperature || DEFAULT_PRINT_SETTINGS[type].defaultMaterialBedTemperature
      }
      component={Select}
      items={PLATE_TEMPERATURE[type]}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      labelClassName="font-weight-500"
      formClassName="pb-2"
      label={`Build plate temp initial layer (${type})`}
      control={control}
      name={`materialBedTemperatureLayerZero${type}`}
      placeholder={`Build plate temp initial layer (${type})`}
      defaultValue={
        defaultValue?.materialBedTemperatureLayerZero || DEFAULT_PRINT_SETTINGS[type].materialBedTemperatureLayerZero
      }
      component={Select}
      items={PLATE_TEMPERATURE[type]}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      label="Enable retraction"
      name={`retractionEnable${type}`}
      placeholder="No"
      component={Select}
      defaultValue={defaultValue?.retractionEnable || DEFAULT_PRINT_SETTINGS[type].retractionEnable}
      items={BOOLEAN_ITEMS}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      label="Retraction distance"
      name={`retractionAmount${type}`}
      placeholder="Retraction distance"
      component={Select}
      defaultValue={defaultValue?.retractionAmount || DEFAULT_PRINT_SETTINGS[type].retractionAmount}
      items={RETRACTION_DISTANCE_ITEMS}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      label="Build plate adhesion type"
      name={`adhesionType${type}`}
      placeholder="Build plate adhesion type"
      component={Select}
      defaultValue={defaultValue?.adhesionType || DEFAULT_PRINT_SETTINGS[type].adhesionType}
      items={ADHESION_TYPES}
      disabled={defaultDisabled}
    />
    <ControlledFormItem
      formClassName="pb-2"
      labelClassName="font-weight-500"
      control={control}
      name={`materialFlow${type}`}
      label="Flow"
      placeholder="Flow"
      component={Select}
      defaultValue={defaultValue?.materialFlow || DEFAULT_PRINT_SETTINGS[type].materialFlow}
      items={FLOW_ITEMS}
      disabled={defaultDisabled}
    />
  </form>
);

export default SettingsForm;
