import React, { useContext } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from 'сontexts/AuthContext';

const RequireLogout = (props) => {
  const { user } = useContext(AuthContext);

  if (user) return <Redirect to="/product-list" />;
  return props.children;
};

export default RequireLogout;
