import React, { useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { withApollo } from '@apollo/client/react/hoc';
import { Button } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { toast } from 'react-toastify';
import { LANDING_LINK } from 'constants/global';
import { signOutMutation } from 'requests/auth';
import { AuthContext } from 'сontexts/AuthContext';

const Signout = () => {
  const [loading, setLoading] = useState(false);
  const { user, setUser, setUserLogOuted } = useContext(AuthContext);
  const client = useApolloClient();

  const singOut = async () => {
    setLoading(true);
    try {
      await client.mutate({
        mutation: signOutMutation,
        variables: { id: user.id },
      });
      window.location.replace(LANDING_LINK);
    } catch (error) {
      setLoading(false);
    } finally {
      setLoading(false);
      setUserLogOuted(true);
      localStorage.removeItem('auth-token');
      setUser(null);
      toast.success('Signed out', { autoClose: 2000 });
    }
  };

  return (
    <Button variant="outline-danger" className="m-2" onClick={singOut} disabled={loading}>
      Sign Out
    </Button>
  );
};

export default withRouter(withApollo(Signout));
