import React, { useEffect, useRef, Children, isValidElement, cloneElement, useState } from 'react';
import { isLatLngLiteral } from '@googlemaps/typescript-guards';
import classNames from 'classnames';
import { createCustomEqual, deepEqual } from 'fast-equals';
import { FaSearch } from 'react-icons/fa';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import Loader from 'components/Loader';
import { MAP_DEFAULT_CENTER } from 'constants/map';
import { formatAddress } from 'utils/map';
import styles from './index.module.scss';
import useGoogleMapsApi from './useGoogleMapsApi';

const DEFAULT_MAP_STYLE = { flexGrow: '1', height: '100%' };

const deepCompareEqualsForMaps = createCustomEqual(() => ({
  areObjectsEqual: (a, b) => {
    if (
      isLatLngLiteral(a) ||
      a instanceof window.google.maps.LatLng ||
      isLatLngLiteral(b) ||
      b instanceof window.google.maps.LatLng
    ) {
      return new window.google.maps.LatLng(a).equals(new window.google.maps.LatLng(b));
    }

    // use fast-equals for other objects
    return deepEqual(a, b);
  },
}));

function useDeepCompareMemoize(value) {
  const ref = useRef();

  if (!deepCompareEqualsForMaps(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
}

function useDeepCompareEffectForMaps(callback, dependencies) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
}

const Map = ({
  children,
  className,
  onMapMount,
  onClick,
  style,
  withSpiderfier = false,
  withSearchBox = false,
  handleSearch,
  mapTypeControl = false,
  streetViewControl = false,
  center = MAP_DEFAULT_CENTER,
  zoom = 4,
  ...options
}) => {
  const googleMapRef = useRef(null);
  const inputRef = useRef(null);
  const searchBox = useRef(null);

  const [map, setMap] = useState();
  const [spiderfier, setSpiderfier] = useState(null);

  const { isLoaded, error } = useGoogleMapsApi();

  useEffect(() => {
    if (googleMapRef.current && !map) {
      const googleMap = new window.google.maps.Map(googleMapRef.current, {
        center,
        zoom,
        mapTypeControl,
        streetViewControl,
        ...options,
        styles: [
          {
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }],
          },
        ],
      });
      setMap(googleMap);
      onMapMount && onMapMount(googleMap);
    }
  }, [map, onMapMount, options, mapTypeControl, streetViewControl, center, zoom]);

  useEffect(() => {
    if (withSpiderfier && map && !spiderfier) {
      setSpiderfier(
        new OverlappingMarkerSpiderfier(map, {
          markersWontHide: true,
          nearbyDistance: 2,
        })
      );
    }
  }, [map, spiderfier, withSpiderfier]);

  useEffect(() => {
    if (map) {
      window.google.maps.event.clearListeners(map, 'click');
      if (onClick) {
        map.addListener('click', onClick);
      }
    }
  }, [map, onClick]);

  useEffect(() => {
    const onSearch = () => {
      const places = searchBox.current?.getPlaces();
      const address = formatAddress(places?.[0]?.address_components);
      const latLng = places?.[0]?.geometry?.location;
      const lat = latLng?.lat();
      const lng = latLng?.lng();
      handleSearch && handleSearch(address, { lat, lng });
      if (lat && lng) {
        map?.setCenter({ lat, lng });
      }
    };
    if (map && withSearchBox && inputRef.current && !searchBox.current) {
      const search = new window.google.maps.places.SearchBox(inputRef.current);
      search.addListener('places_changed', onSearch);
      searchBox.current = search;
    }
  }, [handleSearch, withSearchBox, map]);

  useDeepCompareEffectForMaps(() => {
    if (map) {
      map.setOptions({
        center,
        zoom,
        ...options,
      });
    }
  }, [map, center, zoom, options]);

  const handleKeyDown = (e) => e.key === 'Enter' && !e.shiftKey && e.preventDefault();

  if (!isLoaded) {
    return <Loader />;
  }

  if (error) {
    return <p>{error.message}</p>;
  }

  return (
    <div className={classNames(styles.container, className)}>
      <div ref={googleMapRef} style={style || DEFAULT_MAP_STYLE} />
      {Children.map(children, (child) => {
        if (isValidElement(child)) {
          return cloneElement(child, { map, spiderfier });
        }
      })}
      {withSearchBox && (
        <div className={classNames(styles.searchBox)}>
          <input
            icon={<FaSearch />}
            ref={inputRef}
            onKeyDown={handleKeyDown}
            placeholder="Type address..."
            autoComplete="off"
          />
        </div>
      )}
    </div>
  );
};

export default Map;
