import { formatBytes } from 'utils/global';

export const REQUIRED_FIELD = 'Required field';

export const getError = (error) => {
  if (error) {
    if (error.type === 'required') {
      error = { ...error, message: 'Required' };
    }
    return error;
  }
};

export const maxLength = (max) => (maxValue) => maxValue.length > max ? `Length cant be more than ${max}` : undefined;

export const minLength = (min) => (minValue) =>
  minValue.length < min ? `Length can't be less than ${min} symbols` : undefined;

export const fileType = (validTypes) => (typeValue) => {
  if (typeValue) {
    const nameArr = typeValue.file.name.split('.');
    const nameLength = nameArr.length - 1;
    if (
      validTypes.every((type) => {
        return type !== nameArr[nameLength];
      })
    ) {
      return `File type not valid, support ${validTypes.join(', ')}`;
    }
  }
};

export const fileSize = (maxSize) => (file) => {
  if (file && maxSize < file.file?.size) return `File size can't be more than ${formatBytes(maxSize)}`;
};

export const maxValue = (validMaxValue) => (maxVal) =>
  maxVal > validMaxValue ? `Value can't be more than ${validMaxValue}` : undefined;

export const minValue = (validMinValue) => (minValue) =>
  minValue < validMinValue ? `Value can't be less than ${validMinValue}` : undefined;

export const validatePhotoType = (value) => {
  if (!value) return 'The photo is required';
};

export const emailValidation = (email) =>
  !email.match(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  )
    ? 'Email not correct'
    : undefined;

export const validateUploadPartPhoto = (index) => {
  if (index === 0) return { validate: validatePhotoType };
  else return undefined;
};

export const validateEditPartPhoto = (index, requiredPhoto) => {
  if (index === 0) return { validate: !requiredPhoto && validatePhotoType };
  else return undefined;
};

export const validateLat = (value) => {
  if (value === '' || value === undefined || value === null) return REQUIRED_FIELD;
  if (value && !(isFinite(+value) && Math.abs(+value) <= 85)) return 'Latitude is not valid';
};

export const validateLng = (value) => {
  if (value === '' || value === undefined || value === null) return REQUIRED_FIELD;
  if (value && !(isFinite(+value) && Math.abs(+value) <= 180)) return 'Longitude is not valid';
};

export const isValidPosition = (position) => !validateLat(position.lat) && !validateLng(position.lng);

export const isUS = (value) => {
  if (value !== 'United States') return 'non-valid Country';
};

export const isZipValid = (value) => {
  if (value.length !== 5) return 'non-valid ZIP';
};
