import { gql } from '@apollo/client';

export const stripeConnectQuery = gql`
  query stripeConnectRequest {
    stripeConnectRequest
  }
`;

export const stripeConnectConfirmMutation = gql`
  mutation stripeConnectConfirm($code: String) {
    stripeConnectConfirm(code: $code)
  }
`;
