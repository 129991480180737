import React, { useEffect, useState } from 'react';
import { stringify } from 'query-string';
import PaginationComponent from 'react-bootstrap/Pagination';
import { useHistory, useLocation } from 'react-router-dom';
import { parseQueryString } from 'utils/global';

const { Prev, Next, Item } = PaginationComponent;

const Pagination = ({ pagination = { pageNumber: 1, pageSize: 100, totalEntries: 10, totalPages: 10 } }) => {
  const allPages = Array.from({ length: pagination.totalPages }, (_, idx) => idx + 1);

  const [currentPage, setCurrentPage] = useState(1);

  const history = useHistory();

  const location = useLocation();

  const changeSearchParams = (page) => {
    let queryString = '';
    if (location.search) {
      const parsedQueryString = parseQueryString(location.search);

      parsedQueryString.page = page.toString();

      if (pagination.pageSize) parsedQueryString.pageSize = pagination.pageSize.toString();
      queryString = `?${stringify(parsedQueryString)}`;
    } else {
      queryString = `?page=${page}`;
      if (pagination.pageSize) queryString += `&pageSize=${pagination.pageSize}`;
    }
    history.push({ ...location, search: queryString });
  };

  const handlePageChange = (page) => {
    changeSearchParams(page);
    setCurrentPage(page);
  };

  useEffect(() => {
    if (location.search) {
      const parsedQueryString = parseQueryString(location.search);

      parsedQueryString?.page && setCurrentPage(Number(parsedQueryString.page));
    }
  }, [location.search]);

  return (
    <PaginationComponent>
      <Prev disabled={currentPage === 1} onClick={() => handlePageChange(currentPage - 1)} />
      {allPages.map((page) => (
        <Item active={currentPage === page} key={page} onClick={() => handlePageChange(page)}>
          {page}
        </Item>
      ))}
      <Next disabled={currentPage === pagination.totalPages} onClick={() => handlePageChange(currentPage + 1)} />
    </PaginationComponent>
  );
};

export default Pagination;
