import React, { useCallback, useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import PropTypes from 'prop-types';
import Select from 'components/Select';

const SelectQuery = ({ query, dataPath, labelField, valueField, isSearchable, defaultValue, ...props }) => {
  const client = useApolloClient();
  const [options, setOptions] = useState();
  const [dataState, setDataState] = useState();

  const loadData = useCallback(async () => {
    if (query) {
      try {
        const { data } = await client.query({
          query,
          fetchPolicy: 'network-only',
        });

        setDataState(data[dataPath]);
      } catch (error) {
        console.error(error);
      }
    }
  }, [client, dataPath, query]);

  const getOptions = useCallback(
    (data) => {
      return data?.map((item) => ({
        label: item[labelField],
        value: valueField ? item[valueField] : item,
      }));
    },
    [labelField, valueField]
  );

  useEffect(() => {
    if (dataState) {
      const selectOptions = getOptions(dataState);
      setOptions(selectOptions);
    }
  }, [dataState, getOptions]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  return <Select {...props} items={options} isSearchable={isSearchable} defaultValue={defaultValue} />;
};

SelectQuery.propTypes = {
  query: PropTypes.any.isRequired,
};

export default SelectQuery;
