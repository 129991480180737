import React, { useEffect, useState, useCallback } from 'react';
import { Button } from 'react-bootstrap';
import { useDebouncedCallback } from 'use-debounce';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import RadioCheckBox from 'components/RadioCheckBox';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import SelectSearch from 'components/SelectSearch';
import WingmanMap from 'components/WingmanMap';
import { PER_HOUR_FEES_PARCENTAGE } from 'constants/global';
import { EXTRUDER_SIZES, DELIVERY_POLICY_ITEMS, STATES_ITEMS, DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import useDebounce from 'hooks/useDebounce';
import useMap from 'hooks/useMap';
import { getPrinterModelQuery } from 'requests/wingmen';
import { getIncomeSum, getUserPosition, joinAddress } from 'utils/global';
import { getPosition } from 'utils/map';
import { minValue, maxValue, isUS, isZipValid } from 'utils/validators';

const minValueValidation = minValue(0);
const maxValueValidation = maxValue(20);

const WingmanForm = ({ onSubmit, control, errors, selectedWingman, userPositionInit, setValue, watch }) => {
  const { getAddressFromLatLng, mapsRef } = useMap();

  const costPerHour = selectedWingman?.printer?.costPerHour;
  const nozzleSize = selectedWingman?.printer?.nozzleSize;
  const currentMaterial = selectedWingman?.printer?.currentMaterial;

  const model = selectedWingman?.printer?.model;
  const availableMaterials = selectedWingman?.printer?.availableMaterials;
  const defaultMaterial = availableMaterials?.find((singleMaterial) => singleMaterial.id === currentMaterial?.id)?.id;
  const latitude = selectedWingman?.latitude;
  const longitude = selectedWingman?.longitude;

  const [perHourPrice, setPerHourPrice] = useState(costPerHour);
  const [userPosition, setUserPosition] = useState(null);
  const [position, setPosition] = useState(selectedWingman ? { lat: latitude, lng: longitude } : null);

  const [firstAddressLine, city, country, stateProvince, postalCode] = watch([
    'firstAddressLine',
    'secondAddressLine',
    'city',
    'country',
    'stateProvince',
    'postalCode',
  ]);

  const debounceFirstAddressLine = useDebounce(firstAddressLine, 300);
  const debounceCity = useDebounce(city, 300);
  const debounceCountry = useDebounce(country, 300);
  const debounceStateProvince = useDebounce(stateProvince, 300);
  const debouncePostalCode = useDebounce(postalCode, 300);

  const geocodeAddress = useDebouncedCallback((lt, ln) => getAddressFromLatLng(lt, ln, setValue), 300);

  const center = getPosition(position, userPosition);

  const changePosition = useCallback(
    (newPosition) => {
      setPosition({ lat: newPosition.lat, lng: newPosition.lng });
      setValue('latitude', newPosition.lat);
      setValue('longitude', newPosition.lng);
    },
    [setValue]
  );

  const onMapClick = useCallback(
    (newPosition) => {
      changePosition({ lat: newPosition.lat, lng: newPosition.lng });
      geocodeAddress(newPosition.lat, newPosition.lng);
    },
    [changePosition, geocodeAddress]
  );

  useEffect(() => {
    if (latitude && longitude) {
      changePosition({ lat: latitude, lng: longitude });
    }
  }, [latitude, longitude, changePosition]);

  useEffect(() => {
    const address = joinAddress({
      firstAddressLine: debounceFirstAddressLine,
      city: debounceCity,
      country: debounceCountry,
      stateProvince: debounceStateProvince,
      postalCode: debouncePostalCode,
    });

    if (address) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK') {
          const { lat, lng } = results[0].geometry.location;
          const position = { lat: lat(), lng: lng() };
          changePosition(position);
          mapsRef.current?.map?.setCenter?.(position);
        }
      });
    }
  }, [
    changePosition,
    debounceCity,
    debounceCountry,
    debounceFirstAddressLine,
    debouncePostalCode,
    debounceStateProvince,
    mapsRef,
  ]);

  useEffect(() => {
    if (userPositionInit) getUserPosition(setUserPosition);
  }, [userPositionInit]);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
    }
  };

  return (
    <form className="px-2" onSubmit={onSubmit}>
      <ControlledFormItem
        control={control}
        name="model"
        label="Printer model"
        errors={errors?.model}
        component={SelectQuery}
        query={getPrinterModelQuery}
        labelField="name"
        defaultValueField="name"
        dataPath="printerModels"
        isSearchable
        defaultValue={model}
        hasClear
        validation
        rules={{
          required: true,
        }}
      />
      <ControlledFormItem
        control={control}
        name="costPerHour"
        label="Cost per hour"
        errors={errors?.costPerHour}
        type="number"
        defaultValue={Number(costPerHour) || 0}
        component={Input}
        hasClear
        validation
        rules={{
          required: true,
          validate: { maxValueValidation, minValueValidation },
        }}
        onChange={(value) => setPerHourPrice(value)}
      />
      {perHourPrice > 0 && (
        <div className="mb-5">Your income per hour {getIncomeSum(perHourPrice, PER_HOUR_FEES_PARCENTAGE)} $</div>
      )}
      <div className="mb-5">
        <ControlledFormItem
          control={control}
          label="Wingman S/N"
          value={selectedWingman?.serialNumber}
          disabled={true}
          component={Input}
        />
      </div>
      <ControlledFormItem
        control={control}
        name="deliveryPolicy"
        label="Shipping Settings"
        items={DELIVERY_POLICY_ITEMS}
        component={RadioCheckBox}
        defaultValue={selectedWingman?.deliveryPolicy ?? DELIVERY_POLICY_SETTINGS.PICKUP}
      />

      <div className="mb-5">
        <h6>Wingman Location</h6>
        <WingmanMap
          defaultCenter={userPosition ? center : undefined}
          markerLat={position.lat}
          markerLng={position.lng}
          onMarkerChange={onMapClick}
          minZoom={5}
          center={selectedWingman ? { lat: latitude, lng: longitude } : null}
        />
      </div>

      <ControlledFormItem
        control={control}
        name="country"
        label="Country"
        placeholder="Enter Country"
        errors={errors?.country}
        defaultValue={'United States'}
        component={Input}
        disabled
        validation
        onKeyDown={handleKeyDown}
        rules={{ required: true, validate: { isUS } }}
        onChange={(country) => {
          setValue('country', country);
        }}
      />
      <ControlledFormItem
        control={control}
        name="firstAddressLine"
        label="Address Line 1"
        placeholder="Enter Address Line 1"
        errors={errors?.firstAddressLine}
        defaultValue={selectedWingman?.shippingAddress?.firstAddressLine}
        component={Input}
        hasClear
        validation
        onKeyDown={handleKeyDown}
        rules={{ required: true }}
        onChange={(firstAddressLine) => {
          setValue('firstAddressLine', firstAddressLine);
        }}
      />
      <ControlledFormItem
        control={control}
        name="secondAddressLine"
        label="Address Line 2"
        placeholder="Enter Address Line 2"
        errors={errors?.secondAddressLine}
        defaultValue={selectedWingman?.shippingAddress?.secondAddressLine}
        component={Input}
        hasClear
        validation
        onKeyDown={handleKeyDown}
        onChange={(secondAddressLine) => {
          setValue('secondAddressLine', secondAddressLine);
        }}
      />
      <ControlledFormItem
        control={control}
        name="city"
        label="City"
        placeholder="Enter City"
        errors={errors?.city}
        defaultValue={selectedWingman?.shippingAddress?.city}
        component={Input}
        hasClear
        validation
        onKeyDown={handleKeyDown}
        rules={{ required: true }}
        onChange={(city) => {
          setValue('city', city);
        }}
      />
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="postalCode"
            label="ZIP"
            placeholder="Enter ZIP"
            errors={errors?.postalCode}
            defaultValue={selectedWingman?.shippingAddress?.postalCode}
            component={Input}
            hasClear
            validation
            className="w-100"
            onKeyDown={handleKeyDown}
            rules={{ required: true, validate: { isZipValid } }}
            onChange={(postalCode) => {
              setValue('postalCode', postalCode);
            }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="stateProvince"
            label="State/Province"
            placeholder="Enter State/Province"
            errors={errors?.stateProvince}
            defaultValue={selectedWingman?.shippingAddress?.stateProvince}
            component={SelectSearch}
            hasClear
            validation
            className="w-100"
            onKeyDown={handleKeyDown}
            rules={{ required: true }}
            items={STATES_ITEMS}
            isSearchable
            onChange={(stateProvince) => {
              setValue('stateProvince', stateProvince ?? '');
            }}
          />
        </div>
      </div>
      <ControlledFormItem
        control={control}
        name="nozzleSize"
        label="Nozzle size"
        placeholder="Select Extruder Size"
        errors={errors?.nozzleSize}
        defaultValue={Number(nozzleSize).toFixed(1)}
        component={Select}
        items={EXTRUDER_SIZES}
        validation
        rules={{ required: true }}
      />
      {selectedWingman?.printer && (
        <ControlledFormItem
          control={control}
          name="material"
          label="Current material"
          placeholder="Select material"
          errors={errors?.material}
          defaultValue={defaultMaterial}
          component={Select}
          items={availableMaterials?.map(({ color, type, id }) => ({ label: `${type} > ${color}`, value: id }))}
          validation
        />
      )}
      <div className="text-center mt-2 mb-3">
        <Button variant="success" type="submit">
          Update
        </Button>
      </div>
    </form>
  );
};

export default WingmanForm;
