export const PART_STATUSES = {
  UNPROCESSED: 'unprocessed',
  PROCESSED: 'processed',
  FAILED: 'failed',
};
export const EDIT_PART = 'edit';
export const ADD_PART = 'add';

export const LOCAL_PICKUP = 'LOCAL_PICKUP';
export const SHIPPING = 'SHIPPING';

export const DELIVERY_INFORMATION_ITEMS = [
  { label: 'Local Pickup', value: LOCAL_PICKUP },
  { label: 'Shipping address', value: SHIPPING },
];

export const ALL = 'All';

export const AVAILABILITY_OPTIONS = {
  PUBLIC: 'PUBLIC',
  PRIVATE: 'PRIVATE',
  FORCED_PRIVATE: 'FORCED_PRIVATE',
};

export const PART_SIZE_OPTIONS = {
  ORIGINAL: 'ORIGINAL',
  SCALE: 'SCALE',
  RESIZE: 'RESIZE',
};

export const AVAILABILITY_ITEMS = [
  { label: 'Public', value: AVAILABILITY_OPTIONS.PUBLIC },
  { label: 'Private', value: AVAILABILITY_OPTIONS.PRIVATE },
];

export const AVAILABILITY_ITEMS_FILTER = [
  { label: 'All', value: ALL },
  { label: 'Only Public', value: AVAILABILITY_OPTIONS.PUBLIC },
  { label: 'Only Private', value: AVAILABILITY_OPTIONS.PRIVATE },
];

export const PART_SIZE_ITEMS = [
  { label: 'Original', value: PART_SIZE_OPTIONS.ORIGINAL },
  { label: 'Scale', value: PART_SIZE_OPTIONS.SCALE },
  { label: 'Resize', value: PART_SIZE_OPTIONS.RESIZE },
];

export const SLICER_SETTING_DEFAULT_FIELDS = [
  { inputName: 'percent', fieldName: 'infillSparseDensity' },
  { inputName: 'pattern', fieldName: 'infillPattern' },
  { inputName: 'printQuality', fieldName: 'printQuality' },
];

export const MIN_SCALE_SIZE = '0.01';
export const SCALE_SIZE_STEP = '0.01';
