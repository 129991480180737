import React, { useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Modal as ModalBootstrap, ModalTitle, ModalBody, ModalFooter } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import Modal from 'components/ModalWrapper';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import { BOOLEAN_ITEMS, MAX_DIMENSIONS_VALUE, YES } from 'constants/global';
import { MIN_SCALE_SIZE, SCALE_SIZE_STEP } from 'constants/part';
import {
  ADHESION_TYPES,
  EXTRUDER_SIZES,
  INFILL_PATTERNS,
  INFILL_PERCENTS,
  MATERIAL_TYPE_ITEMS,
  RETRACTION_DISTANCE_ITEMS,
} from 'constants/wingman';
import usePrintQuality from 'hooks/usePrintQuality';
import { sendRequestGCodeMutation } from 'requests/partRequest';
import { getPrinterModelQuery } from 'requests/wingmen';
import { handleErrors } from 'utils/global';
import { minValue } from 'utils/validators';

const RequestGCode = ({ partId }) => {
  const client = useApolloClient();
  const [isModalVisible, setModalVisible] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    watch,
    reset,
    clearErrors,
  } = useForm();
  const [extruderSize] = watch(['extruderSize']);
  const printQualityItems = usePrintQuality(extruderSize);

  const resetFormValues = () => {
    reset();
    reset({ width: undefined, height: undefined, length: undefined });
  };

  const toggleModalVisibility = () => {
    resetFormValues();
    clearErrors();
    setModalVisible((prev) => !prev);
  };

  const handleGCodeRequest = async ({ supportEnabled, retractionEnabled, model, infillSparseDensity, ...values }) => {
    try {
      await client.mutate({
        mutation: sendRequestGCodeMutation,
        variables: {
          partId,
          modelId: model?.id,
          infillSparseDensity: Number(infillSparseDensity),
          supportEnabled: supportEnabled === YES,
          retractionEnabled: retractionEnabled === YES,
          ...values,
        },
      });

      toast.success('The request of G-Code is successfully sent', { autoClose: 3000 });
      toggleModalVisibility();
    } catch (error) {
      handleErrors(error, 'An error has occurred while sending the G-Code request');
    }
  };

  const minValueValidation = minValue(0.01);

  const renderModalForm = () => (
    <form>
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="model"
            errors={errors?.model}
            component={SelectQuery}
            query={getPrinterModelQuery}
            labelField="name"
            dataPath="printerModels"
            placeholder="Select Printer model"
            isSearchable
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="extruderSize"
            errors={errors?.extruderSize}
            component={Select}
            placeholder="Select Extruder Size"
            items={EXTRUDER_SIZES}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="materialType"
            errors={errors?.materialType}
            component={Select}
            placeholder="Select Material Type"
            items={MATERIAL_TYPE_ITEMS}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="printQuality"
            errors={errors?.printQuality}
            component={Select}
            placeholder="Select Printer Quality"
            items={printQualityItems}
            disabled={!extruderSize}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="infillSparseDensity"
            errors={errors?.infillSparseDensity}
            component={Select}
            items={INFILL_PERCENTS}
            placeholder="Select Infill percent %"
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="infillPattern"
            errors={errors?.infillPattern}
            component={Select}
            placeholder="Select Infill pattern"
            items={INFILL_PATTERNS}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="adhesionType"
            errors={errors?.adhesionType}
            component={Select}
            placeholder="Select Build plate adhesion type"
            items={ADHESION_TYPES}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
        <div className="col-6 d-flex align-items-baseline">
          <h6 className="mr-3">Retraction enabled</h6>
          <ControlledFormItem
            control={control}
            name="retractionEnabled"
            errors={errors?.retractionEnabled}
            component={Select}
            items={BOOLEAN_ITEMS}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="supportEnabled"
            errors={errors?.supportEnabled}
            label="Support enabled"
            component={Select}
            items={BOOLEAN_ITEMS}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
        <div className="col-6">
          <ControlledFormItem
            control={control}
            name="retractionDistance"
            label="Retraction Distance"
            errors={errors?.retractionDistance}
            component={Select}
            items={RETRACTION_DISTANCE_ITEMS}
            hasClear
            validation
            rules={{ required: true }}
          />
        </div>
      </div>
      <div className="row">
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name="length"
            errors={errors?.length}
            label="Length - X(mm)"
            labelInfo={`Max Length ${MAX_DIMENSIONS_VALUE}mm`}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name="width"
            label="Width - Y(mm)"
            labelInfo={`Max Width ${MAX_DIMENSIONS_VALUE}mm`}
            errors={errors?.width}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
        <div className="col-4">
          <ControlledFormItem
            control={control}
            name="height"
            errors={errors?.height}
            label="Height - Z(mm)"
            labelInfo={`Max Height ${MAX_DIMENSIONS_VALUE}mm`}
            component={Input}
            type="number"
            min={MIN_SCALE_SIZE}
            step={SCALE_SIZE_STEP}
            hasClear
            validation
            rules={{
              required: true,
              validation: minValueValidation,
            }}
          />
        </div>
      </div>
    </form>
  );

  return (
    <>
      <div className="mt-4 d-flex justify-content-center">
        <Button onClick={toggleModalVisibility}>Request G-Code</Button>
      </div>

      <Modal backdrop="static" visible={isModalVisible} keyboard={false} size="xl">
        <ModalBootstrap.Header>
          <ModalTitle>Request G-Code</ModalTitle>
        </ModalBootstrap.Header>
        <ModalBody>{renderModalForm()}</ModalBody>
        <ModalFooter>
          <Button className="primary" onClick={handleSubmit(handleGCodeRequest)} type="submit">
            Submit
          </Button>
          <Button variant="secondary" onClick={toggleModalVisibility}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default RequestGCode;
