import { gql } from '@apollo/client';
import { part } from './partRequest';
import { temporaryPart } from './temporaryPart';
import { slicerSetting, material, address } from './wingmen';

const jobShort = `
  id
  estimatedPrintTime
  operatorPrice
  designerPrice
  completion
  extruderTemperature
  bedTemperature
  startUpTime
  estimatedJobTime
  status
  fromGcode
  progressPics {
    id 
    timestamp
    url
  }
  part {
    id
    name
  }
  temporaryPart {
    id
  }
`;

const jobFull = `
  actualJobTime
  completedTime
  completion
  deliveryMethod
  dimensionsHeight
  dimensionsLength
  dimensionsWidth
  estimatedJobTime
  fromGcode
  id
  insertedAt
  estimatedPrintTime
  canBeDeleted
  extruderTemperature
  bedTemperature
  progressPics {
    id 
    timestamp
    url
  }
  jobSchedule {
    id
  }
  material {
    ${material}
  }
  materialLength
  part {
    ${part}
  }
  temporaryPart {
    ${temporaryPart}
  }
  printer {
    id 
    model {
      id
      name
    }
  }
  resizeMode
  slicerSetting {
    ${slicerSetting}
  }
  startUpTime
  status
  threeDContUser {
    userName
    id
  }
  scope
  wingMan {
    machineName
    address
    shippingAddress {
      ${address}
    }
  }
`;

export const pagination = `
  pageNumber
  pageSize
  totalEntries
  totalPages
  entries {
    ${jobFull}
  }
`;

export const order = `
  id
  isDeletable
  deliveryMethod
  processingFee
  shippingCost
  tax
  status
  totalCost
  serviceCode
  shippingAddress {
    ${address}
  }
  jobs {
    scope
    actualJobTime
    aesKey
    bedTemperature
    canBeDeleted
    completedTime
    completion
    deliveryMethod
    designerPrice
    dimensionsHeight
    dimensionsLength
    dimensionsWidth
    estimatedJobTime
    estimatedPrintTime
    extruderTemperature
    fromGcode
    gcodeUrl
    id
    insertedAt
    materialLength
    operatorPrice
    price
    resizeMode
    scope
    shippingAddress
    startUpTime
    status
    success
    originalOperator {
      id
      userName
    }
    progressPics {
      id 
      timestamp
      url
    }
    slicerSetting {
      id
      printQuality
      infillSparseDensity
      infillPattern
    }
    material {
      color
      id
      type
    }
    printer {
      id 
      model {
        id
        name
      }
    }
    threeDContUser {
      availablePrivateParts
      balance
      designerTotalPrintJobs
      email
      firstName
      id
      lastName
      operatorTotalPrintJobs
      phone
      stripeConnected
      unconfirmedEmail
      userName
    }
    part {
      availability
      deleted
      description
      dimensionsHeight
      dimensionsLength
      dimensionsWidth
      id
      name
      patentNumber
      pricePerPrint
      status
      supportEnable
      totalPrintsCount
      transferPrice
      partPics {
        id
        isMain
        type
        url
      }
      userMedia {
        id
        url
        threeDContUser {
          availablePrivateParts
          balance
          designerTotalPrintJobs
          email
          firstName
          id
          lastName
          operatorTotalPrintJobs
          phone
          stripeConnected
          unconfirmedEmail
          userName
        }
      }
    }
    wingMan {
      address
      deliveryPolicy
      id
      latitude
      longitude
      machineName
      model
      serialNumber
      show
      totalPrintJobs
      shippingAddress {
        ${address}
      }
    }
  }
`;

export const getMyJobsQuery = gql`
  query GetMyJobs (
    $page: Int
    $pageSize: Int
    $filter: JobFilter
  ) {
    threeDContJobs (
      page: $page
      pageSize: $pageSize
      filter: $filter
    ) {
      ${pagination}
    }
  }
`;

export const userOrdersQuery = gql`
  query userOrders (
    $filter: OrderFilter
  ) {
    userOrders (
      filter: $filter
    ) {
      ${order}
    }
  }
`;

export const updateOrdersSubscription = gql`
  subscription ordersUpdateSubscription($userId: ID!) {
    ordersUpdateSubscription(userId: $userId) {
      ${order}
    }
  }
`;

export const deleteOrderMutation = gql`
  mutation deleteOrder($orderId: ID!) {
    deleteOrder(orderId: $orderId)
  }
`;

export const cancelJobMutation = gql`
  mutation CancelJob($jobId: ID!) {
    cancelJob(jobId: $jobId) {
      id
      startUpTime
      status
      completion
      part {
        id
        name
        partPics {
          url
        }
      }
    }
  }
`;

export const pauseJobMutation = gql`
  mutation PauseJob($jobId: ID!) {
    pauseJob(jobId: $jobId) {
      id
      startUpTime
      status
      completion
      part {
        id
        name
        partPics {
          url
        }
      }
    }
  }
`;

export const resumeJobMutation = gql`
  mutation resumeJob($jobId: ID!) {
    resumeJob(jobId: $jobId) {
      id
      startUpTime
      status
      completion
      part {
        id
        name
        partPics {
          url
        }
      }
    }
  }
`;

export const updateJobSubscription = gql`
  subscription jobUpdateSubscription($jobId: ID!) {
    jobUpdateSubscription(jobId: $jobId) {
      ${jobShort}
    }
  }
`;

export const updateJobsSubscription = gql`
  subscription jobsUpdateSubscription($userId: ID!) {
    jobsUpdateSubscription(userId: $userId) {
      ${jobShort}
    }
  }
`;

export const purchaseJobSubscription = gql`
  subscription jobPurchaseSubscription($userId: ID!) {
    jobPurchaseSubscription(userId: $userId) {
      ${jobFull}
    }
  }
`;

export const startJobMutation = gql`
  mutation startJob($jobId: ID!) {
    startJob(jobId: $jobId) {
      id
    }
  }
`;

export const completeJobMutation = gql`
  mutation completeJob($jobId: ID!) {
    completeJob(jobId: $jobId) {
      id
    }
  }
`;

export const createJobMutation = gql`
  mutation createJob(
    $deliveryMethod: DeliveryMethod!
    $infillSparseDensity: Int!
    $infillPattern: InfillPatterns!
    $partId: ID
    $temporaryPartId: ID
    $printerId: ID!
    $printerMaterialId: ID!
    $threeDContUserId: ID!
    $wingManId: ID!
    $printQuality: PrintQuality!
    $dimensionsHeight: Decimal
    $dimensionsLength: Decimal
    $dimensionsWidth: Decimal
  ) {
    createJob(
      deliveryMethod: $deliveryMethod
      infillSparseDensity: $infillSparseDensity
      infillPattern:$infillPattern
      partId: $partId
      temporaryPartId: $temporaryPartId
      printerId: $printerId
      printerMaterialId: $printerMaterialId
      threeDContUserId: $threeDContUserId
      wingManId: $wingManId
      printQuality: $printQuality
      dimensionsHeight: $dimensionsHeight
      dimensionsLength: $dimensionsLength
      dimensionsWidth: $dimensionsWidth
    ) {
      ${jobShort}
    }
  }
`;

export const asyncUpdateJobCostMutation = gql`
  mutation asyncUpdateJobCost(
    $jobId: ID!,
    $printerMaterialId: ID!,
    $dimensionsHeight: Decimal
    $dimensionsLength: Decimal
    $dimensionsWidth: Decimal
    $infillSparseDensity: Int
    $infillPattern: InfillPatterns
    $printQuality: PrintQuality
  ) {
    asyncUpdateJobCost(
      jobId: $jobId,
      printerMaterialId: $printerMaterialId,
      dimensionsHeight: $dimensionsHeight,
      dimensionsLength: $dimensionsLength,
      dimensionsWidth: $dimensionsWidth
      infillSparseDensity: $infillSparseDensity,
      infillPattern: $infillPattern
      printQuality: $printQuality
    ){
      ${jobShort}
    }
  }
`;

export const purchaseJobMutation = gql`
  mutation purchaseJob($jobId: ID!) {
    purchaseJob(jobId: $jobId)
  }
`;

export const purchaseOrderMutation = gql`
  mutation purchaseOrder {
    purchaseOrder
  }
`;

export const createCartItemMutation = gql`
  mutation createCartItem($jobId: ID!, $amount: Int!) {
    createCartItem(jobId: $jobId, amount: $amount) {
      cartItems {
        id
      }
    }
  }
`;

export const updateCartItemMutation = gql`
  mutation updateCartItem($id: ID!, $amount: Int!) {
    updateCartItem(id: $id, amount: $amount) {
      cartItems {
        id
      }
    }
  }
`;

export const deleteCartItemMutation = gql`
  mutation deleteCartItem($id: ID!) {
    deleteCartItem(id: $id) {
      cartItems {
        id
      }
    }
  }
`;

export const resetCartMutation = gql`
  mutation resetCart {
    resetCart
  }
`;

export const getShippingRatesMutation = gql`
  mutation getShippingRates($shippingAddress: AddressInput, $useBillingAddress: Boolean) {
    getShippingRates(shippingAddress: $shippingAddress, useBillingAddress: $useBillingAddress) {
      carrierDeliveryDays
      carrierFriendlyName
      serviceCode
      serviceType
      shippingCost
    }
  }
`;

export const estimateCartCostMutation = gql`
  mutation estimateCartCost(
    $deliveryMethod: DeliveryMethod
    $serviceCode: String
    $shippingAddress: AddressInput
    $useBillingAddress: Boolean
  ) {
    estimateCartCost(
      deliveryMethod: $deliveryMethod
      serviceCode: $serviceCode
      shippingAddress: $shippingAddress
      useBillingAddress: $useBillingAddress
    ) {
      cartItems {
        id
        amount
        job {
          id
          price
          part {
            id
            name
          }
        }
      }
      deliveryMethod
      processingFee
      shippingCost
      tax
      totalCost
    }
  }
`;

export const deleteJobMutation = gql`
  mutation deleteJob($jobId: ID!) {
    deleteJob(jobId: $jobId)
  }
`;

export const jobUpdateOnUserSubscription = gql`
  subscription jobUpdateOnUserSubscription($userId: ID!){
    jobUpdateOnUserSubscription(userId: $userId){
      ${jobShort}
    }
  }
`;
