import React from 'react';
import { useQuery } from '@apollo/client';
import { Loader } from 'react-bootstrap-typeahead';
import SearchInput from 'components/custom-inputs/SearchInput';
import Pagination from 'components/Pagination';
import UserCard from 'components/UserCard';
import useDebounce from 'hooks/useDebounce';
import useSearchParams from 'hooks/useSearchParams';
import { getListDesignersQuery } from 'requests/designers';
import { getPaginationParams } from 'utils/global';

const DesignersList = () => {
  const { page = 1, pageSize = 9, searchValue = '' } = useSearchParams();

  const debounceValue = useDebounce(searchValue);

  const { data, loading } = useQuery(getListDesignersQuery, {
    variables: {
      page: +page,
      pageSize: +pageSize,
      ...(debounceValue && { searchValue: debounceValue }),
    },
  });

  const pagination = getPaginationParams(data?.listDesigners);

  return (
    <div className="container-fluid">
      <div className="font-weight-bold text-center h3 my-5 mx-1">Designers</div>
      <div className="col-3 m-auto py-2">
        <SearchInput value={searchValue} hasClear />
      </div>
      <div className="d-flex flex-column align-items-center px-4 mb-3">
        <div className="row justify-content-center ">
          {loading && <Loader />}
          {data?.listDesigners?.entries?.map(({ id, userName, profilePic, designerTotalPrintJobs }) => (
            <UserCard
              key={id}
              id={id}
              userName={userName}
              profilePic={profilePic}
              totalPrintJobs={designerTotalPrintJobs}
              entity="designer"
            />
          ))}
        </div>
        <div className="my-3">{pagination.totalPages > 1 && <Pagination pagination={pagination} />}</div>
      </div>
    </div>
  );
};

export default DesignersList;
