import React from 'react';
import { Row, Card } from 'react-bootstrap';
import { formatDuration } from 'lib/helpers';
import styles from './index.module.scss';

const JobQueue = ({ queue }) => (
  <div className="container">
    <Row className="my-3">
      <Card className="p-3 w-100">
        <h5>Job Queue</h5>
        <div className={styles.jobQueueContainer}>
          {queue.map(({ estimatedPrintTime }, i) => (
            <p key={i}>
              <span className="font-weight-bold">Print Job {i + 1}:</span> {formatDuration(estimatedPrintTime)}{' '}
              {i === 0 && <span className="font-weight-bold text-danger">(Current job time remaining)</span>}
            </p>
          ))}
        </div>
      </Card>
    </Row>
  </div>
);

export default JobQueue;
