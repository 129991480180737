import React, { useMemo, useContext, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Accordion, Card, Row, Button } from 'react-bootstrap';
import { FaBars } from 'react-icons/fa';
import { toast } from 'react-toastify';
import JobItem from 'components/JobItem';
import Loading from 'components/Loading';
import MessageModal from 'components/MessageModal';
import Pagination from 'components/Pagination';
import { deleteOrderMutation } from 'requests/jobs';
import { joinAddress, handleErrors, humanizeSnakeCase } from 'utils/global';
import { addTemporaryPartToPart } from 'utils/job';
import { AuthContext } from 'сontexts/AuthContext';

const OrderItem = ({ data, inProgress, pagination, refetch, loading }) => {
  const [modalMessageData, setModalMessageData] = useState(null);

  const client = useApolloClient();

  const { user } = useContext(AuthContext);

  const orderItems = useMemo(
    () =>
      (data ?? [])?.map((orderItem) => {
        const statuses = orderItem?.jobs?.map(({ status, id }) => ({
          status,
          id,
        }));
        const inProgressStatuses = statuses.filter(({ status }) =>
          ['IN_PROGRESS', 'STARTING', 'RESUMING'].includes(status)
        );
        const readyForPrintStatuses = statuses.filter(({ status }) =>
          ['READY_FOR_PRINT', 'PAUSED', 'PAUSING'].includes(status)
        );
        const doneStatuses = statuses.filter(({ status }) => ['DONE', 'COMPLETED'].includes(status));

        return (
          <Accordion key={orderItem.id}>
            <Card className="border border-dark rounded">
              <Card.Header as={Row} className="d-flex justify-content-between">
                <div className="d-flex ">
                  <Accordion.Toggle
                    className="d-flex align-items-center cursor-pointer p-4"
                    size="sm"
                    as={'div'}
                    eventKey={orderItem.id + 'order'}
                  >
                    <FaBars />
                  </Accordion.Toggle>

                  <div className={'p-4 m-0'}>
                    <div className={'d-flex justify-content-between'}>
                      <h6 className={'card-title font-weight-bold'}>{`Order ${orderItem.id}`}</h6>
                      <span
                        className={'ml-2'}
                      >{`${orderItem.status} (${doneStatuses.length}/${statuses.length} Jobs printed)`}</span>
                    </div>

                    <div className={'mt-2'}>
                      <span className={'font-weight-bold'}>{'Print Job statistics: '}</span>
                      <div className={'mt-1'}>
                        <span>{`${doneStatuses.length} Jobs Completed ${
                          doneStatuses.length ? '(' + doneStatuses.map(({ id }) => id).join(', ') + ')' : ''
                        }`}</span>
                      </div>
                      <div className={'mt-1'}>
                        <span>{`${inProgressStatuses.length} Jobs in Progress ${
                          inProgressStatuses.length ? '(' + inProgressStatuses.map(({ id }) => id).join(', ') + ')' : ''
                        }`}</span>
                      </div>
                      <div className={'mt-1'}>
                        <span>{`${readyForPrintStatuses.length} Jobs Ready for Print ${
                          readyForPrintStatuses.length
                            ? '(' + readyForPrintStatuses.map(({ id }) => id).join(', ') + ')'
                            : ''
                        }`}</span>
                      </div>
                    </div>
                  </div>

                  <div className={'p-4 m-0'}>
                    <div className={' mt-2 d-flex justify-content-between invisible'}>
                      <h6 className={'card-title font-weight-bold'}>{`Order ${orderItem.id}`}</h6>
                      <span
                        className={'ml-2'}
                      >{`(Status: IN PROGRESS ${inProgressStatuses.length}/${statuses.length})`}</span>
                    </div>

                    <div>
                      <span className={'font-weight-bold '}>{'Requested by: '}</span>
                      <span>{orderItem?.jobs?.[0]?.threeDContUser?.userName}</span>
                    </div>

                    <span className={'font-weight-bold mt-1'}>{'Delivery: '}</span>
                    {orderItem.deliveryMethod === 'LOCAL_PICKUP' && (
                      <div className={'mt-1'}>
                        <span>{`Local pickup at: ${
                          orderItem?.jobs?.[0]?.wingMan?.shippingAddress
                            ? joinAddress(orderItem?.jobs?.[0]?.wingMan?.shippingAddress)
                            : '-'
                        }`}</span>
                      </div>
                    )}

                    {orderItem.deliveryMethod === 'SHIPPING' && (
                      <div className={'mt-1'}>
                        <span>{`Ship to: ${joinAddress(
                          orderItem?.shippingAddress?.firstAddressLine
                            ? orderItem?.shippingAddress
                            : user?.billingAddress
                        )}`}</span>

                        <p>{`Shipping method: ${humanizeSnakeCase(orderItem.serviceCode ?? '-')}`}</p>
                      </div>
                    )}
                  </div>

                  <div className={'p-4 m-0'}>
                    <div className={' mt-2 d-flex justify-content-between invisible'}>
                      <h6 className={'card-title font-weight-bold'}>{`Order ${orderItem.id}`}</h6>
                      <span
                        className={'ml-2'}
                      >{`(Status: IN PROGRESS ${inProgressStatuses.length}/${statuses.length})`}</span>
                    </div>

                    <div>
                      <span className={'font-weight-bold '}>{'Printed by: '}</span>
                      <span>{orderItem?.jobs?.[0]?.originalOperator?.userName ?? '-'}</span>
                    </div>

                    <div>
                      <span className={'font-weight-bold '}>{'Wingman name: '}</span>
                      <span>{orderItem?.jobs?.[0]?.wingMan?.machineName}</span>
                    </div>
                  </div>
                </div>
                <div className="col-2 d-flex flex-column justify-content-around">
                  <Button variant="warning" size="sm" onClick={() => setModalMessageData({ orderId: orderItem?.id })}>
                    Send dispute
                  </Button>
                  {orderItem?.isDeletable && (
                    <Button
                      variant="danger"
                      size="sm"
                      onClick={async () => {
                        try {
                          await client.mutate({
                            mutation: deleteOrderMutation,
                            variables: { orderId: orderItem.id },
                          });
                          toast.success('The order is successfully deleted');
                          refetch();
                        } catch (e) {
                          handleErrors(e);
                        }
                      }}
                    >
                      Delete Order
                    </Button>
                  )}
                </div>
              </Card.Header>
            </Card>
            <Accordion.Collapse eventKey={orderItem.id + 'order'}>
              <div className="ml-4 pl-4">
                {orderItem.jobs.map((jobItem) => (
                  <JobItem
                    key={jobItem.id}
                    jobItem={addTemporaryPartToPart(jobItem)}
                    inProgress={inProgress}
                    refetch={refetch}
                  />
                ))}
              </div>
            </Accordion.Collapse>
          </Accordion>
        );
      }),
    [client, data, inProgress, refetch, user?.billingAddress]
  );

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      {data && (
        <div className="d-flex flex-column align-items-center">
          {orderItems}
          <div className="my-3">
            {pagination.totalPages > 1 && data.length > 0 && <Pagination pagination={pagination} />}
          </div>
          {modalMessageData && <MessageModal data={modalMessageData} handleClose={() => setModalMessageData(null)} />}
        </div>
      )}
    </>
  );
};

export default OrderItem;
