import React from 'react';
import { useApolloClient } from '@apollo/client';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import AuthPageWrapper from 'components/AuthPageWrapper';
import ControlledFormItem from 'components/ControlledFormItem/index';
import Input from 'components/Input/index';
import { resetPasswordMutation } from 'requests/auth';
import { handleErrors } from 'utils/global';
import { emailValidation } from 'utils/validators';

const ForgotPassword = () => {
  const client = useApolloClient();

  const {
    control,
    loading,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const onResetPassword = async (values) => {
    try {
      await client.mutate({
        mutation: resetPasswordMutation,
        variables: values,
      });
      toast.success('If this email was found in our system, you would receive an email with further instructions');
    } catch (e) {
      handleErrors(e, 'A reset password error has occurred');
    }
  };

  return (
    <AuthPageWrapper title="Reset Password">
      <form className="p-2" onSubmit={handleSubmit(onResetPassword)}>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="email"
            label="Email"
            errors={errors.email}
            component={Input}
            hasClear
            type="email"
            placeholder="Email"
            validation
            rules={{
              required: true,
              validate: { emailValidation },
            }}
          />
        </div>
        <div className="d-grid">
          <Button disabled={loading} type="submit" size="lg">
            Reset Password
          </Button>
        </div>
      </form>
    </AuthPageWrapper>
  );
};

export default ForgotPassword;
