import React, { useRef, useEffect, useState } from 'react';
import cn from 'classnames';
import { camelCase } from 'lodash';
import PropTypes from 'prop-types';
import { InputGroup } from 'react-bootstrap';
import { Typeahead, ClearButton } from 'react-bootstrap-typeahead';
import { FaTimesCircle } from 'react-icons/fa';
import InputError from 'components/InputError';
import styles from './index.module.scss';

const Select = ({
  label,
  labelClassName,
  value,
  defaultValue,
  onChange,
  hasClear,
  errors,
  items,
  placeholder,
  validation,
  disabled,
  isSearchable,
  renderCustomOptions,
  renderInputStatus,
  getOptionStatus,
  defaultValueField = 'id',
}) => {
  const selectRef = useRef();
  const changeHandler = (e) => {
    onChange?.(e.target.value);
  };

  const [selected, setSelected] = useState([]);

  const [openCustomOptions, setOpenCustomOptions] = useState(false);

  const toggleCustomOptions = () => getOptionStatus && setOpenCustomOptions((prev) => !prev);

  const handleChangeCustomOption = (value) => {
    onChange?.(value);
    setOpenCustomOptions(false);
  };

  useEffect(() => {
    defaultValue &&
      isSearchable &&
      setSelected([{ label: defaultValue[defaultValueField] || defaultValue, value: defaultValue }]);
  }, [defaultValue, isSearchable, defaultValueField]);

  const changeSearchableHandler = (selected) => {
    selected.length && onChange?.(selected[0].value);
    isSearchable && selected.length && setSelected(selected);
  };

  const clear = () => {
    onChange?.(null);
    isSearchable && setSelected();
    if (selectRef.current) selectRef.current.selectedIndex = 0;
  };

  useEffect(() => {
    const handleCloseCustomSelect = (e) => {
      if (e?.target?.id !== selectRef?.current?.id) setOpenCustomOptions(false);
    };

    document.body.addEventListener('click', handleCloseCustomSelect);

    return () => document.body.removeEventListener('click', handleCloseCustomSelect);
  }, []);

  useEffect(() => {
    setOpenCustomOptions(false);
  }, [selectRef]);

  useEffect(() => {
    if (!value && !isSearchable) clear();
  }, [value]);

  const renderClearIcon = hasClear && value;

  return (
    <div className="py-2">
      {label && (
        <label className={cn(labelClassName || 'h6')} htmlFor={`selectItem-${camelCase(label)}`}>
          {label}
        </label>
      )}
      {isSearchable ? (
        <>
          <Typeahead
            id="selectSearchableItem"
            onChange={changeSearchableHandler}
            options={items || []}
            placeholder={placeholder || 'Search and select option'}
            clearButton={hasClear}
            selected={selected}
            disabled={disabled}
            renderMenuItemChildren={renderCustomOptions}
          >
            {({ onClear, selected }) => (
              <div className="rbt-aux">
                {hasClear && !!selected.length && (
                  <>
                    <ClearButton
                      onClick={() => {
                        onClear();
                        clear?.();
                      }}
                    />
                  </>
                )}
              </div>
            )}
          </Typeahead>
          {renderInputStatus && renderInputStatus()}
        </>
      ) : (
        <>
          <InputGroup>
            <select
              ref={selectRef}
              id="selectItem"
              onClick={toggleCustomOptions}
              defaultValue={defaultValue}
              value={value || undefined}
              className={cn(
                'form-control position-relative',
                validation && errors && 'is-invalid',
                validation && value && !errors && 'is-valid',
                renderClearIcon && styles.arrowRemove
              )}
              onChange={changeHandler}
              disabled={disabled}
            >
              <>
                {getOptionStatus && value && (
                  <option value={value} selected disabled hidden>
                    {value}
                  </option>
                )}
                <option value="" selected disabled hidden>
                  {placeholder || 'Select option'}
                </option>
                {!getOptionStatus &&
                  items &&
                  items.map((option) => (
                    <option key={option.label} value={option.value}>
                      {option.label}
                    </option>
                  ))}
              </>
            </select>
            {renderClearIcon && <FaTimesCircle className={styles.clear} onClick={clear} />}
          </InputGroup>

          {getOptionStatus && openCustomOptions && (
            <div className={cn('d-flex flex-column border', styles.customOptions)}>
              {items &&
                items.map((option) => (
                  <div
                    key={option.label}
                    onClick={() => handleChangeCustomOption(option.value)}
                    value={option.value}
                    className="list-group-item-action cursor-pointer py-1"
                  >
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="mx-4">{option.label}</span>
                      {getOptionStatus(option.value)}
                    </div>
                  </div>
                ))}
            </div>
          )}
          {renderInputStatus && renderInputStatus()}
        </>
      )}
      {validation && <InputError value={value} errors={errors} />}
    </div>
  );
};

Select.propTypes = {
  label: PropTypes.string,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  items: PropTypes.array,
  onChange: PropTypes.func,
  errors: PropTypes.object,
  placeholder: PropTypes.string,
};

export default Select;
