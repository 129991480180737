import React from 'react';
import NoData from 'components/NoData';
import WingmanCard from '../WingmanCard';

const WingmansGrid = ({ wingmans }) => {
  if (wingmans.length === 0) return <NoData />;

  return (
    <div className="d-flex flex-wrap container pt-3">
      {wingmans.map((wingman) => (
        <WingmanCard key={wingman.id} wingman={wingman} />
      ))}
    </div>
  );
};

export default WingmansGrid;
