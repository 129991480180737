import React, { useState, useRef, useEffect, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/client';
import { Button, ListGroup, ListGroupItem, Row } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import Loading from 'components/Loading';
import { LANDING_LINK } from 'constants/global';
import { MATERIAL_TYPES } from 'constants/wingman';
import usePrintQuality from 'hooks/usePrintQuality';
import {
  addMaterialMutation,
  getMyWingmenQuery,
  updateWingmanMutation,
  removeMaterialMutation,
  updateWingmanSlicerSettingsMutation,
} from 'requests/wingmen';
import { formatBooleanValue, handleErrors } from 'utils/global';
import { AuthContext } from 'сontexts/AuthContext';
import { GoogleMapsApiProvider } from 'сontexts/GoogleMapsApiContext';
import { MapProvider } from 'сontexts/MapContext';
import AddMaterialsForm from './AddMaterialsForm';
import MaterialSettings from './MaterialSettings';
import MaterialsList from './MaterialsList';
import SliderSection from './SliderSection';
import WingmanForm from './WingmanForm';

const MyWingman = () => {
  const { user } = useContext(AuthContext);
  const wingmanQueryVariables = { ownerId: user.id };

  const { data, loading } = useQuery(getMyWingmenQuery, {
    variables: wingmanQueryVariables,
    fetchPolicy: 'cache-and-network',
  });

  const wingmans = data?.getMyWingmen;

  const [selectedWingman, setSelectedWingman] = useState(null);
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const client = useApolloClient();

  const [toggle, setToggle] = useState({
    ABS: false,
    PLA: false,
  });

  let sliderRef = useRef(null);

  const printQualityItems = usePrintQuality(selectedWingman?.printer?.nozzleSize);

  const {
    setValue,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    watch,
  } = useForm();

  useEffect(() => {
    const changeWingman = (item) => {
      setSelectedWingman(item);

      if (item?.printer) {
        const { costPerHour, nozzleSize, currentMaterial } = item?.printer;

        reset({
          model: item?.printer?.model?.name,
          nozzleSize: nozzleSize ? Number(nozzleSize)?.toFixed(1) : null,
          costPerHour: Number(costPerHour),
          material: currentMaterial?.id ?? null,
          city: item?.shippingAddress?.city ?? null,
          deliveryPolicy: item?.deliveryPolicy ?? null,
          countryCode: item?.shippingAddress?.countryCode ?? 'US',
          firstAddressLine: item?.shippingAddress?.firstAddressLine ?? null,
          secondAddressLine: item?.shippingAddress?.secondAddressLine ?? null,
          postalCode: item?.shippingAddress?.postalCode ?? null,
          stateProvince: item?.shippingAddress?.stateProvince ?? null,
        });
      } else {
        reset({
          model: null,
          nozzleSize: null,
          costPerHour: null,
          material: null,
          city: null,
          deliveryPolicy: null,
          countryCode: 'US',
          firstAddressLine: null,
          secondAddressLine: null,
          postalCode: null,
          stateProvince: null,
        });
      }
    };

    wingmans && changeWingman(wingmans[slideIndex]);
    sliderRef.current && sliderRef.current.slickGoTo(slideIndex);
  }, [reset, slideIndex, wingmans]);

  useEffect(() => {
    if (wingmans) {
      const foundWingman = wingmans.find((wingman) => wingman.id === selectedWingman?.id);
      setSelectedWingman(foundWingman || wingmans[0]);
    }
    selectedWingman &&
      setAvailableMaterials(
        wingmans?.find((wingman) => wingman.id === selectedWingman.id)?.printer?.availableMaterials
      );
  }, [wingmans, selectedWingman]);

  const onWingmanUpdate = async ({
    nozzleSize,
    material,
    model,
    latitude,
    longitude,
    deliveryPolicy,
    city,
    firstAddressLine,
    postalCode,
    stateProvince,
    secondAddressLine,
    ...values
  }) => {
    try {
      const { data } = await client.mutate({
        mutation: updateWingmanMutation,
        variables: {
          latitude: latitude,
          longitude: longitude,
          nozzleSize: Number(nozzleSize)?.toFixed(1),
          wingmanId: selectedWingman?.id,
          deliveryPolicy: deliveryPolicy,
          shippingAddress: {
            city,
            countryCode: 'US',
            firstAddressLine,
            postalCode,
            stateProvince,
            ...(secondAddressLine && { secondAddressLine }),
          },
          ...(material && { currentMaterialId: material }),
          ...(model && { modelId: model?.id }),
          ...values,
        },
        refetchQueries: [{ query: getMyWingmenQuery, variables: wingmanQueryVariables }],
      });

      if (data.updateWingman.id === selectedWingman.id) toast.success('Wingman was successfully updated');
    } catch (e) {
      handleErrors(e, 'An error has occurred while updating the wingman');
    }
  };

  const onMaterialAdd = async ({ type, color }) => {
    try {
      await client.mutate({
        mutation: addMaterialMutation,
        variables: {
          color,
          type,
          printerId: selectedWingman?.printer?.id,
        },
        refetchQueries: [{ query: getMyWingmenQuery, variables: wingmanQueryVariables }],
      });
      toast.success('Material added');
    } catch (e) {
      handleErrors(e, 'An error has occurred while adding the material');
    }
  };

  const onMaterialDelete = async (materialId) => {
    try {
      await client.mutate({
        mutation: removeMaterialMutation,
        variables: { materialId },
        refetchQueries: [{ query: getMyWingmenQuery, variables: wingmanQueryVariables }],
      });
      toast.success('The material deleted succesfully');
    } catch (e) {
      handleErrors(e, 'An error has occurred while deleting the material');
    }
  };

  const onUpdateSlicerSettings = async ({ ...values }) => {
    const integerFields = [
      'defaultMaterialBedTemperature',
      'defaultMaterialPrintTemperature',
      'infillSparseDensity',
      'materialBedTemperatureLayerZero',
      'materialPrintTemperatureLayerZero',
      'materialFlow',
    ];

    const firstSetting = { plasticType: MATERIAL_TYPES.PLA };
    const secondSetting = { plasticType: MATERIAL_TYPES.ABS };

    for (const [key, value] of Object.entries(values)) {
      if (key.includes(MATERIAL_TYPES.PLA)) {
        const newKey = key.slice(0, -3);
        integerFields.includes(newKey)
          ? (firstSetting[newKey] = Number(value))
          : (firstSetting[newKey] = formatBooleanValue(value));
      }

      if (key.includes(MATERIAL_TYPES.ABS)) {
        const newKey = key.slice(0, -3);
        integerFields.includes(newKey)
          ? (secondSetting[newKey] = Number(value))
          : (secondSetting[newKey] = formatBooleanValue(value));
      }
    }

    try {
      const { data } = await client.mutate({
        mutation: updateWingmanSlicerSettingsMutation,
        variables: {
          firstSetting,
          secondSetting,
          wingmanId: selectedWingman?.id,
        },
        refetchQueries: [{ query: getMyWingmenQuery, variables: wingmanQueryVariables }],
      });

      if (data.updateWingmanSliserSettings.id === selectedWingman.id) {
        toast.success('Slicer settings were successfully updated');
      }
    } catch (e) {
      handleErrors(e, 'An error has occurred while updating slicer settings');
    }
  };

  if (loading) return <Loading />;

  return (
    <GoogleMapsApiProvider>
      <MapProvider>
        <div className="container-fluid">
          <Row className="h-100">
            {wingmans && selectedWingman ? (
              <>
                <div className="col-2 border-right overflow-auto py-2">
                  <ListGroup className="">
                    {wingmans.map((item, i) => (
                      <Button
                        key={item.id}
                        onClick={() => setSlideIndex(i)}
                        variant="link"
                        as={ListGroupItem}
                        className="my-1 border"
                      >
                        {item?.machineName || `Wingman №${item?.id}`}
                      </Button>
                    ))}
                  </ListGroup>
                </div>
                <div className="col-10 py-2">
                  <div className="p-2">
                    <SliderSection
                      wingmans={wingmans}
                      sliderRef={sliderRef}
                      slideIndex={slideIndex}
                      setSlideIndex={setSlideIndex}
                    />
                    {selectedWingman && (
                      <Row>
                        <div className="col-4">
                          <WingmanForm
                            selectedWingman={selectedWingman}
                            onSubmit={handleSubmit(onWingmanUpdate)}
                            control={control}
                            errors={errors}
                            setValue={setValue}
                            watch={watch}
                            userPositionInit
                          />
                          <AddMaterialsForm onSubmit={onMaterialAdd} />
                          <MaterialsList onDelete={onMaterialDelete} materials={availableMaterials} />
                        </div>
                        {printQualityItems?.length && (
                          <div className="col-8">
                            <MaterialSettings
                              selectedWingman={selectedWingman}
                              printQualityItems={printQualityItems}
                              toggle={toggle}
                              setToggle={setToggle}
                              onSubmit={onUpdateSlicerSettings}
                            />
                          </div>
                        )}
                      </Row>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <div className="jumbotron container mt-5">
                <h3 className="m-auto text-center">
                  You don&#39;t have any wingmans. Please, order the new one{' '}
                  <a
                    href={`${LANDING_LINK}/order-wingman`}
                    className="text-primary cursor-pointer text-decoration-none"
                  >
                    Here
                  </a>
                </h3>
              </div>
            )}
          </Row>
        </div>
      </MapProvider>
    </GoogleMapsApiProvider>
  );
};

export default MyWingman;
