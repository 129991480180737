import React, { useRef, useEffect, useState, useMemo } from 'react';
import { useApolloClient } from '@apollo/client';
import { Container, Button, Alert } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import RadioCheckBox from 'components/RadioCheckBox';
import SelectSearch from 'components/SelectSearch';
import { DELIVERY_INFORMATION_ITEMS, LOCAL_PICKUP, SHIPPING } from 'constants/part';
import {
  SHIPPING_ADDRESS_ITEMS,
  SHIPPING_ADDRESS_SETTINGS,
  STATES_ITEMS,
  DELIVERY_POLICY_SETTINGS,
} from 'constants/wingman';
import { getShippingRatesMutation } from 'requests/jobs';
import { handleErrors, joinAddress } from 'utils/global';
import { isUS, isZipValid } from 'utils/validators';

const DeliveryForm = ({ currentUser, onComplete, onGetShippingRates }) => {
  const client = useApolloClient();
  const cartItemDeliveryPolicy = currentUser?.cart?.cartItems?.[0]?.job?.wingMan?.deliveryPolicy;
  const toastId = useRef(null);

  const [loading, setLoading] = useState(false);

  const {
    control,
    formState: { errors },
    watch,
    handleSubmit,
    setValue,
  } = useForm();

  const [deliveryInformation, shippingAddress] = watch(['deliveryInformation', 'shippingAddress']);

  const submitHandler = async ({
    shippingAddress,
    city,
    firstAddressLine,
    postalCode,
    secondAddressLine,
    stateProvince,
  }) => {
    try {
      setLoading(true);
      const variables = {
        ...(shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS && {
          useBillingAddress: shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS,
        }),
        ...(shippingAddress === SHIPPING_ADDRESS_SETTINGS.ANOTHER_ADDRESS && {
          shippingAddress: {
            city,
            countryCode: 'US',
            firstAddressLine,
            postalCode,
            secondAddressLine,
            stateProvince,
          },
        }),
      };
      const data = await client.mutate({
        mutation: getShippingRatesMutation,
        variables,
      });

      onComplete?.(true, true);
      onGetShippingRates?.(data?.data?.getShippingRates, variables);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.dismiss(toastId.current);
      handleErrors(error, 'An error has occurred while estimatimating');
    }
  };

  useEffect(() => {
    switch (cartItemDeliveryPolicy) {
      case DELIVERY_POLICY_SETTINGS?.PICKUP_AND_SHIP:
        onComplete?.(true, false);
        break;
      case DELIVERY_POLICY_SETTINGS?.SHIP:
        setValue('deliveryInformation', SHIPPING);
        break;
      case DELIVERY_POLICY_SETTINGS?.PICKUP:
        onComplete?.(true, false);
        setValue('deliveryInformation', LOCAL_PICKUP);
        break;
    }
  }, [cartItemDeliveryPolicy, onComplete, setValue]);

  const shippingAddressInfo = useMemo(() => {
    if (deliveryInformation === LOCAL_PICKUP && currentUser?.cart?.cartItems?.[0]?.job?.wingMan?.shippingAddress) {
      return (
        <div className={'mt-1'}>
          <span className={'font-weight-bold'}>{'Pickup address: '}</span>
          <span>{joinAddress(currentUser?.cart?.cartItems?.[0]?.job?.wingMan?.shippingAddress)}</span>
        </div>
      );
    } else {
      return null;
    }
  }, [currentUser?.cart?.cartItems, deliveryInformation]);

  return (
    <form className="w-100" onSubmit={handleSubmit(submitHandler)}>
      <Container fluid className="p-3">
        <h5 className="font-weight-bold">Delivery information:</h5>

        <ControlledFormItem
          control={control}
          name="deliveryInformation"
          items={DELIVERY_INFORMATION_ITEMS}
          errors={errors.deliveryInformation}
          defaultValue={cartItemDeliveryPolicy === DELIVERY_POLICY_SETTINGS?.SHIP ? SHIPPING : LOCAL_PICKUP}
          component={RadioCheckBox}
          disabled={cartItemDeliveryPolicy !== DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP}
          onChange={(value) => {
            onGetShippingRates?.(null, null);
            setValue('deliveryInformation', value);
            setValue('shippingAddress', SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS);
            onComplete?.(value === LOCAL_PICKUP, value !== LOCAL_PICKUP);
          }}
          rules={{
            required: true,
          }}
        />

        {cartItemDeliveryPolicy === DELIVERY_POLICY_SETTINGS?.PICKUP && (
          <div className="h5 font-weight-bold d-flex">
            <Alert variant={'primary'}>Wingman supports only local pickup delivery method.</Alert>
          </div>
        )}
        {cartItemDeliveryPolicy === DELIVERY_POLICY_SETTINGS?.SHIP && (
          <div className="h5 font-weight-bold d-flex">
            <Alert variant={'primary'}>Wingman supports only shipping delivery method.</Alert>
          </div>
        )}

        {shippingAddressInfo}

        {deliveryInformation === SHIPPING && (
          <>
            <h5 className="font-weight-bold">Shipping address:</h5>
            <ControlledFormItem
              disabled={deliveryInformation !== SHIPPING}
              control={control}
              name="shippingAddress"
              items={SHIPPING_ADDRESS_ITEMS}
              errors={errors.shippingAddress}
              defaultValue={SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
              component={RadioCheckBox}
              onChange={(value) => {
                onGetShippingRates?.(null, null);
                onComplete?.(false, false);
                if (value === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS) {
                  setValue('country', 'United States');
                  setValue('firstAddressLine', currentUser?.billingAddress?.firstAddressLine);
                  setValue('secondAddressLine', currentUser?.billingAddress?.secondAddressLine);
                  setValue('city', currentUser?.billingAddress?.city);
                  setValue('postalCode', currentUser?.billingAddress?.postalCode);
                  setValue('stateProvince', currentUser?.billingAddress?.stateProvince);
                } else {
                  setValue('country', 'United States');
                  setValue('firstAddressLine', '');
                  setValue('secondAddressLine', '');
                  setValue('city', '');
                  setValue('postalCode', '');
                  setValue('stateProvince', null);
                }
              }}
              rules={{
                required: true,
              }}
            />

            <div className="row">
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="country"
                  label="Country"
                  placeholder="Country"
                  errors={errors.addressCountry}
                  defaultValue={'United States'}
                  component={Input}
                  disabled
                  validation
                  rules={{ required: true, validate: isUS }}
                />
              </div>
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="firstAddressLine"
                  label="Address Line 1"
                  placeholder="Enter Address Line 1"
                  defaultValue={currentUser?.billingAddress?.firstAddressLine}
                  errors={errors?.firstAddressLine}
                  component={Input}
                  disabled={shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
                  validation
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="secondAddressLine"
                  label="Address Line 2"
                  placeholder="Enter Address Line 2"
                  defaultValue={currentUser?.billingAddress?.secondAddressLine}
                  errors={errors?.secondAddressLine}
                  component={Input}
                  disabled={shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
                  className="w-100"
                  validation
                />
              </div>
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="city"
                  label="City"
                  placeholder="Enter City"
                  defaultValue={currentUser?.billingAddress?.city}
                  errors={errors?.city}
                  component={Input}
                  disabled={shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
                  className="w-100"
                  validation
                  rules={{ required: true }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="postalCode"
                  label="ZIP"
                  placeholder="Enter ZIP"
                  defaultValue={currentUser?.billingAddress?.postalCode}
                  errors={errors?.postalCode}
                  component={Input}
                  disabled={shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
                  className="w-100"
                  validation
                  rules={{ required: true, validate: { isZipValid } }}
                />
              </div>
              <div className="col-6">
                <ControlledFormItem
                  control={control}
                  name="stateProvince"
                  label="State/Province"
                  placeholder="Enter State/Province"
                  defaultValue={currentUser?.billingAddress?.stateProvince}
                  errors={errors?.stateProvince}
                  component={SelectSearch}
                  disabled={shippingAddress === SHIPPING_ADDRESS_SETTINGS.BILLING_ADDRESS}
                  className="w-100"
                  hasClear
                  validation
                  rules={{ required: true }}
                  items={STATES_ITEMS}
                />
              </div>
            </div>

            {deliveryInformation === SHIPPING && (
              <Button
                disabled={loading}
                onClick={handleSubmit(submitHandler)}
                variant="primary"
                className="justify-content-center"
              >
                {'Get the delivery options'}
              </Button>
            )}
          </>
        )}
      </Container>
    </form>
  );
};

export default DeliveryForm;
