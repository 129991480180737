import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import AsyncOperation from 'components/AsyncOperation';
import { ASYNC_OPERATION_TYPES } from 'constants/global';
import { stripeConnectConfirmMutation } from 'requests/stripe';
import { AuthContext } from 'сontexts/AuthContext';

const StripeConnect = () => {
  const { user } = useContext(AuthContext);
  const history = useHistory();

  return (
    <div className="d-flex flex-column justify-content-center align-items-center w-100">
      <AsyncOperation
        type={ASYNC_OPERATION_TYPES.STRIPE}
        successMessage="Your Stripe account was successfully connected."
        mutation={stripeConnectConfirmMutation}
      />
      {user && (
        <Button className="mt-3" onClick={() => history.push(`/user/${user?.id}`)}>
          Return to the Profile
        </Button>
      )}
    </div>
  );
};

export default StripeConnect;
