import React, { useEffect, useState, useMemo } from 'react';
import { useLazyQuery, useApolloClient } from '@apollo/client';
import { Container, Row, Col } from 'react-bootstrap';
import { useHistory, useLocation } from 'react-router-dom';
import Error from 'components/Error';
import Tabs from 'components/Tabs';
import { ORDER_COMPLETED_STATUSES, ORDER_IN_PROGRESS_STATUSES } from 'constants/job';
import { useAuth } from 'hooks/useAuth';
import { userOrdersQuery, updateOrdersSubscription } from 'requests/jobs';
import { getPaginationParams, getQueryParams, handleErrors } from 'utils/global';
import OrderItem from './OrderItem';

const Orders = () => {
  const history = useHistory();
  const { search } = useLocation();

  const client = useApolloClient();
  const { user } = useAuth();

  const [inProgressOrdersData, setInProgressOrdersData] = useState(null);
  const [completedOrdersData, setCompletedOrdersData] = useState(null);

  const [getOrders, { loading, error, refetch }] = useLazyQuery(userOrdersQuery, {
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    getOrders({ variables: { filter: { status: ORDER_IN_PROGRESS_STATUSES } } }).then(({ data }) => {
      setInProgressOrdersData(data.userOrders);
    });
  }, [getOrders, setInProgressOrdersData]);

  useEffect(() => {
    getOrders({ variables: { filter: { status: ORDER_COMPLETED_STATUSES } } }).then(({ data }) => {
      setCompletedOrdersData(data.userOrders);
    });
  }, [setCompletedOrdersData, getOrders]);

  useEffect(() => {
    let subscription;
    const userId = user?.id;

    try {
      if (userId) {
        subscription = client
          .subscribe({
            query: updateOrdersSubscription,
            variables: { userId },
          })
          .subscribe({
            next({ data }) {
              const order = data?.ordersUpdateSubscription;

              const inProgressOrderIndex = inProgressOrdersData.findIndex((item) => item.id === order.id);
              const completedOrderIndex = completedOrdersData.findIndex((item) => item.id === order.id);

              if (inProgressOrderIndex + 1) {
                setInProgressOrdersData((prev) => [
                  ...prev.slice(0, inProgressOrderIndex),
                  order,
                  ...prev.slice(inProgressOrderIndex + 1),
                ]);
              } else if (completedOrderIndex + 1) {
                setCompletedOrdersData((prev) => [
                  ...prev.slice(0, completedOrderIndex),
                  order,
                  ...prev.slice(completedOrderIndex + 1),
                ]);
              } else {
                if (ORDER_IN_PROGRESS_STATUSES.find((status) => status === order.status)) {
                  setInProgressOrdersData((prev) => [order, ...prev]);
                } else {
                  setCompletedOrdersData((prev) => [order, ...prev]);
                }
              }
            },
            error(error) {
              handleErrors(error);
            },
          });
      } else {
        subscription?.unsubscribe();
      }
    } catch (error) {
      handleErrors(error);
    }

    return () => {
      if (subscription) subscription.unsubscribe();
    };
  }, [client, completedOrdersData, inProgressOrdersData, user?.id]);

  const jobItems = useMemo(
    () => [
      {
        title: 'In Progress',
        component: (
          <OrderItem
            key={1}
            inProgress
            data={inProgressOrdersData}
            pagination={getPaginationParams(inProgressOrdersData?.threeDContJobs)}
            refetch={refetch}
            loading={loading}
          />
        ),
        key: 'progress',
      },
      {
        title: 'Complete',
        component: (
          <OrderItem
            key={2}
            data={completedOrdersData}
            pagination={getPaginationParams(completedOrdersData?.threeDContJobs)}
            refetch={refetch}
            loading={loading}
          />
        ),
        key: 'complete',
      },
    ],
    [completedOrdersData, inProgressOrdersData, loading, refetch]
  );

  if (error) return <Error error={error} />;

  return (
    <Container fluid className="p-3">
      <div className="h3 my-4 font-weight-bold">My Orders</div>
      <Row>
        <Col>
          <Tabs
            id="jobsItems"
            items={jobItems}
            defaultActiveKey={'progress'}
            onTabChange={() => {
              const query = getQueryParams(search, { page: null, pageSize: null });
              history.push({ search: query });
            }}
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Orders;
