import React, { useCallback, useRef, useState } from 'react';
import { GoogleMap } from '@react-google-maps/api';
import { OverlappingMarkerSpiderfier } from 'ts-overlapping-marker-spiderfier';
import PositionMarker from 'components/PositionMarker';
import { DEFAULT_CONTAINER_STYLE, MAP_DEFAULT_CENTER, MAP_DEFAULT_ZOOM } from 'constants/map';

const Map = ({
  containerStyle = DEFAULT_CONTAINER_STYLE,
  center = MAP_DEFAULT_CENTER,
  position = [],
  popupValues = [],
  zoom = MAP_DEFAULT_ZOOM,
  onChangePosition,
  setMarkerPosition,
}) => {
  const mapRef = useRef(undefined);
  const [activeMarker, setActiveMarker] = useState(null);
  const [oms, setOms] = useState();

  const onLoad = useCallback((map) => {
    mapRef.current = map;
    if (mapRef.current) {
      const newOms = new OverlappingMarkerSpiderfier(mapRef.current, {
        markersWontMove: true,
        markersWontHide: true,
        basicFormatEvents: true,
        circleFootSeparation: 60,
      });
      setOms(newOms);
    }
  }, []);

  const onUnmount = useCallback(() => (mapRef.current = undefined), []);

  const handleClickPosition = async (event) => {
    if (onChangePosition) {
      const position = onChangePosition(event);
      console.log(position);
      setMarkerPosition(position);
    }
  };

  return (
    <div className="mt-2 mb-3">
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={zoom}
        onLoad={onLoad}
        onUnmount={onUnmount}
        onChangePosition={(e) => console.log(e)}
        onClick={(e) => handleClickPosition(e)}
        options={{
          zoomControl: false,
          streetViewControl: false,
          mapTypeControl: false,
          fullscreenControl: false,
        }}
      >
        {position.length &&
          mapRef.current &&
          position.map((marker) => (
            <PositionMarker
              key={marker.id}
              marker={marker}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              popupValues={popupValues}
              spiderfy={oms}
            />
          ))}
      </GoogleMap>
    </div>
  );
};

export default Map;
