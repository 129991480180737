import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useQuery } from '@apollo/client';
import cn from 'classnames';
import { isEmpty, isEqual } from 'lodash';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import ControlledFormItem from 'components/ControlledFormItem';
import Input from 'components/Input';
import RadioCheckBox from 'components/RadioCheckBox';
import Select from 'components/Select';
import SelectQuery from 'components/SelectQuery';
import {
  MIN_SCALE_SIZE,
  PART_SIZE_ITEMS,
  PART_SIZE_OPTIONS,
  SCALE_SIZE_STEP,
  SLICER_SETTING_DEFAULT_FIELDS,
} from 'constants/part';
import { INFILL_PERCENTS, INFILL_PATTERNS, DELIVERY_POLICY_SETTINGS } from 'constants/wingman';
import usePrintQuality from 'hooks/usePrintQuality';
import { getWingmansQuery } from 'requests/wingmen';
import styles from './index.module.scss';

const sizeNamesArray = ['dimensionsHeight', 'dimensionsLength', 'dimensionsWidth'];

const G_CODE_AVAILABLE_STATUS = 'G-code is available';
const PICKUP_STATUS = 'Pickup Only';
const PICKUP_AND_SHIP_STATUS = 'Ship and Pickup';
const SHIP_STATUS = 'Ship Only';

const CostForm = ({ onSubmit, part, setWingman, setJobData, isCalculating, storageData }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [wingman, type, printQuality, pattern, percent] = watch([
    'wingman',
    'type',
    'printQuality',
    'pattern',
    'percent',
  ]);

  const [h, l, w] = watch(sizeNamesArray);

  const { data: wingmanList } = useQuery(getWingmansQuery);

  const gCodes = part?.gcodes;

  const printQualityItems = usePrintQuality(wingman?.printer?.nozzleSize);

  useEffect(() => {
    if (!type) {
      setValue('color', null);
      setValue('printQuality', null);
      setValue('percent', null);
      setValue('pattern', null);
      setValue('size', PART_SIZE_OPTIONS.ORIGINAL);
      setSize(PART_SIZE_OPTIONS.ORIGINAL);
      setValue('dimensionsLength', part?.dimensionsLength);
      setValue('dimensionsWidth', part?.dimensionsWidth);
      setValue('dimensionsHeight', part?.dimensionsHeight);
    }

    setJobData(null);
    setWingman(wingman);

    if (wingman) {
      setWingman(wingman);
    } else {
      setWingman(null);
      setValue('type', null);
    }
  }, [wingman, storageData, type]);

  const formFields = [
    'dimensionsHeight',
    'dimensionsLength',
    'dimensionsWidth',
    'pattern',
    'printQuality',
    'percent',
    'wingman',
    'color',
    'type',
    'size',
  ];

  const getDefaultValues = (field) => storageData[field];

  useEffect(() => {
    storageData &&
      formFields.forEach((field) => {
        if (field === 'size') setSize(getDefaultValues(field));
        setValue(field, getDefaultValues(field));
      });
    !storageData && formFields.forEach((field) => !field.includes('dimensions') && setValue(field, null));
  }, [storageData, wingmanList]);

  useEffect(() => {
    const getDefaultSlicerSettingValue = (propName) => {
      const slicerSettings = wingman?.printer?.slicerSettings;
      if (slicerSettings) {
        const selectedTypeSettings = slicerSettings.find((setting) => setting.plasticType === type);
        return selectedTypeSettings?.[propName];
      }

      return null;
    };

    if (!storageData) {
      SLICER_SETTING_DEFAULT_FIELDS.forEach(({ inputName, fieldName }) =>
        setValue(inputName, getDefaultSlicerSettingValue(fieldName))
      );
      !type && setValue('printQuality', null);
    }
  }, [type, storageData, wingman?.printer?.slicerSettings, setValue]);

  const getMaterialTypesItems = () => {
    let materialTypes = [];
    const uniqueItems = [...new Set(wingman?.printer?.availableMaterials.map((item) => item.type))];
    if (uniqueItems.length) materialTypes = uniqueItems.map((item) => ({ label: item, value: item }));

    return materialTypes;
  };

  const getColorItems = () => {
    let colorItems = [];

    const filtered = wingman?.printer?.availableMaterials?.filter((singleMaterial) => singleMaterial.type === type);
    if (filtered?.length) colorItems = filtered.map(({ color }) => ({ label: color, value: color }));

    return colorItems;
  };

  const [size, setSize] = useState(PART_SIZE_OPTIONS.ORIGINAL);

  const dh = +part.dimensionsHeight || 100;
  const dl = +part.dimensionsLength || 100;
  const dw = +part.dimensionsWidth || 100;

  const sizes = {
    dimensionsHeight_dimensionsLength: dh / dl,
    dimensionsHeight_dimensionsWidth: dh / dw,
    dimensionsLength_dimensionsHeight: dl / dh,
    dimensionsLength_dimensionsWidth: dl / dw,
    dimensionsWidth_dimensionsHeight: dw / dh,
    dimensionsWidth_dimensionsLength: dw / dl,
  };

  useEffect(() => {
    if (size === PART_SIZE_OPTIONS.ORIGINAL) {
      sizeNamesArray.forEach((item) => setValue(item, parseFloat(part[item])));
    }
  }, [setValue, size, part]);

  const resizeCallback =
    (changed, [valueOne, valueTwo]) =>
    (sizeValue) => {
      setValue(valueOne, (sizeValue / sizes[`${changed}_${valueOne}`]).toFixed(2));
      setValue(valueTwo, (sizeValue / sizes[`${changed}_${valueTwo}`]).toFixed(2));
    };

  const onFormError = (formErrors) => {
    if (!isEmpty(formErrors)) toast.error("Some fields aren't filled");
  };

  const [isAvailableWingman, setIsAvailableWingman] = useState(false);
  const [currentGCode, setCurrentGCode] = useState();

  const settingsFields = useMemo(() => ['materialType', 'printQuality', 'infillSparseDensity', 'infillPattern'], []);

  const getWingmanFields = (wingman, field) => {
    if (wingman) {
      switch (field) {
        case 'extruderSize':
          return Number(wingman.printer?.nozzleSize)?.toFixed(1);
        case 'model':
          return wingman.printer?.model?.id;
        case 'adhesionType':
          return wingman.printer?.slicerSettings.find((item) => item[field])?.[field];
        default:
          break;
      }

      return wingman[field];
    }
  };

  const getGCodesFields = (gCode, field) => {
    if (field === 'model') return gCode?.model?.id;
    if (field === 'extruderSize') return Number(gCode?.extruderSize).toFixed(1);

    return gCode[field];
  };

  const getGCodeAvailableStatus = useCallback(
    (optionWingman) => {
      if (optionWingman?.threeDContUser?.id === part?.userMedia?.threeDContUser?.id) {
        return gCodes?.filter(
          (gCode) =>
            isEqual(getGCodesFields(gCode, 'extruderSize'), getWingmanFields(optionWingman, 'extruderSize')) &&
            isEqual(getGCodesFields(gCode, 'model'), getWingmanFields(optionWingman, 'model'))
        );
      }
    },
    [gCodes, part?.userMedia?.threeDContUser?.id]
  );

  useEffect(() => {
    if (type) {
      const foundGCode = gCodes.filter(({ materialType }) => materialType === type);
      foundGCode && setCurrentGCode(foundGCode);
    }
  }, [gCodes, type]);

  useEffect(() => {
    if (!wingman) setIsAvailableWingman(false);
  }, [wingman]);

  const getAvailableSettings = useCallback(
    (optionValue) => {
      return gCodes?.some((gCode) => settingsFields.some((field) => gCode[field] === optionValue));
    },
    [gCodes, settingsFields]
  );

  const getAvailableSettingsFields = useCallback(
    (optionValue) => {
      return currentGCode?.some((gCode) => settingsFields.some((field) => gCode[field] === optionValue));
    },
    [currentGCode, settingsFields]
  );

  const getAvailableWingman = useCallback(
    (wingman) => {
      if (getGCodeAvailableStatus(wingman)?.length > 0) {
        setIsAvailableWingman(true);
      }
    },
    [getGCodeAvailableStatus]
  );

  const renderCustomOptions = useCallback(
    (options) => {
      return (
        <div className="d-flex justify-content-between w-100" onClick={() => getAvailableWingman(options.value)}>
          <div>{options.label}</div>
          <div className="d-flex justify-content-between">
            {getGCodeAvailableStatus(options.value)?.length > 0 && (
              <div className="text-warning font-weight-bold mr-3">{G_CODE_AVAILABLE_STATUS}</div>
            )}
            {options.value.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP && (
              <div className={cn('font-weight-bold', styles.pickupColor)}>{PICKUP_STATUS}</div>
            )}
            {options.value.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP && (
              <div className={cn('font-weight-bold', styles.pickupAndShipColor)}>{PICKUP_AND_SHIP_STATUS}</div>
            )}
            {options.value.deliveryPolicy === DELIVERY_POLICY_SETTINGS.SHIP && (
              <div className={cn('font-weight-bold', styles.shipColor)}>{SHIP_STATUS}</div>
            )}
          </div>
        </div>
      );
    },
    [getAvailableWingman, getGCodeAvailableStatus]
  );

  const getGCodeAvailableOptionStatus = useCallback((availableSettigs) => {
    if (availableSettigs) {
      return <span className="mr-3 font-weight-bold text-warning">{G_CODE_AVAILABLE_STATUS}</span>;
    }
  }, []);

  const renderGCodeAvailableStatus = useCallback(
    (availableField) => {
      if (availableField && isAvailableWingman) {
        return <div className="text-right mt-2 text-warning font-weight-bold">{G_CODE_AVAILABLE_STATUS}</div>;
      }
    },
    [isAvailableWingman]
  );

  const renderDeliveryPolicyStatus = useCallback((availableField) => {
    if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP) {
      return <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.pickupColor)}>{PICKUP_STATUS}</div>;
    } else if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.PICKUP_AND_SHIP) {
      return (
        <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.pickupAndShipColor)}>
          {PICKUP_AND_SHIP_STATUS}
        </div>
      );
    } else if (availableField?.deliveryPolicy === DELIVERY_POLICY_SETTINGS.SHIP) {
      return <div className={cn('text-right mt-2 ml-3 font-weight-bold', styles.shipColor)}>{SHIP_STATUS}</div>;
    }
    return null;
  }, []);

  const getOptionsStatus = useCallback(
    (availableFields) => isAvailableWingman && getGCodeAvailableOptionStatus(availableFields),
    [getGCodeAvailableOptionStatus, isAvailableWingman]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit, onFormError)}>
      <div className="my-4">
        <ControlledFormItem
          control={control}
          name="wingman"
          label="Pick wingman"
          placeholder="Select wingman"
          errors={errors.wingman}
          component={SelectQuery}
          renderCustomOptions={renderCustomOptions}
          renderInputStatus={() => (
            <div className="d-flex justify-content-end">
              {renderGCodeAvailableStatus(getGCodeAvailableStatus(wingman))}
              {renderDeliveryPolicyStatus(wingman)}
            </div>
          )}
          isSearchable
          defaultValue={storageData?.wingman}
          labelField="machineName"
          defaultValueField="machineName"
          query={getWingmansQuery}
          dataPath="threeDContWingmen"
          hasClear
          validation
          rules={{
            required: true,
          }}
        />
      </div>
      <div className="my-4">
        <ControlledFormItem
          control={control}
          name="type"
          label="Material type"
          errors={errors.type}
          placeholder="Select type"
          component={Select}
          getOptionStatus={(option) => getOptionsStatus(getAvailableSettings(option))}
          items={getMaterialTypesItems()}
          renderInputStatus={() => renderGCodeAvailableStatus(getAvailableSettings(type))}
          hasClear
          disabled={!wingman}
          validation
          rules={{
            required: true,
          }}
        />
        <ControlledFormItem
          control={control}
          name="color"
          label="Material color"
          placeholder="Select color"
          errors={errors.color}
          component={Select}
          items={getColorItems()}
          hasClear
          disabled={!wingman || !type || !getColorItems().length}
          validation
          rules={{
            required: true,
          }}
        />
        <ControlledFormItem
          control={control}
          name="printQuality"
          label="Print quality"
          placeholder="Print quality"
          component={Select}
          getOptionStatus={(option) => getOptionsStatus(getAvailableSettingsFields(option))}
          renderInputStatus={() => renderGCodeAvailableStatus(getAvailableSettings(printQuality))}
          items={printQualityItems}
          disabled={!wingman || !type}
          validation
          rules={{
            required: true,
          }}
        />
        <ControlledFormItem
          control={control}
          name="percent"
          label="InFill %"
          placeholder="Select %"
          errors={errors.percent}
          component={Select}
          getOptionStatus={(option) => getOptionsStatus(getAvailableSettingsFields(option))}
          renderInputStatus={() => renderGCodeAvailableStatus(getAvailableSettings(percent))}
          items={INFILL_PERCENTS}
          hasClear
          disabled={!wingman || !type}
          validation
          rules={{
            required: true,
          }}
        />

        <ControlledFormItem
          control={control}
          name="pattern"
          label="InFill pattern"
          placeholder="Select pattern"
          errors={errors.pattern}
          component={Select}
          getOptionStatus={(option) => getOptionsStatus(getAvailableSettingsFields(option))}
          renderInputStatus={() => renderGCodeAvailableStatus(getAvailableSettings(pattern))}
          items={INFILL_PATTERNS}
          hasClear
          disabled={!wingman || !type}
          validation
          rules={{
            required: true,
          }}
        />
      </div>
      <div className="my-4 border-bottom">
        <ControlledFormItem
          control={control}
          name="size"
          label="Size"
          items={PART_SIZE_ITEMS}
          component={RadioCheckBox}
          value={size}
          onChange={setSize}
        />
        <div className="row justify-content-center">
          <div className="w-100 pr-2 col-12 col-md-4">
            <ControlledFormItem
              control={control}
              name="dimensionsLength"
              label="Length - X(mm)"
              errors={errors.dimensionsLength}
              type="number"
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              value={l || part.dimensionsLength}
              component={Input}
              disabled={size === PART_SIZE_OPTIONS.ORIGINAL}
              validation
              onChange={
                size === PART_SIZE_OPTIONS.SCALE &&
                resizeCallback('dimensionsLength', ['dimensionsHeight', 'dimensionsWidth'])
              }
              rules={{ required: true }}
            />
          </div>
          <div className="w-100 col-12 col-md-4">
            <ControlledFormItem
              control={control}
              name="dimensionsWidth"
              label="Width - Y(mm)"
              errors={errors.dimensionsWidth}
              type="number"
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              value={w || part.dimensionsWidth}
              component={Input}
              disabled={size === PART_SIZE_OPTIONS.ORIGINAL}
              validation
              onChange={
                size === PART_SIZE_OPTIONS.SCALE &&
                resizeCallback('dimensionsWidth', ['dimensionsLength', 'dimensionsHeight'])
              }
              rules={{ required: true }}
            />
          </div>
          <div className="w-100 pr-2 col-12 col-md-4">
            <ControlledFormItem
              control={control}
              name="dimensionsHeight"
              label="Height - Z(mm)"
              errors={errors.dimensionsHeight}
              type="number"
              min={MIN_SCALE_SIZE}
              step={SCALE_SIZE_STEP}
              value={h || part.dimensionsHeight}
              component={Input}
              disabled={size === PART_SIZE_OPTIONS.ORIGINAL}
              onChange={
                size === PART_SIZE_OPTIONS.SCALE &&
                resizeCallback('dimensionsHeight', ['dimensionsLength', 'dimensionsWidth'])
              }
              validation
              rules={{ required: true }}
            />
          </div>

          <Button
            onClick={handleSubmit(onSubmit, onFormError)}
            variant="primary"
            className="justify-content-center mb-4"
            disabled={isCalculating}
          >
            View print cost
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CostForm;
