import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import { getCurrentUserQuery } from 'requests/auth';
import { handleErrors } from 'utils/global';

const AuthContext = React.createContext({ user: null, setUser: () => {} });

const AuthProvider = (props) => {
  const [user, setUser] = useState(null);
  const [load, setLoad] = useState(false);
  const [userLogOuted, setUserLogOuted] = useState(false);

  const [getUserQuery, { loading }] = useLazyQuery(getCurrentUserQuery, {
    onCompleted: (data) => {
      setUser(data?.me);
      setLoad(true);
    },
    onError: (e) => {
      handleErrors(e);
      setLoad(true);
    },
  });

  useEffect(() => {
    !user && getUserQuery();
  }, [user, getUserQuery]);

  return (
    <AuthContext.Provider value={{ user, loading, setUser, userLogOuted, setUserLogOuted }}>
      {load && props.children}
    </AuthContext.Provider>
  );
};

export { AuthContext, AuthProvider };
