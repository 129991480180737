import React from 'react';

const ContactUs = () => {
  return (
    <div>
      <h1>3d Wingman contact us page</h1>
      <p>contact us page body content</p>
    </div>
  );
};

export default ContactUs;
