import React from 'react';
import { Route } from 'react-router-dom';
import RequireSignIn from 'components/RequireSignin';

const ProtectedRoute = (props) => {
  return (
    <RequireSignIn>
      <Route {...props} />
    </RequireSignIn>
  );
};
export default ProtectedRoute;
