export const isoMap = {
  CA: 'CA',
  US: 'US',
  MX: 'MX',
  SE: 'SE',
};

export const CA = [
  {
    name: 'Alberta',
    iso: 'CA-AB',
  },
  {
    name: 'British Columbia',
    iso: 'CA-BC',
  },
  {
    name: 'Manitoba',
    iso: 'CA-MB',
  },
  {
    name: 'New Brunswick',
    iso: 'CA-NB',
  },
  {
    name: 'Newfoundland and Labrador',
    iso: 'CA-NL',
  },
  {
    name: 'Northwest Territories',
    iso: 'CA-NT',
  },
  {
    name: 'Nova Scotia',
    iso: 'CA-NS',
  },
  {
    name: 'Nunavut',
    iso: 'CA-NU',
  },
  {
    name: 'Ontario',
    iso: 'CA-ON',
  },
  {
    name: 'Prince Edward Island',
    iso: 'CA-PE',
  },
  {
    name: 'Québec',
    iso: 'CA-QC',
  },
  {
    name: 'Saskatchewan',
    iso: 'CA-SK',
  },
  {
    name: 'Yukon',
    iso: 'CA-YT',
  },
];

export const US = [
  {
    name: 'Alabama',
    iso: 'US-AL',
  },
  {
    name: 'Alaska',
    iso: 'US-AK',
  },
  {
    name: 'American Samoa',
    iso: 'US-AS',
  },
  {
    name: 'Arizona',
    iso: 'US-AZ',
  },
  {
    name: 'Arkansas',
    iso: 'US-AR',
  },
  {
    name: 'Baker Island',
    iso: 'UM-81',
  },
  {
    name: 'California',
    iso: 'US-CA',
  },
  {
    name: 'Colorado',
    iso: 'US-CO',
  },
  {
    name: 'Connecticut',
    iso: 'US-CT',
  },
  {
    name: 'Delaware',
    iso: 'US-DE',
  },
  {
    name: 'District of Columbia',
    iso: 'US-DC',
  },
  {
    name: 'Florida',
    iso: 'US-FL',
  },
  {
    name: 'Georgia',
    iso: 'US-GA',
  },
  {
    name: 'Guam',
    iso: 'US-GU',
  },
  {
    name: 'Hawaii',
    iso: 'US-HI',
  },
  {
    name: 'Howland Island',
    iso: 'UM-84',
  },
  {
    name: 'Idaho',
    iso: 'US-ID',
  },
  {
    name: 'Illinois',
    iso: 'US-IL',
  },
  {
    name: 'Indiana',
    iso: 'US-IN',
  },
  {
    name: 'Iowa',
    iso: 'US-IA',
  },
  {
    name: 'Jarvis Island',
    iso: 'UM-86',
  },
  {
    name: 'Johnston Atoll',
    iso: 'UM-67',
  },
  {
    name: 'Kansas',
    iso: 'US-KS',
  },
  {
    name: 'Kentucky',
    iso: 'US-KY',
  },
  {
    name: 'Kingman Reef',
    iso: 'UM-89',
  },
  {
    name: 'Louisiana',
    iso: 'US-LA',
  },
  {
    name: 'Maine',
    iso: 'US-ME',
  },
  {
    name: 'Maryland',
    iso: 'US-MD',
  },
  {
    name: 'Massachusetts',
    iso: 'US-MA',
  },
  {
    name: 'Michigan',
    iso: 'US-MI',
  },
  {
    name: 'Midway Atoll',
    iso: 'UM-71',
  },
  {
    name: 'Minnesota',
    iso: 'US-MN',
  },
  {
    name: 'Mississippi',
    iso: 'US-MS',
  },
  {
    name: 'Missouri',
    iso: 'US-MO',
  },
  {
    name: 'Montana',
    iso: 'US-MT',
  },
  {
    name: 'Navassa Island',
    iso: 'UM-76',
  },
  {
    name: 'Nebraska',
    iso: 'US-NE',
  },
  {
    name: 'Nevada',
    iso: 'US-NV',
  },
  {
    name: 'New Hampshire',
    iso: 'US-NH',
  },
  {
    name: 'New Jersey',
    iso: 'US-NJ',
  },
  {
    name: 'New Mexico',
    iso: 'US-NM',
  },
  {
    name: 'New York',
    iso: 'US-NY',
  },
  {
    name: 'North Carolina',
    iso: 'US-NC',
  },
  {
    name: 'North Dakota',
    iso: 'US-ND',
  },
  {
    name: 'Northern Mariana Islands',
    iso: 'US-MP',
  },
  {
    name: 'Ohio',
    iso: 'US-OH',
  },
  {
    name: 'Oklahoma',
    iso: 'US-OK',
  },
  {
    name: 'Oregon',
    iso: 'US-OR',
  },
  {
    name: 'Palmyra Atoll',
    iso: 'UM-95',
  },
  {
    name: 'Pennsylvania',
    iso: 'US-PA',
  },
  {
    name: 'Puerto Rico',
    iso: 'US-PR',
  },
  {
    name: 'Rhode Island',
    iso: 'US-RI',
  },
  {
    name: 'South Carolina',
    iso: 'US-SC',
  },
  {
    name: 'South Dakota',
    iso: 'US-SD',
  },
  {
    name: 'Tennessee',
    iso: 'US-TN',
  },
  {
    name: 'Texas',
    iso: 'US-TX',
  },
  {
    name: 'United States Minor Outlying Islands',
    iso: 'US-UM',
  },
  {
    name: 'United States Virgin Islands',
    iso: 'US-VI',
  },
  {
    name: 'Utah',
    iso: 'US-UT',
  },
  {
    name: 'Vermont',
    iso: 'US-VT',
  },
  {
    name: 'Virginia',
    iso: 'US-VA',
  },
  {
    name: 'Wake Island',
    iso: 'UM-79',
  },
  {
    name: 'Washington',
    iso: 'US-WA',
  },
  {
    name: 'West Virginia',
    iso: 'US-WV',
  },
  {
    name: 'Wisconsin',
    iso: 'US-WI',
  },
  {
    name: 'Wyoming',
    iso: 'US-WY',
  },
];

export const MX = [
  {
    name: 'Aguascalientes',
    iso: 'MX-AGU',
  },
  {
    name: 'Baja California',
    iso: 'MX-BCN',
  },
  {
    name: 'Baja California Sur',
    iso: 'MX-BCS',
  },
  {
    name: 'Campeche',
    iso: 'MX-CAM',
  },
  {
    name: 'Chiapas',
    iso: 'MX-CHP',
  },
  {
    name: 'Chihuahua',
    iso: 'MX-CHH',
  },
  {
    name: 'Ciudad de México',
    iso: 'MX-CMX',
  },
  {
    name: 'Coahuila de Zaragoza',
    iso: 'MX-COA',
  },
  {
    name: 'Colima',
    iso: 'MX-COL',
  },
  {
    name: 'Durango',
    iso: 'MX-DUR',
  },
  {
    name: 'Guanajuato',
    iso: 'MX-GUA',
  },
  {
    name: 'Guerrero',
    iso: 'MX-GRO',
  },
  {
    name: 'Hidalgo',
    iso: 'MX-HID',
  },
  {
    name: 'Jalisco',
    iso: 'MX-JAL',
  },
  {
    name: 'México',
    iso: 'MX-MEX',
  },
  {
    name: 'Michoacán',
    iso: 'MX-MIC',
  },
  {
    name: 'Morelos',
    iso: 'MX-MOR',
  },
  {
    name: 'Nayarit',
    iso: 'MX-NAY',
  },
  {
    name: 'Nuevo León',
    iso: 'MX-NLE',
  },
  {
    name: 'Oaxaca',
    iso: 'MX-OAX',
  },
  {
    name: 'Puebla',
    iso: 'MX-PUE',
  },
  {
    name: 'Querétaro',
    iso: 'MX-QUE',
  },
  {
    name: 'Quintana Roo',
    iso: 'MX-ROO',
  },
  {
    name: 'San Luis Potosí',
    iso: 'MX-SLP',
  },
  {
    name: 'Sinaloa',
    iso: 'MX-SIN',
  },
  {
    name: 'Sonora',
    iso: 'MX-SON',
  },
  {
    name: 'Tabasco',
    iso: 'MX-TAB',
  },
  {
    name: 'Tamaulipas',
    iso: 'MX-TAM',
  },
  {
    name: 'Tlaxcala',
    iso: 'MX-TLA',
  },
  {
    name: 'Veracruz',
    iso: 'MX-VER',
  },
  {
    name: 'Yucatan',
    iso: 'MX-YUC',
  },
  {
    name: 'Zacatecas',
    iso: 'MX-ZAC',
  },
];

export const SE = [
  {
    name: 'Blekinge län',
    iso: 'SE-K',
  },
  {
    name: 'Dalarnas län',
    iso: 'SE-W',
  },
  {
    name: 'Gotlands län',
    iso: 'SE-I',
  },
  {
    name: 'Gävleborgs län',
    iso: 'SE-X',
  },
  {
    name: 'Hallands län',
    iso: 'SE-N',
  },
  {
    name: 'Jämtlands län',
    iso: 'SE-Z',
  },
  {
    name: 'Jönköpings län',
    iso: 'SE-F',
  },
  {
    name: 'Kalmar län',
    iso: 'SE-H',
  },
  {
    name: 'Kronobergs län',
    iso: 'SE-G',
  },
  {
    name: 'Norrbottens län',
    iso: 'SE-BD',
  },
  {
    name: 'Skåne län',
    iso: 'SE-M',
  },
  {
    name: 'Stockholms län',
    iso: 'SE-AB',
  },
  {
    name: 'Södermanlands län',
    iso: 'SE-D',
  },
  {
    name: 'Uppsala län',
    iso: 'SE-C',
  },
  {
    name: 'Värmlands län',
    iso: 'SE-S',
  },
  {
    name: 'Västerbottens län',
    iso: 'SE-AC',
  },
  {
    name: 'Västernorrlands län',
    iso: 'SE-Y',
  },
  {
    name: 'Västmanlands län',
    iso: 'SE-U',
  },
  {
    name: 'Västra Götalands län',
    iso: 'SE-O',
  },
  {
    name: 'Örebro län',
    iso: 'SE-T',
  },
  {
    name: 'Östergötlands län',
    iso: 'SE-E',
  },
];

export const minorIslandIsoCodeMap = {
  AS: 'AS',
  UM: 'UM',
  GU: 'GU',
  MP: 'MP',
  PR: 'PR',
  VI: 'VI',
};

export const STATES = { CA, US, MX, SE };

export const COUNTRIES = [
  { name: 'United States', iso: 'US', state: US },
  { name: 'Canada', iso: 'CA', state: CA },
  { name: 'Mexico', iso: 'MX', state: MX },
  { name: 'Sweden', iso: 'SE', state: SE },
];
