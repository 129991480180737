import React, { useEffect } from 'react';
import { useApolloClient } from '@apollo/client';

import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer, Slide } from 'react-toastify';

import { linkWithSockets } from 'client';
import AppLayout from 'lib/AppLayout';
import ProtectedAuthRoute from 'lib/ProtectedAuthRoute';
import ProtectedRoute from 'lib/ProtectedRoute';
import ContactUs from 'pages/ContactUs';
import DesignersList from 'pages/DesignersList';
import DesignerView from 'pages/DesignerView';
import EditPart from 'pages/EditPart';
import EmailConfirm from 'pages/EmailConfirm';
import EmailUnsubscribe from 'pages/EmailUnsubscribe';
import ForgotPassword from 'pages/ForgotPassword';
import Home from 'pages/Home';

import MyCart from 'pages/MyCart';
import MyUploads from 'pages/MyUploads';
import MyWingman from 'pages/MyWingman';
import NearestOperators from 'pages/NearestOperators';
import NotFound from 'pages/NotFound';
import OneTimePrint from 'pages/OneTimePrint';
import OperatorsList from 'pages/OperatorsList';
import OperatorsView from 'pages/OperatorView';
import Orders from 'pages/Orders';
import Part from 'pages/Part';
import PaymentReject from 'pages/PaymentReject';
import PaymentSuccess from 'pages/PaymentSuccess';
import ProductList from 'pages/ProductList';
import Profile from 'pages/Profile';
import ResetPassword from 'pages/ResetPassword';
import Signin from 'pages/Signin';
import Signup from 'pages/Signup';
import StripeConnect from 'pages/StripeConnect';
import Terms from 'pages/Terms';
import TransferReject from 'pages/TransferReject';
import TransferSuccess from 'pages/TransferSuccess';
import WingmanOrder from 'pages/WingmanOrder';
import WingmanView from 'pages/WingmanView';
import { getUserToken } from 'utils/global';
import { AuthProvider } from 'сontexts/AuthContext';
import { JobUpdateProvider } from 'сontexts/JobUpdateContext';

const App = () => {
  const client = useApolloClient();

  useEffect(() => {
    client.setLink(linkWithSockets(getUserToken()));
  }, [client]);

  return (
    <BrowserRouter>
      <AuthProvider>
        <JobUpdateProvider>
          <AppLayout>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/contact-us" component={ContactUs} />
              <Route path="/terms" component={Terms} />
              <Route path="/confirm_email" component={EmailConfirm} />
              <Route path="/email_unsubscribe" component={EmailUnsubscribe} />
              <Route path="/payment_success" component={PaymentSuccess} />
              <Route path="/payment_reject" component={PaymentReject} />
              <Route path="/transfer_success" component={TransferSuccess} />
              <Route path="/transfer_reject" component={TransferReject} />
              <Route path="/stripe_connect" component={StripeConnect} />
              <Route path="/product-list" component={ProductList} />
              <Route path="/wingman-locations" component={NearestOperators} />
              <Route path="/operators" exact component={OperatorsList} />
              <Route path="/designers" exact component={DesignersList} />
              {/* Auth protected routes */}
              <ProtectedRoute path="/user/:id" component={Profile} />
              <ProtectedRoute path="/part/:id" exact component={Part} />
              <ProtectedRoute path="/part/edit/:id" exact component={EditPart} />
              <ProtectedRoute path="/my-orders" component={Orders} />
              <ProtectedRoute path="/my-product/:id" component={MyUploads} />
              <ProtectedRoute path="/one-time-print" component={OneTimePrint} />
              <ProtectedRoute path="/my-wingman" component={MyWingman} />
              <ProtectedRoute path="/my-cart" component={MyCart} />
              <ProtectedRoute path="/wingman/:id" exact component={WingmanView} />
              <ProtectedRoute path="/wingman-order" component={WingmanOrder} />
              <ProtectedRoute path="/operator/:id" exact component={OperatorsView} />
              <ProtectedRoute path="/designer/:id" exact component={DesignerView} />
              <ProtectedAuthRoute path="/sign-up" component={Signup} />
              <ProtectedAuthRoute path="/sign-in" component={Signin} />
              <ProtectedAuthRoute path="/reset_password" component={ResetPassword} />
              <ProtectedAuthRoute path="/forgot-password" component={ForgotPassword} />
              <Route component={NotFound} />
            </Switch>
          </AppLayout>
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            pauseOnHover
            transition={Slide}
          />
        </JobUpdateProvider>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;
