import React from 'react';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import Error from 'components/Error';
import Loading from 'components/Loading';
import PartDetails from 'components/PartDetails';
import { getSinglePartByIdQuery } from 'requests/partRequest';

const Part = () => {
  const { id } = useParams();

  const { loading, error, data } = useQuery(getSinglePartByIdQuery, {
    variables: { partId: id },
    fetchPolicy: 'cache-and-network',
  });

  if (loading) return <Loading />;
  if (error) return <Error error={error} />;
  return (
    <div className="container-fluid">
      <PartDetails part={data.partById} />
    </div>
  );
};

export default Part;
