import React from 'react';
import { useApolloClient } from '@apollo/client';
import qs from 'qs';
import { Button } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import AuthPageWrapper from 'components/AuthPageWrapper';
import ControlledFormItem from 'components/ControlledFormItem/index';
import Input from 'components/Input/index';
import { resetPasswordConfirmMutation } from 'requests/auth';
import { handleErrors } from 'utils/global';
import { minLength, maxLength } from 'utils/validators';

const minLengthValidation = minLength(6);
const maxLengthValidation = maxLength(30);

const ResetPassword = () => {
  const client = useApolloClient();
  const history = useHistory();

  const {
    control,
    loading,
    formState: { errors },
    handleSubmit,
  } = useForm();

  const resetPasswordFinishHandler = async ({ password }) => {
    try {
      const { email, token } = qs.parse(history.location.search, { ignoreQueryPrefix: true });
      const variables = {
        email,
        password,
        token,
      };

      await client.mutate({
        mutation: resetPasswordConfirmMutation,
        variables,
      });
      toast.success('The password was successfully updated');
      history.push('/sign-in');
    } catch (e) {
      handleErrors(e, 'A reset password error has occurred');
    }
  };

  return (
    <AuthPageWrapper title="Setup New Password">
      <form className="p-2" onSubmit={handleSubmit(resetPasswordFinishHandler)}>
        <div className="mb-4">
          <ControlledFormItem
            control={control}
            name="password"
            label="New Password"
            errors={errors.password}
            component={Input}
            type="password"
            hasClear
            placeholder="Enter New Password"
            validation
            rules={{
              required: true,
              validate: { minLengthValidation, maxLengthValidation },
            }}
          />
        </div>
        <div className="d-grid">
          <Button disabled={loading} type="submit" size="lg">
            Submit Password
          </Button>
        </div>
      </form>
    </AuthPageWrapper>
  );
};

export default ResetPassword;
