import React from 'react';
import PropTypes from 'prop-types';
import NoData from 'components/NoData';
import Pagination from 'components/Pagination';
import PartCard from 'components/PartCard';

const PartList = ({ parts, pagination }) => {
  const isProductListPage = window.location.pathname.includes('/product-list');

  if (parts.length === 0) {
    return isProductListPage ? (
      <div className="jumbotron mt-5 text-center">
        <div className="container">
          <h5>There are no models in this category yet.</h5>
        </div>
      </div>
    ) : (
      <NoData />
    );
  }

  return (
    <div className="d-flex flex-column align-items-center">
      <div className="d-flex flex-wrap container pt-3">
        {parts.map((part) => (
          <PartCard key={part.id} part={part} />
        ))}
      </div>
      <div className="my-3">{pagination.totalPages > 1 && <Pagination pagination={pagination} />}</div>
    </div>
  );
};

PartList.propTypes = {
  parts: PropTypes.array.isRequired,
};

export default PartList;
